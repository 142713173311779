/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth'
import {useOnboardingDots} from '../../../../app/routing/PrivateRoutes'

// import {useIntercom} from 'react-use-intercom';

export function AsideMenuMainCompany() {
  // const { boot, show } = useIntercom();
  const {onboardingDots} = useOnboardingDots();
  const {currentUser} = useAuth()
  // TODO: this is such a mess... really need to clean this up
  let user = undefined;
  if (currentUser) {
    // So ugly... but not sure how else to go about it
    // https://stackoverflow.com/questions/17546953/cant-access-object-property-even-though-it-shows-up-in-a-console-log
    user = JSON.parse(JSON.stringify(currentUser)).user
  }
  return (
    <>
      <AsideMenuItemWithSub
        to='/account'
        title='Account'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
        hasOnboardingDot={onboardingDots?.accountSettings}
      >
        <AsideMenuItem to='/account/profile' title='Profile' hasBullet={true}
          hasOnboardingDot={onboardingDots?.accountSettings} />
        <AsideMenuItem to='/account/billing' title='Billing' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/business-ops'
        title='Business Ops'
        icon='/media/icons/duotune/abstract/abs017.svg'
        fontIcon='waves'
        hasOnboardingDot={onboardingDots?.macrosSettings}
      >
        <AsideMenuItem to='/business-ops/settings' title='Policies' hasBullet={true}
          hasOnboardingDot={onboardingDots?.macrosSettings} />
        <AsideMenuItem to='/business-ops/snippets' title='Macro Snippets' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem to='/team/settings' title='Team' icon='/media/icons/duotune/communication/com014.svg' />
      { user?.company.plan.ai && user?.company.gorgias_integrated &&
        <AsideMenuItem to='/ai/settings' title='AI Agent' icon='/media/icons/duotune/technology/teh003.svg' />
      }
    </>
  )
}
