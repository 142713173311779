import React  from 'react'
import clsx from 'clsx'
import { Row, Col } from 'react-bootstrap'
import {dismissOnboardingItem} from '../../../../../pages/dashboard/_requests'
import {getOnboardingProgress} from '../../../../../../app/modules/accounts/_requests'
import {useOnboardingDots} from '../../../../../routing/PrivateRoutes'

export enum OnboardingDismissalItem {
  AccountSettings = 'account_settings',
  MacrosSettings = 'macros_settings',
}

type Props = {
  item: OnboardingDismissalItem
  text: string
}

const OnboardingDismissal: React.FC<Props> = (props) => {
  const {onboardingDots, setOnboardingDots} = useOnboardingDots();
  const getOnboardingProgressDots = async () => {
    getOnboardingProgress().then((response) => {
      const {onboarding_dots: dots} = response.data;
      // NOTE: reformatting to keep camel case
      const formattedDots = {
        'accountSettings': dots.account_settings,
        'macrosSettings': dots.macros_settings,
      }
      setOnboardingDots(formattedDots);
    })
  };

  const dismissOnboarding = async () => {
    dismissOnboardingItem(props.item).then(async (response) => {
      getOnboardingProgressDots().catch((error) => {
        console.log('ERROR: ' + error);
      });
    })
  }

  return (
    <div className={clsx(`card mb-5 mb-xl-10`, {
      'hidden': props.item === OnboardingDismissalItem.AccountSettings
      ? !onboardingDots?.accountSettings : !onboardingDots?.macrosSettings
    })}>
      <div className='card-body border-top p-9'>
        <Row>
          <Col md={2}>
            <div className='card-title m-0'>
              <h3 className='dismissal-heading fw-bolder m-0'>Notice 🔔</h3>
            </div>
          </Col>
          <Col md={7}>
            <p className='dismissal-text'>{props.text}</p>
          </Col>
          <Col md={3}>
            <button type='submit' className='btn btn-primary dismissal-button' onClick={dismissOnboarding}>Dismiss</button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export {OnboardingDismissal}
