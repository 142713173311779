import React, {useEffect, useState} from 'react'
import {Container, Col, Row, Stack} from 'react-bootstrap'
import {loadStripe, Appearance} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {useStripeContext} from '../../../accounts/AccountPage';
import {
  getPaymentMethods, createSetupIntent,
  deletePaymentMethod, updateDefaultPaymentMethod
} from '../../../billing/_requests';
import {toAbsoluteUrl} from '../../../../../_metronic/helpers';
import CheckoutForm from '../../../billing/components/CheckoutForm';
import {useAuth} from '../../../../../app/modules/auth';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY!);

interface PaymentMethod {
  id: string
  brand: string
  last4: string
  exp_month: string
  exp_year: string
  is_default: boolean
}

export function CardPayment() {
  const [addingCard, setAddingCard] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState<Array<PaymentMethod>>([])
  const [clientSecret, setClientSecret] = useState<string | undefined>()
  const [disabledButtonList, setDisabledButtonList] = useState<Array<boolean>>([])
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [makeCardPrimaryList, setMakeCardPrimaryList] = useState<Array<boolean>>([])
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const {currentUser} = useAuth()
  const user = JSON.parse(JSON.stringify(currentUser))?.user

  useEffect(() => {
    const getPaymentMethodsList = async () => {
      getPaymentMethods().then((response) => {
        const {payment_methods: paymentMethods} = response.data;
        setPaymentMethods(paymentMethods);
        let startingDisabledButtonList = [];
        let startingMakeCardPrimaryList = [];
        for (let i = 0; i < paymentMethods.length; i++) {
          startingDisabledButtonList.push(false);
          startingMakeCardPrimaryList.push(false);
        }
        setDisabledButtonList(startingDisabledButtonList);
      });
    };
    getPaymentMethodsList().catch((error) => {
      setErrorMessage('ERROR: ' + error);
    });
  }, [])

  const options = {
    clientSecret: clientSecret,
    appearance: {
      theme: "stripe",
      labels: "floating",
    } as Appearance
  }
  const {stripe, elements} = useStripeContext();
  const submit = async () => {
    if (elements) {
      setErrorMessage(undefined);
      setSubmitting(true);
      const {error} = await stripe!.confirmSetup({
        elements,
        redirect: 'if_required',
      });
      if (!error) {
        getPaymentMethods().then((response) => {
          const {payment_methods: paymentMethods} = response.data;
          setPaymentMethods(paymentMethods);
        }).catch((error) => {
          setErrorMessage('ERROR: ' + error);
        });;
        setAddingCard(false);
      } else {
        setErrorMessage('ERROR: Error adding card.');
      }
      setSubmitting(false);
    }
  }
  const addCard = async () => {
    setErrorMessage(undefined);
    const {data} = await createSetupIntent();
    const {client_secret: clientSecret} = data;
    setClientSecret(clientSecret);
    setAddingCard(true);
  }
  const deleteCard = async (index: number, paymentMethod: PaymentMethod) => {
    setErrorMessage(undefined);
    let updatedDisabledButtonList = [...disabledButtonList];
    updatedDisabledButtonList[index] = true;
    setDisabledButtonList(updatedDisabledButtonList);
    deletePaymentMethod(paymentMethod.id).then(async (response) => {
      // update payment methods
      getPaymentMethods().then((response) => {
        const {payment_methods: paymentMethods} = response.data;
        setPaymentMethods(paymentMethods);
      })
    }).catch((error) => {
      setErrorMessage('ERROR: ' + error);
    })
    updatedDisabledButtonList[index] = false;
    setDisabledButtonList(updatedDisabledButtonList);
  }
  const makeCardPrimary = async (index: number, id: string) => {
    setErrorMessage(undefined);
    let updatedMakeCardPrimaryList = [...makeCardPrimaryList];
    updatedMakeCardPrimaryList[index] = true;
    setMakeCardPrimaryList(updatedMakeCardPrimaryList);
    updateDefaultPaymentMethod(id).then(async () => {
      // update payment methods
      getPaymentMethods().then((response) => {
        const {payment_methods: paymentMethods} = response.data;
        setPaymentMethods(paymentMethods);
      })
    }).catch((error) => {
      setErrorMessage('ERROR: ' + error);
    });
    updatedMakeCardPrimaryList[index] = false;
    setMakeCardPrimaryList(updatedMakeCardPrimaryList);
  }

  return (
    <>
      { !addingCard && (
        <div className='card-tab'>
          <Container fluid className='p-0'>
            <Row lg={2} xs={1}>
              { paymentMethods.map((paymentMethod, index) => {
                let exp_date = paymentMethod.exp_month + '/' +  paymentMethod.exp_year.slice(2);
                if (paymentMethod.exp_month.length === 1) {
                  exp_date = "0" + exp_date;
                }
                return (
                  <Col key={index} className='p-2'>
                    <div className='outline-dashed rounded pt-4 card-display'>
                      {paymentMethod.is_default &&
                        <div className='primary-label'>Primary</div>
                      }
                      { !paymentMethod.is_default &&
                        <button
                          className='make-primary-label'
                          onClick={() => {
                            makeCardPrimary(index, paymentMethod.id);
                          }}>
                            {
                              !makeCardPrimaryList[index] &&
                              'Make Primary'
                            }
                            {
                              makeCardPrimaryList[index] &&
                              <img alt='Loading' className='loading-icon' src={toAbsoluteUrl(`/media/gifs/loading.gif`)} />
                            }
                        </button>
                      }
                      <Row>
                        <Col className='left-col' lg={3}>
                          <img alt='Credit Card Icon' className='card-icon' src={toAbsoluteUrl(`/media/icons/cards/${paymentMethod.brand}.png`)} />
                        </Col>
                        <Col className='center-col' lg={5}>
                          <Stack>
                            <p className='brand-last4'>
                              {
                                '****' +
                                paymentMethod.last4
                              }
                              
                            </p>
                            <p className='card-exp'>
                              {'Card expires at ' + exp_date}
                            </p>
                          </Stack>
                        </Col>
                        <Col className='right-col' lg={4}>
                          {!paymentMethod.is_default &&
                            <button className='btn delete-btn' disabled={disabledButtonList[index]} onClick={() => {
                              deleteCard(index, paymentMethod);
                            }}>
                              { !disabledButtonList[index] &&
                                'Delete'}
                              {
                                disabledButtonList[index] &&
                                <img alt='Loading' className='loading-icon' src={toAbsoluteUrl(`/media/gifs/loading.gif`)} />
                              }
                            </button>
                          }
                        </Col>
                      </Row>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </Container>
          { user.is_owner &&
            <Stack className='add-new-card-stack' direction='horizontal'>
              <div className='error-message'>
                {errorMessage}
              </div>
              <button
                type='button'
                onClick={() => {
                  addCard();
                }}
                className='btn btn-primary'
              >
                Add New Card
              </button>
            </Stack>
          }
        </div>
      )}
      { addingCard && (
        <div className='card-tab'>
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm parentPage='accountSettings'/>
          </Elements>
          <Stack className='error-button-stack' direction='horizontal'>
            <div className='error-message'>
              {errorMessage}
            </div>
            <Stack className='button-stack' direction='horizontal' gap={5}>
              <button
                type='button'
                onClick={() => {
                  setErrorMessage(undefined);
                  setAddingCard(false);
                }}
                className='btn btn-color-gray-400 btn-active-light-primary px-6'
              >
                Cancel
              </button>
              <button 
                onClick={() => {
                  submit();
                }}
                type='submit'
                className='btn btn-lg btn-primary mt-0'
              >
                <span className='indicator-label'>
                  { !submitting &&
                    <span className='indicator-label'>Submit</span>
                  }
                  { submitting &&
                    <img alt='Loading' className='loading-icon' src={toAbsoluteUrl(`/media/gifs/loading.gif`)} />
                  }
                </span>
              </button>
            </Stack>
          </Stack>
        </div>
      )}
    </>
  )
}
