import {FC} from 'react'
import {NavigationBar} from './components/NavigationBar'
import {Row, Col, Accordion, Button, Container, Stack} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { Footer } from './components/Footer'

const WhoWeArePage: FC = () => (
  <>
    <div className='custom-page who-we-are-page'>
      <div className='above-fold'>
        <NavigationBar />
        <Container fluid className="p-0">
          <Row>
            <Col>
              <h1>Who We Are</h1>
              <p>We've built CS operations or teams that consist of 1 to 300 people. 
                We've covered everything from activating SIM cards and iPhones to 
                selling clothing for rappers and pop stars. We've seen all the pain 
                points personally, so you don't have to.
              </p>
              <Button href='/login/registration'>GET STARTED</Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='pain-in-ass-section'>
        <Container fluid className="p-0">
          <Row>
            <Col lg={6} md={12} className='left-col'>
              {/* <Stack direction='horizontal' gap={5} className='icon-text-stack'>
                <img alt='Click Point Icon' className='click-point-img' src={toAbsoluteUrl('/media/icons/custom/point-click-black-icon.png')} />
                <p>Quick and easy</p>
              </Stack> */}
              <h2>We believe operating customer service shouldn't be a pain in the ass</h2>
            </Col>
            <Col lg={6} md={12} className='right-col'>
              <p>Call centers and hiring agencies are expensive and hard to deal with. 
                That's why most people end up hiring their friends or family to manage 
                their e-commerce: "Because it's easy. Anyone can do it."
              </p>
              <p>
                Why do you end up paying so much to hire a team, spending thousands 
                when agents are only paid by the hour? Don't end up wasting time 
                and money on building the wrong process. There is a better way to 
                get an efficient and skilled team, without having to search the 
                world for customer service.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='entrust-section'>
        <Container fluid className="p-0">
          <Row>
            <Col lg={6} md={12} className='left-col'>
            </Col>
            <Col lg={6} md={12} className='right-col'>
              {/* <Stack direction='horizontal' gap={5} className='icon-text-stack'>
                <img alt='Heart Icon' className='heart-white-img' src={toAbsoluteUrl('/media/icons/custom/heart-white-icon.png')} />
                <p>Keep customers informed by</p>
              </Stack> */}
              <h2>We've been entrusted by the best of the best</h2>
              <p className='subtitle'>
                We are your customer service partners, we help you get the most 
                out of your customers through efficient processes, up-to-date 
                technology, and easy integration.
              </p>
              <p className='subtitle'>
                We’ve been trained on Gorgias and have seen nearly every scenario 
                you could imagine. We’ll take the hits so you don’t have to.
              </p>
              <Row className='logo-row'>
                <Col sm={2} xs={4}>
                  <img alt='Khalifa Kush Logo' className='khalifa-logo' src={toAbsoluteUrl('/media/customers/khalifakush.png')} />
                </Col>
                <Col sm={2} xs={4}>
                  <img alt='CLB Logo' className='clb-logo' src={toAbsoluteUrl('/media/customers/clb.png')} />
                </Col>
                <Col sm={2} xs={4}>
                  <img alt='Logo 3' className='logo-3' src={toAbsoluteUrl('/media/customers/logo-3.png')} />
                </Col>
                <Col sm={2} xs={4}>
                  <img alt='Lana Del Rey Logo' className='lana-logo' src={toAbsoluteUrl('/media/customers/lana.png')} />
                </Col>
                <Col sm={2} xs={4}>
                  <img alt='Wing Logo' className='wing-logo' src={toAbsoluteUrl('/media/customers/wing.png')} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='elite-agents-section'>
        <Container fluid className="p-0">
          <Row>
            <Col lg={6} md={12} className='left-col'>
              <h2>Our agents are all elite</h2>
              <p className='subtitle'>
                We're a team of agents from Miami, Toronto, and Manila 
                that have been battle-tested on Gorgias and have contributed 
                to closing thousands of tickets. Our agents are the best in 
                the game, are college-educated, and have worked with some 
                of the world's best companies.
              </p>
              <Row className='logo-row'>
                <Col sm={3} xs={6}>
                  <img alt='Uber Logo' className='uber-logo' src={toAbsoluteUrl('/media/customers/uber.png')} />
                </Col>
                <Col sm={3} xs={6}>
                  <img alt='Airbnb Logo' className='airbnb-logo' src={toAbsoluteUrl('/media/customers/airbnb.png')} />
                </Col>
                <Col sm={3} xs={6}>
                  <img alt='Google Logo' className='google-logo' src={toAbsoluteUrl('/media/customers/google.png')} />
                </Col>
                <Col sm={3} xs={6}>
                  <img alt='Door Dash Logo' className='doordash-logo' src={toAbsoluteUrl('/media/customers/doordash.png')} />
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} className='right-col'>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  </>
)

export {WhoWeArePage}
