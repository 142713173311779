import {FC, useEffect, useState} from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import {getUsers} from './_requests'
import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'
import {Link} from 'react-router-dom';
import {UserModel} from '../../modules/auth'


const UsersListPage: FC = () => {
  const [users, setUsers] = useState<Array<UserModel>>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  useEffect(() => {
    const listUsers = async () => {
      getUsers().then((reponse) => {
        const {users} = reponse.data;
        let userList: Array<UserModel> = []
        for (let i = 0; i<users.length; i++) {
          userList.push(new UserModel(users[i]));
        }
        setUsers(userList);
      })
    };
    listUsers().catch((error) => {
      setErrorMessage('ERROR: ' + error);
    })
  }, [])

  return (
    <div className='admin-users-list-page'>
      <Container fluid className="p-0">
        <Row className='flex-column-reverse flex-md-row'>
          <Col className='left-col' md={12}>
            <h1>Users</h1>
            <Row className='list-header shadow-sm'>
              <Col sm={1}>
                <p className='fw-bolder'>ID</p>
              </Col>
              <Col>
                <p className='fw-bolder'>Name</p>
              </Col>
              <Col>
                <p className='fw-bolder'>Email</p>
              </Col>
              <Col>
                <p className='fw-bolder'>Type</p>
              </Col>
              <Col sm={1}>
              </Col>
            </Row>
            <Row className='list-section'>
              <Col>
              {errorMessage ? (
                <div className='card-body'>
                  <div className='error-message mt-10'>
                    {errorMessage}
                  </div>
                </div>
              ) : (
                <>
                  {users.map((user: UserModel, index) => {
                    return (
                      <Link to={`/admin/users/${user.id!.toString()}`} key={index}>
                        <div className='card-body team-member-item'>
                          <Row>
                            <Col className='item-column' sm={1}>
                              <p>{user.id}</p>
                            </Col>
                            <Col className='item-column'>
                              <p>{user.firstName} {user.lastName}</p>
                            </Col>
                            <Col className='item-column'>
                              <p>{user.email}</p>
                            </Col>
                            <Col className='item-column'>
                              <p className={`member-owner member-owner-${user.isOwner ? 'owner' : 'member'}`}>{user.isOwner ? 'Owner' : 'Member'}</p>
                            </Col>
                            <Col className='item-column arrow-column' sm={1}>
                              <img alt='Right Arrow' className='arrow-icon' src={toAbsoluteUrl('/media/icons/custom/gray-right-arrow-rounded.png')} />
                            </Col>
                          </Row>
                        </div>
                      </Link>
                    )
                  })}
                </>
              )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export {UsersListPage}
