import {FC} from 'react'
import {NavigationBar} from '../components/NavigationBar'
import {Row, Col, Accordion, Button, Container, Stack} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { Footer } from '../components/Footer'

const PrivacyPolicyPage: FC = () => (
  <>
    <div className='custom-page policy-page'>
      <div className='above-fold'>
        <NavigationBar />
        <Container fluid className="p-0">
          <Row>
            <Col>
              <h1>Privacy Policy</h1>
              <p>Last Updated March 1, 2023</p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='policy-section'>
        <p>
          Dedication Agents is committed to protecting your privacy. This privacy policy applies to those who visit dedicationagents.com 
          and subdomains of this domain (“Site” or collectively “Sites”), as well as users of Dedication Agents products and services 
          (“Service” or collectively “Services”) which is owned and operated by Dedication Agents. ("Dedication Agents", “We”, “Us” or “Our”). 
          This privacy policy describes how Dedication Agents collects, uses and shares the personal information you provide to us. 
          It also describes the choices available to you regarding the use of, your access to, and how to update and correct your personal information. 
          The use of information collected through our products and services shall be limited to the purpose of providing the products and services for 
          which our customer has contracted with Dedication Agents.
        </p>
        <p className='fw-bold'>What information do we collect?</p>
        <p>Personal Information.</p>
        <p>
          Dedication Agents may collect personally identifiable information from you such as names, addresses, telephone numbers, 
          physical addresses, email addresses, CVs (in the case of candidates), and, if applicable, company names, addresses, 
          telephone numbers, fax numbers, physical addresses, email addresses, credit card numbers or tax ID numbers as well 
          as similar information concerning technical contacts, marketing contacts, and executive contacts within your company 
          or organization (collectively, “Personal Information”).
        </p>
        <p>Non-Personal Information.</p>
        <p>
          Dedication Agents may collect non-personally identifiable information from you such as the type of browser you use, 
          your operating system, the screen resolution of your browser, your ISP, your IP address, which pages you view on the 
          Site and the time and duration of your visits to the Site (collectively, “Non-Personal Information”). Dedication Agents 
          associates Non-Personal Information with Personal Information if you register with the Site.
        </p>
        <p>User Communications.</p>
        <p>
          If you communicate with us, we may collect information relating to that communication whether it 
          takes the form of email, fax, letter, forum posting, testimonials or any other form of communication 
          between you and Dedication Agents or Submitted by you to the Site (collectively, “User Communications”).
        </p>
        <p className='fw-bold'>What do we use your information for?</p>
        <p>Any of the information we collect from you may be used in one of the following ways:</p>
        <ul>
          <li>To improve customer service</li>
          <ul>
            <li>
              In the context of customer support, your information helps us to more effectively respond to your customer service requests.
            </li>
          </ul>
          <li>To process transactions</li>
          <ul>
            <li>
              Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company 
              for any reason whatsoever, other than for the express purpose of delivering the purchased product or service requested.
            </li>
          </ul>
          <li>To send periodic emails</li>
          <ul>
            <li>
              The email address you provide in the application may be used to send you information and updates pertaining to your 
              usage, in addition to receiving occasional company news, updates, related product or service information, etc.
            </li>
          </ul>
          <li>To carry out the hiring process</li>
          <ul>
            <li>
              We use identification and contact details, job performance data, and data on your suitability for the purpose of 
              carrying out the selection process, including screening of candidates. After that personal data is processed under 
              the legal title of performance of the contract, implementation of pre-contract arrangements, i.e. preparation of 
              an employment contract or agreement. We keep your personal information for the duration of the recruitment process, 
              unless you consent to its further retention in the Candidate Database. Based on your consent, we store your information 
              in the Candidate Database and thus process your identification, contact details, and performance data to contact you with 
              another relevant job offer if any. We can also use your data from the Candidate Database to better understand what kinds of 
              job candidates are interested in the careers we have to offer. With your consent, we process the data for up to 3 years after 
              the end of the recruitment process. You may withdraw your consent at any time by contacting dpo@dedicationagents.com. 
              Data processing before revocation of your consent remains valid.
            </li>
          </ul>
          <li>Staff data</li>
          <ul>
            <li>
              To make our Site and Services more useful to you, we may collect Personal Data about your staff (including your employees, 
              contractors, subcontractors, agents and officers) by extracting this information from your Zendesk (or similar) account.
            </li>
            <li>
              This data may be processed for the purposes of monitoring how many responses to support tickets each staff member sends. 
              We may then use that data in aggregate (i.e. anonymised) in order to determine metrics associated with our services.
            </li>
            <li>
              The legal basis for this processing is based on the legitimate interests of carrying out our business, 
              providing personalized Services to you and any other legitimate interests as detailed below.
            </li>
          </ul>
          <li>To distribute marketing and advertising materials</li>
          <ul>
            <li>
              We may contact you with the relevant information about Dedication Agents products and services from time to time. 
              Most messages we will send by email. For some messages, we may use Personal Information we collect about you to help 
              us determine the most relevant information to share with you. If you do not want to receive such messages from us, 
              you will be able to tell us by selecting certain boxes on forms we use when we first collect your contact details. 
              Or you can change your marketing preferences by clicking on the unsubscribe link at the bottom of our emails.
            </li>
          </ul>
        </ul>
        <p className='fw-bold'>How do we protect your information?</p>
        <p>
          We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, 
          submit, or access your personal information. We offer the use of a secure server. All supplied sensitive information is 
          transmitted via Transport Layer Security (TLS) technology to and then stored into our database to be only accessed by those 
          authorized with special access rights to our systems, and are required to keep the information confidential. 
          Credit card information is transmitted directly to the payment processor and is not stored on our servers. 
          If you have any questions about the security of your personal information, you can contact us at dpo@dedicationagents.com
        </p>
        <p className='fw-bold'>Cookies or other tracking technologies?</p>
        <p>
          Cookies are small files that a site or its service provider transfers to your computer's hard drive through your 
          Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and 
          remember certain information.
        </p>
        <p>
          Dedication Agents uses cookies or similar technologies to analyze trends, administer the website, track users' movements around 
          the website, and to gather demographic information about our user base as a whole. You can control the use of cookies at the 
          individual browser level, but if you choose to disable cookies, it may limit your use of certain features or functions on our website or service.
        </p>
        <p>Log files.</p>
        <p>
          As is true of most websites, we gather certain information automatically. This information may include Internet protocol (IP) addresses, browser type, 
          Internet service provider (ISP), referring/exit pages, the files viewed on our site (e.g., HTML pages, graphics, etc.), operating system, date/time stamp, 
          and/or clickstream data to analyze trends in the aggregate and administer the site.
        </p>
        <p>Behavioral Targeting.</p>
        <p>
          We partner with a third party to manage our advertising on other sites. Our third party partner may use cookies or similar technologies in order to provide 
          you advertising based upon your browsing activities and interests. If you wish to opt out of interest-based advertising, you can contact us at dpo@dedicationagents.com. 
          Please note you will continue to receive generic ads.
        </p>
        <p className='fw-bold'>Do we share any information to outside parties?</p>
        <p>
          We do not sell, trade, or otherwise transfer to outside parties your personal information. We will share your personal information 
          with third parties only in the ways that are described in this privacy policy. However, non-personally identifiable visitor 
          information may be provided to other parties for marketing, advertising, or other uses.
        </p>
        <p>Service Providers.</p>
        <p>
          We may provide your personal information to companies that provide services to help us with our business activities 
          such as offering customer service, carrying out the recruiting process or processing your payment. 
          These companies are authorized to use your personal information only as necessary to provide these services to us.
        </p>
        <p>Affiliates and acquisitions.</p>
        <p>
          We may share some or all of your Personal Data with our parent company, subsidiaries, joint ventures, or other companies 
          under a common control (“Affiliates”), in which case we will require our Affiliates to honor this Privacy Policy. In the 
          event we are involved in a merger, acquisition or sale of assets we may disclose Personal Data collected by us to such 
          entities that we propose to merge with or be acquired by, and will assume the rights and obligations regarding your 
          Personal Data as described in this Privacy Policy. This includes the disclosure of information to our clients where 
          we act as a data processor.
        </p>
        <p>With your consent.</p>
        <p>
          We share information about you with third parties when you give us consent to do so. For example, we often display 
          personal testimonials of satisfied customers on our public websites. With your consent, we may post your name alongside the testimonial.
        </p>
        <p className='fw-bold'>Legal Disclaimer</p>
        <p>
          We may also disclose your personal information as required by law, such as to comply with a subpoena or similar legal process 
          when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, 
          investigate fraud, or respond to a government request.
        </p>
        <p>
          In the event Dedication Agents is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be 
          notified via email and/or a prominent notice on our website, of any change in ownership, uses of your personal information, 
          and choices you may have regarding your personal information.
        </p>
        <p>We may also disclose your personal information to any other third party with your prior consent.</p>
        <p className='fw-bold'>What are your rights as a Data Subject?</p>
        <p>
          Right of access: In accordance with Art. 15 GDPR, you may have the right to obtain confirmation from us as to whether or not your 
          personal data is processed by us, and, where that is the case, to request access to your personal data. The information about personal data 
          processing includes the purposes of the processing, the categories of personal data concerned, and the recipients or categories of recipients 
          to whom your personal data have been or may be disclosed. However, this is not an absolute right and the interests of other individuals may restrict 
          your right of access. Also, you may have the right to obtain a copy of your personal data undergoing processing. For additional copies requested, 
          we may charge a reasonable fee based on administrative costs.
        </p>
        <p>
          Right to rectification: In accordance with Art. 16 GDPR, you may have the right to obtain from us the rectification of inaccurate personal data. 
          Depending on the purposes of the processing, you may have the right to have incomplete personal data completed, including by means of providing 
          a supplementary statement.
        </p>
        <p>
          Right to erasure (right to be forgotten): In accordance with Art. 17 GDPR, you have the right to request that we delete your personal data. 
          Please keep in mind that we may keep your personal data if it is still necessary for:
        </p>
        <p>
          fulfilling our legal obligation; archival, historical, or scientific research or statistical purposes; 
          ordetermination, exercise, or defense of our legal claims.
        </p>
        <p>
          Right to restriction of processing: In accordance with Art. 18 GDPR, you have the right to request that we restrict the processing of your personal data. 
          In this case, the respective personal data will be marked accordingly and may only be processed by us for certain purposes.
        </p>
        <p>
          Right to personal data portability: In accordance with Art. 20 GDPR, you have the right to receive the personal data concerning you, which you have provided to us, 
          in a structured, commonly used, and machine-readable format and/or to request the transfer of this personal data to another entity.
        </p>
        <p>
          Right to object: If you have given your consent to the processing of your data in accordance with Art. 7 III GDPR, you may revoke your consent at any time in the future. 
          The declaration of revocation must be addressed to us and must be presented in writing or delivered by email or fax.
        </p>
        <p>Our Policy towards Children</p>
        <p>
          The Dedication Agents products and/or services are not directed at persons under 16 and we do not knowingly collect Personal Information from children under 16. 
          If you become aware that your child has provided us with Personal Information, without your consent, then please contact us using the details below so that 
          we can take steps to remove such information and terminate any account your child has created with us.
        </p>
        <p>Third party links</p>
        <p>
          Occasionally, at our discretion, we may include links to our merchant partners (third party products or services) on our Sites. These third party's websites
          have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked websites. 
          Nonetheless, we seek to protect the integrity of our Sites and welcome any feedback about these third party websites.
        </p>
        <p>Testimonials</p>
        <p>
          We display personal testimonials of satisfied customers on our Sites in addition to other endorsements. With your consent, we may post your testimonial along with your name. 
          If you wish to update or delete your testimonial, you can contact us at dpo@dedicationagents.com
        </p>
        <p>Consent to Our Terms and Conditions</p>
        <p>
          Please also visit our Terms and Conditions section establishing your consent to the use, disclaimers, and limitations of liability governing the use of this Site Information 
          Related to Data Collected through our Products and Services.
        </p>
        <p>Information Related to Data Processor or Collected for our Customers:</p>
        <p>
          Dedication Agents is considered a processor or sub-processor under the direction of our customers who are considered controllers or processors. Dedication Agents has entered 
          appropriate Data Processing Agreements with our customers controlling protection of your personal data.
        </p>
        <p>Transfer of Personal Data to Third Parties:</p>
        <p>
          We may transfer personal data to third-party processor companies (i.e. cloud data and server services) that help us provide our service. Such third party processors are located in, 
          and the transfers are limited to, the United States or countries in the European Union and these processors are controlled by data processing agreements providing the 
          same protections of your personal data.
        </p>
        <p>Access and Retention of Data Controlled by our Customers:</p>
        <p>
          Dedication Agents acknowledges that you have the right to access your personal information or ask for its removal. An individual who seeks access, or who seeks to correct, amend, or delete 
          inaccurate data should direct his/her query to the Dedication Agents's customer (the data controller). If requested to remove data we will respond within a reasonable timeframe.
        </p>
        <p>
          Dedication Agents will retain personal data we process on behalf of our customers for as long as needed to provide Services to our customer. Dedication Agents will also retain personal data as 
          necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
        </p>
        <p className='fw-bold'>How to contact us about this Policy</p>
        <p>Changes to our Privacy Policy</p>
        <p>
          We may update this Privacy Policy to reflect changes to our information practices. If we make any material changes we will notify you by email (sent to the e-mail address specified in your account) 
          or by means of a notice on this Site prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.
        </p>
        <p>Contacting Us</p>
        <p>
          If there are any questions regarding this Privacy Policy, the practices of this Site, or your dealings with this Site, you may contact us using the information below:
        </p>
        <p className='address-line'>Dedication Agents</p>
        <p className='address-line'>PO Box 701059</p>
        <p className='address-line'>PMB 99737</p>
        <p className='address-line'>Louisville, Kentucky 40270-1059 US</p>
        <p className='email-line'>dpo@dedicationagents.com</p>
      </div>
      <Footer />
    </div>
  </>
)

export {PrivacyPolicyPage}
