import {FC, createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from 'react'
import {Row, Col, Container} from 'react-bootstrap'
import {getQuestionAndAnswers} from './_requests'
import {Answer, MacrosQuestionComponent, OptionResponse, Question} from './components/MacrosQuestion'
import {ConditionalQuestion} from './components/MacrosQuestion'
import {submitMacroAnswers} from './_requests'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useAuth} from '../../../app/modules/auth'
import {OnboardingDismissal, OnboardingDismissalItem} from '../../modules/accounts/components/settings/cards/OnboardingDismissal'

type EditableContextProps = {
  editable: boolean
  setEditable: Dispatch<SetStateAction<boolean>>
}
const initEditablePropsState = {
  editable: false,
  setEditable: () => {},
}
const EditableContext = createContext<EditableContextProps>(initEditablePropsState)
function useEditable() {
  return useContext(EditableContext)
}

const MacrosSettingsPage: FC = () => {
  const [macroQuestions, setMacroQuestions] = useState<Array<Question>>([])
  const [macroAnswers, setMacroAnswers] = useState<Array<Answer>>([])
  const [macroConditionalQuestions, setMacroConditionalQuestions] = useState<Array<ConditionalQuestion>>([])
  const [optionResponses, setOptionResponses] = useState<Array<OptionResponse>>([])
  const {currentUser} = useAuth()
  const user = JSON.parse(JSON.stringify(currentUser))?.user
  useEffect(() => {
    const getQsAndAs = async () => {
      getQuestionAndAnswers().then((response) => {
        const {questions, conditional_questions: conditionalQuestions, answers, option_responses: optionResponsesData} = response.data;
        // NOTE: assuming this is already ordered by id (prob not best practice... but fine for now)
        setMacroQuestions(questions);
        // NOTE: assuming this is already ordered by parent option id (prob not best practice... but fine for now)
        setMacroConditionalQuestions(conditionalQuestions);
        setMacroAnswers(answers);
        setOptionResponses(optionResponsesData);
      })
    }
    getQsAndAs().catch((error) => {
      // TODO: setErrorMessage('ERROR: ' + error);
    });
    return () => {}
  }, []);

  const [loading, setLoading] = useState(false)
  const [editable, setEditable] = useState(false)

  function handleSubmit() {
    if (!editable) {
      setEditable(true);
    } else {
      setEditable(false);
      setLoading(true);
      const form: any = document.forms[0]
      let selectedOptions: Array<Array<string>> = [];
      let optionResponses: Array<Array<string>> = [];
      // NOTE: skipping index 0 because it's not actually an input
      let workingIndex: number = 1;
      let otherFieldCount: number;
      let selectedQuestionOptions: Array<string> = [];
      let selectedParentConditionalQuestionOptions: Array<string> = [];
      // NOTE: subtracting 1 from loop length because last value is another button
      for (let i=0; i<form.length - 1; i++) {
        if (workingIndex > i) {
          continue;
        }
        const valueArray = form[i].value.split(';');
        const questionID = valueArray[0];
        const parentConditionalQuestionID = valueArray[1];
        // first check to see if there is a selected option
        if (valueArray[2] !== 'null'){
          if (questionID !== undefined) {
            if (form[i].checked !== false) {
              selectedQuestionOptions.push(valueArray[2])
            }
            if (questionID === form[i + 1]?.value.split(';')[0]) {
              workingIndex = workingIndex + 1
              continue;
            } else {
              if (selectedQuestionOptions.length > 0) {
                selectedOptions.push(selectedQuestionOptions);
                selectedQuestionOptions = [];
              }
            }
          } else if (parentConditionalQuestionID !== undefined) {
            selectedParentConditionalQuestionOptions.push(valueArray[2]);
            if (parentConditionalQuestionID === form[i + 1]?.value.split(';')[1]) {
              workingIndex = workingIndex + 1
              continue;
            } else {
              if (selectedParentConditionalQuestionOptions.length > 0) {
                selectedOptions.push(selectedParentConditionalQuestionOptions);
                selectedParentConditionalQuestionOptions = [];
              }
            }
          }
          // next, check if there any 'other' options coming up
          otherFieldCount = parseInt(valueArray[5], 10);
          // and whether they should be added to the optionResponse or just skipped
          if (valueArray.length > 6) {
            if (otherFieldCount > 0) {
              if (otherFieldCount === 1) {
                const value = form[i + 1].value;
                optionResponses.push([
                  valueArray[2], value
                ])
              } else if (otherFieldCount === 2) {
                const value = form[i + 1].value + ' ' + form[i + 2].value;
                optionResponses.push([
                  valueArray[2], value
                ])
              }
            }
          }
        } else {
          // next, check if there any 'other' options coming up
          otherFieldCount = parseInt(valueArray[5], 10);
        }
        workingIndex = otherFieldCount + 1 + i;
      }
      submitMacroAnswers(selectedOptions, optionResponses).then((response) => {
        setLoading(false);
        setEditable(false);
        // TODO: easier for now. would need to pull provider out higher in the tree
        document.location.reload();
      }).catch((error) => {
        setLoading(false);
        setEditable(false);
      })
    }
  }
  const text = 'Macros allow us to create appropriate responses to customer inquiries. Please fill out all settings that apply.'

  return (
    <div className='custom-page macros-settings-page'>
      <Container fluid className='p-0'>
        <Row>
            <Col>
              <OnboardingDismissal item={OnboardingDismissalItem.MacrosSettings} text={text}/>
              {/* NOTE:  Will have issues using action='javascript:void(0)' with future versions of React */}
              <form onSubmit={handleSubmit} noValidate className='macros-form' name='macros-form' action='javascript:void(0);'>
                {user.is_owner &&
                  <button type='submit' className='btn btn-primary macros-button desktop-only'>
                    {editable && !loading && 'Save'}
                    {!editable && !loading && 'Edit'}
                    {!editable && loading &&
                      <img alt='Loading' className='loading-icon' src={toAbsoluteUrl(`/media/gifs/loading.gif`)} />
                    }
                  </button>
                }
                <h1>Macros Settings</h1>
                { macroQuestions.map((question, index) => {
                  // may as well pass all the conditional questions and then pick and choose in the
                  // Macros Question Component
                  let answer: Answer | null = null;
                  let conditionalAnswers: Array<Answer> = [];
                  for (let i=0; i<macroAnswers.length; i++) {
                    if (!macroAnswers[i].question_id) {
                      conditionalAnswers.push(macroAnswers[i])
                    } else if (macroAnswers[i].question_id === question.id) {
                      answer = macroAnswers[i];
                    }
                  }
                  return (
                    <EditableContext.Provider value={{editable, setEditable}} key={index}>
                      <MacrosQuestionComponent
                        index={index} question={question} answer={answer}
                        conditionalQuestions={macroConditionalQuestions}
                        // passing in all conditional answers and will sort in the questions component
                        conditionalAnswers={conditionalAnswers}
                        optionResponses={optionResponses}
                      />
                    </EditableContext.Provider>
                  )
                })}
                <div className='bottom-sticky not-desktop'>
                  <button type='submit' className='btn btn-primary macros-button-mobile'>
                    {editable && !loading && 'Save'}
                    {!editable && !loading && 'Edit'}
                    {!editable && loading &&
                      <img alt='Loading' className='loading-icon' src={toAbsoluteUrl(`/media/gifs/loading.gif`)} />
                    }
                  </button>
                </div>
              </form>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export {MacrosSettingsPage, useEditable}
