import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {PrivacyPolicyPage} from '../../../modules/main/policies/PrivacyPolicy'

const PrivacyPolicyPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PRIVACYPOLICYPAGE'})}</PageTitle>
      <PrivacyPolicyPage />
    </>
  )
}

export {PrivacyPolicyPageWrapper}
