import * as Yup from 'yup'

export interface ICreateAccount {
  jobTitle: string
  companyName: string
  website: string
  gorgiasURL: string
  phoneNumber: string
  timezone: string
}

const createCompanySchemas = [
  Yup.object({
    jobTitle: Yup.string().label('Job Title'),
    companyName: Yup.string().required().label('Company Name'),
    website: Yup.string().required().label('Website'),
    gorgiasURL: Yup.string().required().label('Gorgias URL'),
    phoneNumber: Yup.string().required().label('Phone Number'),
    timezone: Yup.string().required().label('Timezone'),
  }),
  null,
  null,
]

const inits: ICreateAccount = {
  jobTitle: '',
  companyName: '',
  website: '',
  gorgiasURL: '',
  phoneNumber: '',
  timezone: '',
}

// TODO: Maybe create Placeholder text?

export {createCompanySchemas, inits}
