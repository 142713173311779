import {FC} from 'react'
import {NavigationBar} from './components/NavigationBar'
import {Row, Col, Accordion, Button, Container, Stack} from 'react-bootstrap'
import { Footer } from './components/Footer'

const ThankYouPage: FC = () => (
  <>
    <div className='custom-page thank-you-page'>
      <div className='above-fold'>
        <NavigationBar />
        <Container fluid className="p-0">
          <Row>
            <Col>
              <h1>Thank you!</h1>
              <h2>We'll get back to you as soon as we can.</h2>
              <Button className='back-home-btn' href='/'>BACK TO HOMEPAGE</Button>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  </>
)

export {ThankYouPage}

