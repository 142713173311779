import {
  createContext, Dispatch, FC, MouseEventHandler, SetStateAction,
  useContext, useEffect, useRef, useState,
} from 'react'
import * as Yup from 'yup'
import { Col, Container, Row, Stack } from 'react-bootstrap'
import { useFormik } from 'formik'
import { getCompanyDocs, uploadFileDoc, uploadURLDoc } from '../../modules/ai/_requests'
import { UploadedDoc } from '../../modules/accounts/components/settings/ai/CompanyDocsList'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { CompanyDocsList } from '../../modules/accounts/components/settings/ai/CompanyDocsList'
import { useAuth } from '../../../app/modules/auth'
import { LaunchAIComponent } from '../../modules/ai/components/LaunchAI'


const MAX_FILE_SIZE = 102400; //100KB
const validFileExtensions = { doc: ['txt', 'doc', 'docx'] };

function isValidFileType(fileName: string) {
  return validFileExtensions.doc.indexOf(fileName.split('.').pop()!) > -1;
}
const UploadURLSchema = Yup.object().shape({
  url: Yup.string()
    .required('URL is required'),
  qaFormat: Yup.bool(),
  overwrite: Yup.bool(),
})
const UploadDocSchema = Yup.object().shape({
  overwrite: Yup.bool(),
})
export interface UploadURL {
  url: string
  qaFormat: boolean
  overwrite: boolean
}
export interface UploadDoc {
  overwrite: boolean
}
const urlInitialValues: UploadURL = {
  url: '',
  qaFormat: false,
  overwrite: false,
}
const docInitialValues: UploadDoc = {
  overwrite: false,
}

type DocsListContextProps = {
  docs: Array<UploadedDoc> | []
  setDocs: Dispatch<SetStateAction<Array<UploadedDoc> | []>>
}

const initDocsListPropsState = {
  docs: [],
  setDocs: () => {},
}
const DocsListContext = createContext<DocsListContextProps>(initDocsListPropsState)
function useDocsList() {
  return useContext(DocsListContext)
}
const AIPage: FC = () => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [selected, setSelected] = useState<File | undefined>()
  const [docs, setDocs] = useState<Array<UploadedDoc>>([])
  const [urlErrorMessage, setUrlErrorMessage] = useState<string | undefined>()
  const [docErrorMessage, setDocErrorMessage] = useState<string | undefined>()
  const inputRef = useRef(null)
  const {currentUser} = useAuth()
  // TODO: this is such a mess... really need to clean this up
  let user = undefined;
  if (currentUser) {
    // So ugly... but not sure how else to go about it
    // https://stackoverflow.com/questions/17546953/cant-access-object-property-even-though-it-shows-up-in-a-console-log
    user = JSON.parse(JSON.stringify(currentUser)).user
  }
  useEffect(() => {
    const getCompanyDocuments = async () => {
      getCompanyDocs().then((response) => {
        const {vector_uploads: uploads} = response.data
        setDocs(uploads)
      })
    }
    getCompanyDocuments().catch((error) => {
      // TODO: create toasts
      console.log('ERROR: ', error)
      // setErrorMessage('ERROR: ' + error);
    })
  }, [])
  const urlFormik = useFormik({
    initialValues: urlInitialValues,
    validationSchema: UploadURLSchema,
    onSubmit: async (values: UploadURL, { resetForm }) => {
      setSubmitting(true)
      setUrlErrorMessage(undefined)
      uploadURLDoc(values).then((response) => {
        const {vector_uploads: uploads} = response.data
        setDocs(uploads)
      }).catch((error) => {
        setUrlErrorMessage(`ERROR: ${error}`);
      }).finally(() => {
        setSubmitting(false)
        resetForm()
      })
    },
  })
  const docFormik = useFormik({
    initialValues: docInitialValues,
    validationSchema: UploadDocSchema,
    onSubmit: async (values: UploadDoc, { resetForm }) => {
      if (!isValidFileType(selected!.name.toLowerCase())) {
        setDocErrorMessage(`ERROR: Invalid file type. Valid extensions: ${validFileExtensions.doc}`)
      } else if (selected!.size > MAX_FILE_SIZE) {
        setDocErrorMessage(`ERROR: File size too large. Max file size: ${MAX_FILE_SIZE/1024}KB`)
      } else {
        setSubmitting(true)
        setDocErrorMessage(undefined)
        uploadFileDoc(selected!, values).then((response) => {
          const {vector_uploads: uploads} = response.data
          setDocs(uploads)
        }).catch((error) => {
          setDocErrorMessage(`ERROR: ${error}`);
        }).finally(() => {
          setSubmitting(false)
          resetForm()
          setSelected(undefined)
        })
      }
    },
  })
  const onFileClick = (event: any) => {
    const selectedFile = document.getElementById('selectedFile')
    selectedFile?.click()
  }
  const onFileChange = (event: any) => {
    setSelected(event.target.files[0])
    setDocErrorMessage(undefined)
  }
  const cancelFileSelection: MouseEventHandler = () => {
    setSelected(undefined)
  }
  return (
    <div className='ai-page'>
      <Container fluid className='p-0'>
        { user.company.third_party_integrations.ai_pre_launch_limiter &&
          <LaunchAIComponent aiPreLaunchLimiter={user.company.third_party_integrations.ai_pre_launch_limiter} />
        }
        <h1>AI Knowledgebase</h1>
        <Row className='doc-upload-section'>
          <Col className='doc-upload-col'>
            <ul className='nav nav-tabs' id='uploadTabs' role='tablist'>
              <li className='nav-item' role='presentation'>
                <button className='nav-link tab-1 active' id='home-tab' data-bs-toggle='tab' data-bs-target='#home' type='button' role='tab' aria-controls='home' aria-selected='true'>URL</button>
              </li>
              <li className='nav-item' role='presentation'>
                <button className='nav-link tab-2' id='profile-tab' data-bs-toggle='tab' data-bs-target='#profile' type='button' role='tab' aria-controls='profile' aria-selected='false'>Doc</button>
              </li>
            </ul>
            <div className='tab-content' id='uploadTabsContent'>
              <div className='tab-pane fade show active' id='home' role='tabpanel' aria-labelledby='home-tab'>
                <form onSubmit={urlFormik.handleSubmit} noValidate className='form'>
                  <Row>
                    <Col lg={9} className='upload-col'>
                      <input
                        type='text'
                        className='form-control form-control-lg name-input'
                        placeholder='URL'
                        {...urlFormik.getFieldProps('url')}
                      />
                      {urlFormik.touched.url && urlFormik.errors.url && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{urlFormik.errors.url}</div>
                        </div>
                      )}
                      <Stack direction='horizontal' gap={5}>
                        <Stack direction='horizontal' gap={2} className='qa-format-stack'>
                          <input
                            className='qa-format'
                            id='qaFormat'
                            type='checkbox'
                            checked={urlFormik.values.qaFormat}
                            {...urlFormik.getFieldProps('qaFormat')}
                          />
                          <label>Q&A Format</label>
                        </Stack>
                        <Stack direction='horizontal' gap={2} className='qa-format-stack'>
                          <input
                            className='qa-format'
                            id='overwrite'
                            type='checkbox'
                            checked={urlFormik.values.overwrite}
                            {...urlFormik.getFieldProps('overwrite')}
                          />
                          <label>Overwrite</label>
                        </Stack>
                        {urlErrorMessage &&
                          <div className='error-message mt-5'>
                            {urlErrorMessage}
                          </div>
                        }
                      </Stack>
                    </Col>
                    <Col lg={3} className='button-col'>
                      <button
                        type='submit'
                        className='btn btn-lg btn-primary mt-0'
                        disabled={submitting ? true : false}
                      >
                        <span className='indicator-label'>
                          { !submitting &&
                            <span>Upload</span>
                          }
                          { submitting &&
                            <img alt='Loading' className='loading-icon' src={toAbsoluteUrl(`/media/gifs/loading.gif`)} />
                          }
                        </span>
                      </button>
                    </Col>
                  </Row>
                </form>
              </div>
              <div className='tab-pane fade' id='profile' role='tabpanel' aria-labelledby='profile-tab'>
                <form onSubmit={docFormik.handleSubmit} noValidate className='form'>
                  <Row>
                    <Col lg={9} className='upload-col'>
                      { selected &&
                        <Stack direction='vertical'>
                          <Stack gap={4} direction='horizontal' className='selected-file-stack'>
                            <img src='/media/icons/duotune/arrows/arr061.svg' className='close-icon' onClick={cancelFileSelection} alt='close'/>
                            <p className='selected-file'>{selected.name}</p>
                          </Stack>
                          <Stack direction='horizontal' gap={2} className='qa-format-stack'>
                            <input
                              className='qa-format'
                              id='overwrite'
                              type='checkbox'
                              checked={docFormik.values.overwrite}
                              {...docFormik.getFieldProps('overwrite')}
                            />
                            <label>Overwrite</label>
                          </Stack>
                        </Stack>
                      }
                    </Col>
                    <Col lg={3} className='button-col'>
                      { selected &&
                        <button
                          type='submit'
                          className='btn btn-lg btn-primary mt-0'
                          disabled={submitting ? true : false}
                        >
                          <span className='indicator-label'>
                            { !submitting &&
                              <span className='indicator-label'>Upload</span>
                            }
                            { submitting &&
                              <img alt='Loading' className='loading-icon' src={toAbsoluteUrl(`/media/gifs/loading.gif`)} />
                            }
                          </span>
                        </button>
                      }
                      { !selected &&
                        <>
                          <input type="file" id="selectedFile" style={{display: 'none'}} onChange={onFileChange} ref={inputRef} />
                          <input type="button" value="Select File"  onClick={onFileClick} className='select-file'/>
                        </>
                      }
                    </Col>
                  </Row>
                  {docErrorMessage &&
                    <Row className='error-message'>
                        {docErrorMessage}
                    </Row>
                  }
                </form>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <DocsListContext.Provider value={{docs, setDocs}}>
            <CompanyDocsList />
          </DocsListContext.Provider>
        </Row>
      </Container>
    </div>
  )
}

export {AIPage, useDocsList}
