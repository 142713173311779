import { FC, useState  } from 'react'
import { Col, Row } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { launchAI } from '../../../modules/ai/_requests'
import { useAuth } from '../../../modules/auth/core/Auth'
import { UserModel } from '../../auth'


type Props = {
  aiPreLaunchLimiter: number | null
}

const LaunchAIComponent: FC<Props> = ({aiPreLaunchLimiter}) => {
  const [sequenceRunning, setSequenceRuning] = useState<boolean>(false)
  const [launchSequenceText, setLaunchSequenceText] = useState<string>('3')
  const { setCurrentUser } = useAuth();
  function clickLaunchAI() {
    launchAI().then((response) => {
      const {data: user} = response;
      runLaunchSequence(user)
    }).catch((error) => {
      console.log(`ERROR: ${error}`)
    })
  }
  function runLaunchSequence(user: UserModel) {
    setSequenceRuning(true)
    setTimeout(() => {
      setLaunchSequenceText('2')
      setTimeout(() => {
        setLaunchSequenceText('1')
        setTimeout(() => {
          setLaunchSequenceText('LAUNCHED!')
          setTimeout(() => {
            setCurrentUser(user)
          }, 2500)
        }, 1000)
      }, 1000)
    }, 1000)
  }
  return (
    <Row className='ai-launch-section'>
      <Col lg={2}>
        <img src={toAbsoluteUrl('/media/icons/custom/space-rocket-launch.png')} alt='Rocket Launch' className='rocket-img'/>
      </Col>
      { !sequenceRunning &&
        <Col lg={7} className='launch-col'>
          <p className='launch-text'>
            You get <span className='bold-text'>{aiPreLaunchLimiter} FREE </span>
            AI tickets per month, <span  className='bold-text'>FOREVER</span>. Once you're ready to launch Dedication AI 
            to handle all your tickets, just click the launch button.
          </p>
        </Col>
      }
      { !sequenceRunning &&
        <Col lg={3} className='launch-col'>
          <button className="btn-primary btn-salmon" onClick={clickLaunchAI}>
            LAUNCH
          </button>
        </Col>
      }
      { sequenceRunning &&
        <Col lg={10} className='launch-sequence-col'>
          <h3 className='launch-sequence-text'>{launchSequenceText}</h3>
        </Col>
      }
    </Row>
  )
}

export { LaunchAIComponent }
