import {FC} from 'react'
import {Row, Col, Accordion, Button, Container} from 'react-bootstrap'

const FAQFull: FC = () => (
  // TODO: eventually should prob find a way to pull out the data (including a tag elements)
  // and make this reusable for all FAQs (full & mini)
  <>
     <div className='faq-section'>
        <Container fluid className="p-0">
          <Row>
            <Col lg={12}>
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <p  className='question-text'>
                      Where are Dedication Agents based?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      Dedication Agents are home-based!
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <p  className='question-text'>
                      I want to have a 24/7 team, how much will that cost?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      You can have it as an add-on. You may refer to our
                      <a href='/pricing' className='inline-link'> pricing page </a>
                      for more information on your plan and other add-ons.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <p  className='question-text'>
                      I already have an existing CS team and only want to use Dedication Agents 
                      for level 1 tickets.
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      We can definitely work on your T1 tickets and escalate all other tickets that need your attention.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <p className='question-text'>
                      What if I want Dedication Agents to assimilate to my team?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      That's perfectly fine! If you need extra hands to help out with CS, we can make that happen.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <p className='question-text'>
                      Can Dedication Agents take calls?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      Not yet! This feature will be available in the future, however, as of now, we only support chat.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <p className='question-text'>
                      Is there a free trial? I don't want to commit yet.
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      Unfortunately, we don't offer free trials at this time.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <p className='question-text'>
                      Where is Dedication Agents located?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      Our headquarters is located in Miami, Florida.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    <p className='question-text'>
                      What kinds of tasks can I deligate to Dedication Agents, outside of customer support on Gorgias?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      As long as the task is related to the ecom store, then Dedication Agents can handle it!
                    </p>
                    <p className='answer-text'>
                      Here are some examples of tasks people have asked Dedication Agents to do:
                    </p>
                    <ul>
                      <li className='answer-text'>
                        Pulling inventory sheets from Shopify or other ecommerce websites
                      </li>
                      <li className='answer-text'>
                        Updating FAQs page on your website
                      </li>
                      <li className='answer-text'>
                        Sending you an itemized list of every reported chargeback
                      </li>
                    </ul>
                    <p className='answer-text'>
                      Of course, if you're tasking out work outside of Gorgias, you'll need to provide the necessary credentials. 
                      These requests will be billed additionally, at an hourly rate.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    <p className='question-text'>
                      What is the hourly rate for additional tasks outside of Gorgias?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      Hourly rates for additional tasks start at $15/hour.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    <p className='question-text'>
                      Which tasks can I assign to Dedication Agents that won't billed as additional hours?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      Anything within Gorgias! Here are some examples:
                    </p>
                    <ul>
                      <li className='answer-text'>
                        Add/Update macros
                      </li>
                      <li className='answer-text'>
                        Add/Update rule
                      </li>
                      <li className='answer-text'>
                        Escalate refund tickets to Client View
                      </li>
                      <li className='answer-text'>
                        Add new Client View
                      </li>
                      <li className='answer-text'>
                        Add new issue tagging
                      </li>
                      <li className='answer-text'>
                        Pull Gorgias ticket data
                      </li>
                    </ul>
                    <p  className='answer-text'>
                      These types of tasks will have no additional charges!
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    <p className='question-text'>
                      My business is based outside of the US, can I still subscribe to Dedication Agents?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      We can only cater to US based clients as of right now.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>
                    <p className='question-text'>
                      My business is not ecommerce-related. Can I still use Dedication Agents?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      Unfortunately not. We only cater to e-commerce clients.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                  <Accordion.Header>
                    <p className='question-text'>
                      I don't want to use Gorgias, but I want to subscribe to Dedication Agents.
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      Sorry, but no thanks. We only work with Gorgias because it's awesome! 
                      You can read more about them  <a href='https://www.gorgias.com/' target='_blank' className='inline-link'>here</a>. 
                      If you already have an existing help desk, we can look into migrating you over to Gorgias.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13">
                  <Accordion.Header>
                    <p className='question-text'>
                      What if I want to use Dedication Agents as additional support to my existing CS team?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      That's perferctly fine! If you need extra hands to help out with CS, we can make that happen.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="14">
                  <Accordion.Header>
                    <p className='question-text'>
                      Are there hidden fees?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      Nope! You pay a monthly subscription fee based on the plan you selected and an hourly rate 
                      for tasks outside of Gorgias. You will also be charged for any add ons you've applied to your account.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="15">
                  <Accordion.Header>
                    <p className='question-text'>
                      Can I cancel in the middle of my billing cycle?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      You may cancel in the middle of your billing cycle, however, you will not 
                      be refunded for the remainder of the billing cycle.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="16">
                  <Accordion.Header>
                    <p className='question-text'>
                      Can I change the frequency of my newsletters and reports?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      Yes! Just let us know if you prefer to receive daily, bi-weekly, monthly, or quarterly reports.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="17">
                  <Accordion.Header>
                    <p className='question-text'>
                      How will I know if my account is at my plan's ticket limit?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      We'll provide you a usage report whenever we hit 25%, 50%, and 90% usage.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="18">
                  <Accordion.Header>
                    <p className='question-text'>
                      I didn't reach 100% usage this month. Will the remaining ticket balance carry over to next month?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      No, your remaining ticket balance will not carry over.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>
  </>
)

export {FAQFull}
