import React, {useEffect, useState} from 'react'
import {getInvoices} from '../../../billing/_requests'
import {Container, Col, Row} from 'react-bootstrap'

interface Invoice {
  status: string
  amount_due: number
  amount_paid: number
  created: string
  start_period: string
  end_period: string
}

export function BillingHistory() {
  const [invoices, setInvoices] = useState<Array<Invoice>>([])
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const greenStatusList = ['paid']
  const grayStatusList = ['draft', 'open']
  const redStatusList = ['uncollectable', 'void']

  useEffect(() => {
    const getInvoiceList = async () => {
      getInvoices().then((response) => {
        const {invoices} = response.data;
        setInvoices(invoices);
      });
    };
    getInvoiceList().catch((error) => {
      setErrorMessage('ERROR: ' + error);
    });
    return () => {}
  }, [])
  return (
    <>
      <Container fluid className='p-0'>
        { invoices.map((invoice, index) => {
          const status = invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1);
          return (
            <Row key={index} className='outline-dashed rounded pt-4 card-display mb-5 billing-history-card'>
              <Col className='column' xs={6} md={4} lg={2}>
                <p className='billing-title'>Date Created</p>
                <p className='billing-value'>
                  {invoice.created}
                </p>
              </Col>
              <Col className='column' xs={6} md={4} lg={2}>
                <p className='billing-title'>Status</p>
                { greenStatusList.includes(invoice.status) &&
                  <div className='green-status-label'>{status}</div>
                }
                { grayStatusList.includes(invoice.status) &&
                  <div className='gray-status-label'>{status}</div>
                }
                { redStatusList.includes(invoice.status) &&
                  <div className='red-status-label'>{status}</div>
                }
              </Col>
              <Col className='column' xs={6} md={4} lg={2}>
                <p className='billing-title'>Period Start</p>
                <p className='billing-value'>{invoice.start_period}</p>
              </Col>
              <Col className='column' xs={6} md={4} lg={2}>
                <p className='billing-title'>Period End</p>
                <p className='billing-value'>{invoice.end_period}</p>
              </Col>
              <Col className='column' xs={6} md={4} lg={2}>
                <p className='billing-title'>Amount Due</p>
                <p className='billing-value'>${invoice.amount_due}</p>
              </Col>
              <Col className='column' xs={6} md={4} lg={2}>
                <p className='billing-title'>Amount Paid</p>
                <p className='billing-value'>${invoice.amount_paid}</p>
              </Col>
            </Row>
          )
        })}
        <div className='error-message'>
          {errorMessage}
        </div>
      </Container>
    </>
  )
}
