import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {WhatWeDoPage} from '../../modules/main/WhatWeDoPage'

const WhatWeDoPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.WHATWEDOPAGE'})}</PageTitle>
      <WhatWeDoPage />
    </>
  )
}

export {WhatWeDoPageWrapper}
