import axios from 'axios'

import { FinalizeInvoiceDetails } from '../wizards/components/CompanySetup'

const API_URL = process.env.REACT_APP_API_URL
const CREATE_SUBSCRIPTION_URL = `${API_URL}/stripe/subscription/`
const FINALIZE_INVOICE_URL = `${API_URL}/stripe/finalize-initial-invoice/`
const PAYMENT_METHODS_LIST_URL = `${API_URL}/stripe/payment-methods/`
const SETUP_INTENT_URL = `${API_URL}/stripe/setup-intent/`
const DELETE_PAYMENT_METHOD_URL = `${API_URL}/stripe/payment-method/delete/`
const UPDATE_PAYMENT_METHOD_URL = `${API_URL}/stripe/payment-method/update-default/`
const INVOICE_LIST_URL = `${API_URL}/stripe/invoices/`
const CHANGE_SUBSCRIPTION_PLAN_URL = `${API_URL}/stripe/update-subscription/`
const DISCOUNT_CODES_URL = `${API_URL}/stripe/discount-codes/`

export async function createStripeSubscription(plan_id: string) {
  // Need to remove headers for CORS
  const ax = axios.create({
    transformRequest: (data, headers) => {
        // Remove all shared headers
        delete headers!.common;
    }
  });
  const res = await ax.get('https://geolocation-db.com/json/');
  return axios.post(
    CREATE_SUBSCRIPTION_URL, {
      "plan_id": plan_id,
      "ip_address": res.data.IPv4,
    }
  )
}

export function finalizeStripeInvoice(details: FinalizeInvoiceDetails){
  return axios.post(
    FINALIZE_INVOICE_URL, {
      "job_title": details.jobTitle,
      "name": details.companyName,
      "website": details.website,
      "gorgias_url": details.gorgiasURL,
      "phone_number": details.phoneNumber,
      "timezone": details.timezone,
    }
  )
}

// Billing
export async function getPaymentMethods() {
  return axios.get(PAYMENT_METHODS_LIST_URL)
}
export async function createSetupIntent() {
  // For adding new payment methods
  return axios.post(SETUP_INTENT_URL)
}
export async function updateSubscriptionPlan(plan_id: string) {
  return axios.post(
      CHANGE_SUBSCRIPTION_PLAN_URL, {
      'plan_id': plan_id,
  })
}
export async function deletePaymentMethod(payment_method: string) {
  return axios.post(
      DELETE_PAYMENT_METHOD_URL, {
      'payment_method': payment_method
  })
}
export async function updateDefaultPaymentMethod(payment_method_id: string) {
  return axios.post(
      UPDATE_PAYMENT_METHOD_URL, {
      'id': payment_method_id
  })
}
export async function getInvoices() {
  return axios.get(INVOICE_LIST_URL)
}

export async function applyDiscountCode(code: string) {
  return axios.post(DISCOUNT_CODES_URL, {
    'code': code
  })
}
