import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {MacrosSettingsPage} from '../../modules/macros/MacrosSettingsPage'

const MacrosSettingsPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MACROSSETTINGSPAGE'})}</PageTitle>
      <MacrosSettingsPage />
    </>
  )
}

export {MacrosSettingsPageWrapper}
