import {FC} from 'react'
import {NavigationBar} from './components/NavigationBar'
import {Row, Col, Accordion, Button, Container, Stack} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { Footer } from './components/Footer'
import { FAQMini } from '../../../_custom/partials/FAQMini'

const WhatWeDoPage: FC = () => (
  <>
    <div className='custom-page what-we-do-page'>
      <div className='above-fold'>
        <NavigationBar />
        <Container fluid className="p-0">
          <Row>
            <Col>
              <h1>What Dedication Agents Do</h1>
              <h3>We are battle-tested e-commerce agents that are proficient across 
                  the board for common issues.
              </h3>
              <Stack className='icon-vertical-stack'>
                <Stack className='icon-stack' direction='horizontal' gap={5}>
                  <img alt='Tracking Update' className='skill-icon' src={toAbsoluteUrl('/media/icons/custom/tracking-update.png')} />
                  <img alt='Tracking Update' className='skill-icon'src={toAbsoluteUrl('/media/icons/custom/courier-claims.png')} />
                  <img alt='Tracking Update' className='skill-icon' src={toAbsoluteUrl('/media/icons/custom/order-consolidation.png')} />
                  <img alt='Tracking Update' className='skill-icon' src={toAbsoluteUrl('/media/icons/custom/address-change.png')} />
                  <img alt='Tracking Update' className='skill-icon' src={toAbsoluteUrl('/media/icons/custom/out-of-stock.png')} />
                </Stack>
                <Stack className='icon-stack' direction='horizontal' gap={5}>
                  <img alt='Tracking Update' className='skill-icon' src={toAbsoluteUrl('/media/icons/custom/return-labels.png')} />
                  <img alt='Tracking Update' className='skill-icon'src={toAbsoluteUrl('/media/icons/custom/idle-shipments.png')} />
                  <img alt='Tracking Update' className='skill-icon' src={toAbsoluteUrl('/media/icons/custom/fulfillment.png')} />
                  <img alt='Tracking Update' className='skill-icon' src={toAbsoluteUrl('/media/icons/custom/faq-updates.png')} />
                </Stack>
                <Stack className='icon-stack' direction='horizontal' gap={5}>
                  <img alt='Tracking Update' className='skill-icon'src={toAbsoluteUrl('/media/icons/custom/discount-codes.png')} />
                  <img alt='Tracking Update' className='skill-icon' src={toAbsoluteUrl('/media/icons/custom/refund-requests.png')} />
                  <img alt='Tracking Update' className='skill-icon' src={toAbsoluteUrl('/media/icons/custom/chargeback.png')} />
                </Stack>
              </Stack>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='provide-ecom-section'>
        <Container fluid className="p-0">
          <Row>
            <Col className='left-col' md={12} lg={6}>
              <h2>We provide ecom customer service on Gorgias, period.</h2>
              <Stack direction='horizontal' gap={5} className='horizontal-stack'>
                <Stack className='vertical-stack-left' gap={4}>
                  <img alt='Helmet Icon' className='helmet-icon'src={toAbsoluteUrl('/media/icons/custom/helmet-icon.png')} />
                  <img alt='Logo' src={toAbsoluteUrl('/media/logos/default-black.png')} className='da-logo' />
                  <p>Dedication Agents are drivers</p>
                </Stack>
                <img alt='Vertical Divider' className='vertical-divider'src={toAbsoluteUrl('/media/icons/custom/vertical-divider.png')} />
                <Stack className='vertical-stack-right' gap={4}>
                  <img alt='Car Icon' className='car-icon'src={toAbsoluteUrl('/media/icons/custom/car-icon.png')} />
                  <img alt='Gorigias Logo' className='gorgias-logo' src={toAbsoluteUrl('/media/icons/custom/gorgias.png')} />
                  <p>Gorgias is the vehicle</p>
                </Stack>
              </Stack>
            </Col>
            <Col className='right-col' md={12} lg={6}>
              <p>
                Good CS can build a loyal customer base and boost revenue - but not if it's 
                hard, time-consuming, and expensive to maintain.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div className='sorry-no-section'>
        <Container fluid className="p-0">
          <Row>
            <Col lg={12}>
              <Stack direction='horizontal'>
                <img alt='Point Click Icon' className='point-click-icon' src={toAbsoluteUrl('/media/icons/custom/point-click-icon.png')} />
                <p>In case you were wondering</p>
              </Stack>
              <h2>Sorry, But No Thanks.</h2>
              <p className='paragraph-text'>
                Dedication Agents are focused specifically on eCommerce customer support on Gorgias. 
                Our agents' background in eCommerce allows them to identify issues quickly 
                and provide your customers with rapid responses.
              </p>
              <p className='paragraph-text'>
                As much as we love your users, we aren't the right people to educate them on your product. 
                For this reason, we don't handle onboarding, activation, troubleshooting, 
                inventory requirements, or communicating with your fulfillment team.
              </p>
              <Button>CHECK OUT MORE OF OUR WORK</Button>
            </Col>
          </Row>
        </Container>
      </div> */}
      <div className='grid-section'>
        <Container className="p-0">
          <Row>
            <Col lg={126} className='left-col'>
              <h2>We give you customer service muscle to supercharge your user experience</h2>
            </Col>
          </Row>
          <Row className='row-one gx-0'>
            <Col className='col-one' sm={12} lg={4}>
              <div className='col-inner'>
                <Stack gap={3}>
                  <img alt='Exclamation Icon' className='exclamation-img' src={toAbsoluteUrl('/media/icons/custom/blue-exclamation.png')} />
                  <h4>No hiring, training, or employee management</h4>
                  <p>Ideal for when you want tweaks to the design, a new landing page built, or a new team member trained</p>
                </Stack>
              </div>
            </Col>
            <Col className='col-two' sm={12} lg={4}>
              <div className='col-inner'>
                <Stack gap={3}>
                  <img alt='Letter Icon' className='letter-img' src={toAbsoluteUrl('/media/icons/custom/blue-letter.png')} />
                  <h4>Open communcations</h4>
                  <p>Chat directly with Dedication Agents as your escalation manager</p>
                </Stack>
              </div>
            </Col>
            <Col className='col-three' sm={12} lg={4}>
              <div className='col-inner'>
                <Stack gap={3}>
                  <img alt='Globe Icon' className='globe-img' src={toAbsoluteUrl('/media/icons/custom/blue-globe.png')} />
                  <h4>Streamlined Gorgias setup</h4>
                  <p>Once you've got your domain, there's literally nothing additonal you need to pay for</p>
                </Stack>
              </div>
            </Col>
          </Row>
          <Row className='gx-0'>
            <Col className='col-one' sm={12} lg={4}>
              <div className='col-inner'>
                <Stack gap={3}>
                  <img alt='Muscle Icon' className='muscle-img' src={toAbsoluteUrl('/media/icons/custom/blue-muscle.png')} />
                  <h4>Trained e-commerce customer support muscle ready to go</h4>
                  <p>Be open 24/7 with a custom built e-commerce store that's easy to use and easy to make money with</p>
                </Stack>
              </div>
            </Col>
            <Col className='col-two' sm={12} lg={4}>
              <div className='col-inner'>
                <Stack gap={3}>
                  <img alt='Cycle Icon' className='cycle-img' src={toAbsoluteUrl('/media/icons/custom/blue-cycle.png')} />
                  <h4>Syncing user messaging</h4>
                  <p>Streamlined FAQ policies, macro templates, and terms and conditions</p>
                </Stack>
              </div>
            </Col>
            <Col className='col-three' sm={12} lg={4}>
              <div className='col-inner'>
                <Stack gap={3}>
                  <img alt='Gorgias Logo' className='gorgias-img' src={toAbsoluteUrl('/media/icons/custom/blue-gorgias.png')} />
                  <h4>Gorgias Certified</h4>
                  <p>Setup dashboard views, integrate accounts, install macro responses, setup tagging, routing, and escalation paths</p>
                </Stack>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='specialize-d2c-section'>
        <Container fluid className="p-0">
          <Row>
            <Col lg={6} className='left-col'>
              <h2>We specialize in D2C e-commerce issues</h2>
            </Col>
            <Col lg={6} className='right-col'>
              <p>We focus on exactly what we're best at: e-commerce. If you're looking for 
                technical support, onboarding or activations... Sorry, but no thanks.
              </p>
            </Col>
          </Row>
          <Row className='custom-table'>
            <Stack className='top-table-row'>
              <Stack direction='horizontal' className='header-stack'>
                <p className='table-header-one ms-auto'>Dedication</p>
                <p className='table-header-two'>Call Center</p>
              </Stack>
              <Stack direction='horizontal'>
                <p className='table-text'>Fixed pricing plans that you can choose from</p>
                {/* <img  alt='Black Check Icon'className='first-icon black-check ms-auto' src={toAbsoluteUrl('/media/icons/custom/black-check.png')} />
                <img  alt='Black X Icon'className='second-icon black-x' src={toAbsoluteUrl('/media/icons/custom/black-x.png')} /> */}
                <img alt='Black Check Icon' className='first-icon black-check ms-auto' src={toAbsoluteUrl('/media/icons/custom/black-check.png')} />
                <img alt='Black X Icon' className='second-icon black-x' src={toAbsoluteUrl('/media/icons/custom/black-x.png')} />
              </Stack>
            </Stack>
            <Stack direction='horizontal' className='table-row'>
              <p className='table-text'>Completely remote and full-time team</p>
              <img alt='Black Check Icon' className='first-icon black-check ms-auto' src={toAbsoluteUrl('/media/icons/custom/black-check.png')} />
              <img alt='Black X Icon' className='second-icon black-x' src={toAbsoluteUrl('/media/icons/custom/black-x.png')} />
            </Stack>
            <Stack direction='horizontal' className='table-row'>
              <p className='table-text'>Ready to go Gorgias-trained agents</p>
              <img alt='Black Check Icon' className='first-icon black-check ms-auto' src={toAbsoluteUrl('/media/icons/custom/black-check.png')} />
              <img alt='Black X Icon' className='second-icon black-x' src={toAbsoluteUrl('/media/icons/custom/black-x.png')} />
            </Stack>
            <Stack direction='horizontal' className='table-row'>
              <p className='table-text'>Training agents on Gorgias platform</p>
              <img alt='Black Check Icon' className='first-icon black-check ms-auto' src={toAbsoluteUrl('/media/icons/custom/black-check.png')} />
              <img alt='Black X Icon' className='second-icon black-x' src={toAbsoluteUrl('/media/icons/custom/black-x.png')} />
            </Stack>
          </Row>
        </Container>
      </div>
      <FAQMini />
      <Footer />
    </div>
  </>
)

export {WhatWeDoPage}
