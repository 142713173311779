import React, {useState} from 'react'
import {ThirdPartyIntegrationsModel} from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {updateThirdPartyIntegrations} from '../../../_requests'
import { Stack } from 'react-bootstrap'

import {useAuth} from '../../../../../../app/modules/auth'

const integrationsSchema = Yup.object().shape({
  gorgiasURL: Yup.string().required('Gorgias URL is required'),
  shopifyURL: Yup.string().nullable(),
  shipstationURL: Yup.string().nullable(),
})

const ThirdPartyIntegrations: React.FC = () => {
  const {currentUser} = useAuth();
  const user = JSON.parse(JSON.stringify(currentUser))?.user;
  const integrations = user.company.third_party_integrations;
  const [errorMsg, setErrorMsg] = useState('')
  let gorgiasURL = '';
  let shopifyURL = '';
  let shipstationURL = '';
  if (integrations) {
    gorgiasURL = integrations.gorgias_url;
    shopifyURL = integrations.shopify_url;
    shipstationURL = integrations.shipstation_url;
  }
  const initialValues: ThirdPartyIntegrationsModel = {
    gorgiasURL: gorgiasURL,
    shopifyURL: shopifyURL || '',
    shipstationURL: shipstationURL || '',
  }
  const [loading, setLoading] = useState(false)
  const [editable, setEditable] = useState(false)
  const formik = useFormik<ThirdPartyIntegrationsModel>({
    initialValues,
    validationSchema: integrationsSchema,
    onSubmit: async (values) => {
      if (editable) {
        setEditable(false);
        setLoading(true);
        updateThirdPartyIntegrations(values).then((response) => {
          setEditable(false);
        }).catch((error) => {
          setErrorMsg("Error: " + error);
        })
      } else {
        setEditable(true);
      }
      setLoading(false);
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Integrations</h3>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Gorgias URL</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='Gorgias URL'
                  {...formik.getFieldProps('gorgiasURL')}
                  disabled={!editable}
                />
                {formik.touched.gorgiasURL && formik.errors.gorgiasURL && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.gorgiasURL}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                Shopify URL
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='Shopify URL'
                  {...formik.getFieldProps('shopifyURL')}
                  disabled={!editable}
                />
                {formik.touched.shopifyURL && formik.errors.shopifyURL && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.shopifyURL}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                Shipstation URL
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='Shipstation URL'
                  {...formik.getFieldProps('shipstationURL')}
                  disabled={!editable}
                />
                {formik.touched.shipstationURL && formik.errors.shipstationURL && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.shipstationURL}</div>
                  </div>
                )}
              </div>
            </div>
            <p className="error-message">
              {errorMsg}
            </p>
            { user.is_owner &&
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <Stack direction='horizontal' gap={5}>
                  { editable && <button
                    type='button'
                    onClick={() => {
                      setEditable(false)
                    }}
                    className='btn btn-color-gray-400 btn-active-light-primary px-6'
                  >
                    Cancel
                  </button> }
                  <button type='submit' className='btn btn-primary' disabled={loading}>
                    {!loading && !editable && 'Edit'}
                    {!loading && editable && 'Save Changes'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </Stack>
              </div>
            }
          </div>
        </form>
      </div>
    </div>
  )
}

export {ThirdPartyIntegrations}
