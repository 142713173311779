import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {InvitationPage} from '../../modules/main/InvitationPage'

const InvitationPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.INVITATIONPAGE'})}</PageTitle>
      <InvitationPage />
    </>
  )
}

export {InvitationPageWrapper}
