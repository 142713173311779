import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const COMPANIES_URL = `${API_URL}/companies/`
const USERS_URL = `${API_URL}/users/`

export async function getCompanies() {
  return axios.get(COMPANIES_URL);
}

export async function getCompany(id: string) {
  return axios.get(COMPANIES_URL + id + '/');
}

export async function getUser(id: string) {
  return axios.get(USERS_URL + id + '/');
}

export async function getUsers() {
  return axios.get(USERS_URL);
}
