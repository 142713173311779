/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {CompaniesListPage} from './CompaniesListPage'

const AdminDashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ADMIN_DASHBOARD'})}</PageTitle>
      <CompaniesListPage />
    </>
  )
}

export {AdminDashboardWrapper}
