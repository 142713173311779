import React from 'react'
import {ProfileDetails} from './cards/ProfileDetails'
import {CompanyDetails} from './cards/CompanyDetails'
import {OnboardingDismissal} from './cards/OnboardingDismissal'
import {ThirdPartyIntegrations} from './cards/ThirdPartyIntegrations'
import {SignInMethod} from './cards/SignInMethod'
import {ConnectedAccounts} from './cards/ConnectedAccounts'
import {EmailPreferences} from './cards/EmailPreferences'
import {Notifications} from './cards/Notifications'
import {DeactivateAccount} from './cards/DeactivateAccount'
import {OnboardingDismissalItem} from './cards/OnboardingDismissal'

import {useAuth} from '../../../../../app/modules/auth'

export function Settings() {
  const {currentUser} = useAuth()
  const googleID = JSON.parse(JSON.stringify(currentUser))?.user.company.google_id;
  const text = 'While not all fields are required, the more information you can provide, the better we can service your account.'
  return (
    <>
      <OnboardingDismissal item={OnboardingDismissalItem.AccountSettings} text={text}/>
      <CompanyDetails />
      <ThirdPartyIntegrations />
      <ProfileDetails />
      {/* Only show Sign In section if user isn't signed in using Google */}
      {googleID === undefined && <SignInMethod />}
      {/* <ConnectedAccounts /> */}
      {/* <EmailPreferences /> */}
      {/* <Notifications /> */}
      {/* <DeactivateAccount /> */}
    </>
  )
}
