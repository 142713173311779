import {FC, useEffect, useState} from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import {getCompanies} from './_requests'
import {CompanyDetailsModel} from '../../modules/accounts/components/settings/SettingsModel'
import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'
import {Link} from 'react-router-dom';


const CompaniesListPage: FC = () => {
  const [companies, setCompanies] = useState<Array<CompanyDetailsModel>>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  useEffect(() => {
    const listCompanies = async () => {
      getCompanies().then((reponse) => {
        const {companies} = reponse.data;
        let coList: Array<CompanyDetailsModel> = []
        for (let i = 0; i<companies.length; i++) {
          const company = companies[i];
          coList.push(new CompanyDetailsModel(company));
        }
        setCompanies(coList);
      })
    };
    listCompanies().catch((error) => {
      setErrorMessage('ERROR: ' + error);
    })
  }, [])

  return (
    <div className='admin-companies-list-page'>
      <Container fluid className="p-0">
        <Row className='flex-column-reverse flex-md-row'>
          <Col className='left-col' md={12}>
            <h1>Companies</h1>
            <Row className='list-header shadow-sm'>
              <Col sm={1}>
                <p className='fw-bolder'>ID</p>
              </Col>
              <Col>
                <p className='fw-bolder'>Name</p>
              </Col>
              <Col>
                <p className='fw-bolder'>Website</p>
              </Col>
              <Col>
                <p className='fw-bolder'>Phone</p>
              </Col>
              <Col sm={1}>
              </Col>
            </Row>
            <Row className='list-section'>
              <Col>
                {errorMessage ? (
                  <div className='card-body'>
                    <div className='error-message mt-10'>
                      {errorMessage}
                    </div>
                  </div>
                ) : (
                  <>
                    {companies.map((company: CompanyDetailsModel, index) => {
                      return (
                        <Link to={company.id!.toString()} key={index}>
                          <div className='card-body company-item'>
                            <Row>
                              <Col className='item-column' sm={1}>
                                <p>{company.id}</p>
                              </Col>
                              <Col className='item-column'>
                                <p className='fw-bold'>{company.name}</p>
                              </Col>
                              <Col className='item-column'>
                                <p>{company.website}</p>
                              </Col>
                              <Col className='item-column'>
                                <p>
                                  {company.phoneNumber?.slice(0,3)}-{company.phoneNumber?.slice(3,6)}-{company.phoneNumber?.slice(6,10)}
                                </p>
                              </Col>
                              <Col className='item-column arrow-column' sm={1}>
                                <img alt='Right Arrow' className='arrow-icon' src={toAbsoluteUrl('/media/icons/custom/gray-right-arrow-rounded.png')} />
                              </Col>
                            </Row>
                          </div>
                        </Link>
                      )
                    })}
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export {CompaniesListPage}
