import {FC, useEffect, useState} from 'react'
import {Row, Col, Container} from 'react-bootstrap'
import {getMacros} from './_requests'

const MacrosSnippetsPage: FC = () => {
  const [macros, setMacros] = useState<Array<string>>([])
  useEffect(() => {
    const getMacrosTexts = async () => {
      await getMacros().then((response) => {
        const {macros} = response.data;
        setMacros(macros);
      })
    }
    getMacrosTexts().catch((error) => {
      // setErrorMessage('ERROR: ' + error);
    });
    return () => {}
  }, []);
  
  if (macros.length > 0) {
    return (
      <div className='macros-snippets-page'>
        <Row>
          <Col>
            <h1>Macros Snippets</h1>
            { macros.map((macro, index) => {
              return (
                <div className='macros-snippet' key={index}>
                  <p dangerouslySetInnerHTML={{__html: macro}}></p>
                </div>
              )
            })}
          </Col>
        </Row>
      </div>
    )
  } else {
    return  (
      <div className='macros-snippets-page'>
        <Row>
          <Col>
            <h1>Macros Snippets</h1>
            <div className='macros-snippet'>
              <p>No Macro Snippets available just yet. Please update settings <a href='/business-ops/settings'>here</a></p>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export {MacrosSnippetsPage}
