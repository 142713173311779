import {FC, useState} from 'react'
import {NavigationBar} from './components/NavigationBar'
import {Row, Col, Button, Container, Stack} from 'react-bootstrap'
import { Footer } from './components/Footer'
import {ErrorMessage, Field, Formik, Form, FormikValues} from 'formik'
import * as Yup from 'yup'
import {requestInvitation} from '../notifications/_requests'
import { useNavigate } from "react-router-dom"

const invitationSchema = Yup.object({
  name: Yup.string().required().label('Name'),
  email: Yup.string().email('Invalid email address').required().label('Email'),
  website: Yup.string().required().label('Website'),
  integrations: Yup.array().required().label('Integrations'),
})
export interface RequestInvitation {
  name: string
  website: string
  email: string
  integrations: string
}
const inits: RequestInvitation = {
  name: '',
  website: '',
  email: '',
  integrations: '',
}


const InvitationPage: FC = () => {
  const navigate = useNavigate()
  const [initValues] = useState<RequestInvitation>(inits)
  const [loading, setLoading] = useState(false)
  const submitInvitationRequest = async (values: RequestInvitation, actions: FormikValues) => {
    setLoading(true);
    // TODO: make sure this try catch works here
    try {
      await requestInvitation(values)
      navigate('/thank-you');
    } catch (error) {
      console.log("ERROR: ", error);
      // TODO: show error
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='custom-page'>
      <div className='invitation-above-fold'>
        <NavigationBar />
        <Container fluid className="p-0">
          <Row className='flex-column-reverse flex-lg-row'>
            <Col className='left-col' lg={7}>
              <Stack>
                <h1>Request an invite</h1>
                <h3>Dedication agents are eCommerce-specific agents. Request an invite and our team 
                  will get back to you within 24 hours.
                </h3>
                <Formik validationSchema={invitationSchema} initialValues={initValues} onSubmit={submitInvitationRequest}>
                  {() => (
                    <Form className='mx-auto mw-1000px w-100 pt-15 pb-10' id='kt_invite_request_form'>
                      <div className='w-100'>
                        <div className='fv-row mb-10'>
                          <Field
                            name='name'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='Name*'
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='name' />
                          </div>
                        </div>
                        <div className='fv-row mb-10'>
                          <Field
                            name='email'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='Email*'
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='email' />
                          </div>
                        </div>
                      </div>
                      <div className='fv-row mb-10'>
                        <Field
                          name='website'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Company Website*'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='website' />
                        </div>
                      </div>
                      <div className='fv-row mb-10'>
                      <div role="group" aria-labelledby="checkbox-group">
                        <h3>We are integrated with*:</h3>
                        <label>
                          <Field type="checkbox" name="integrations" value="Gorgias" />
                          <p>Gorgias</p>
                        </label>
                        <label>
                          <Field type="checkbox" name="integrations" value="Shopify" />
                          <p>Shopify</p>
                        </label>
                        <label>
                          <Field type="checkbox" name="integrations" value="Shipstation" />
                          <p>Shipstation</p>
                        </label>
                        <label>
                          <Field type="checkbox" name="integrations" value="Other" />
                          <p>Other</p>
                        </label>
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='integrations' />
                        </div>
                      </div>
                      </div>
                      {!loading &&
                        <button type='submit' className='btn btn-lg btn-primary me-3'>
                          <span className='indicator-label'>
                            <span className='indicator-label'>REQUEST AN INVITE</span>
                          </span>
                        </button>
                      }
                      {loading &&
                        <button type='submit' className='btn btn-lg btn-primary me-3' disabled>
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        </button>
                      }
                    </Form>
                  )}
                </Formik>
              </Stack>
            </Col>
            <Col className='right-col' lg={5}>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  )
}
export {InvitationPage}
