import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ThankYouPage} from '../../modules/main/ThankYouPage'

const ThankYouPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.THANKYOUPAGE'})}</PageTitle>
      <ThankYouPage />
    </>
  )
}

export {ThankYouPageWrapper}
