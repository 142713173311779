import {FC} from 'react'
import {NavigationBar} from './components/NavigationBar'
import {Row, Col, Container} from 'react-bootstrap'
import { Footer } from './components/Footer'
import { FAQFull } from '../../../_custom/partials/FAQFull'

const FAQPage: FC = () => (
  <>
    <div className='custom-page faq-page'>
      <div className='above-fold'>
        <NavigationBar />
        <Container fluid className="p-0">
          <Row>
            <Col>
              <h1>Frequently asked questions</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <FAQFull />
      <Footer />
    </div>
  </>
)

export {FAQPage}

