import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Button, Container, Navbar,NavDropdown, Nav} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'

const NavigationBar: FC = () => (
  <>
    <Navbar expand='lg' fixed='top'>
      <Container>
        <Navbar.Brand href="/">
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/default-black.png')} className='h-60px' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {/* <Nav.Link href="/what-we-do">
              What We Do
              <KTSVG
                path='/media/icons/duotune/arrows/arr072.svg'
                className='svg-icon-3 ms-2 me-0 dark-icon'
              />
            </Nav.Link> */}
            <NavDropdown title='What We Do' id='collapsible-nav-dropdown'>
              <NavDropdown.Item href='/whats-included'>What's Included</NavDropdown.Item>
              <NavDropdown.Item href='/what-we-do'>What Agents Do</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/who-we-are">
              Who We Are
              {/* <KTSVG
                path='/media/icons/duotune/arrows/arr072.svg'
                className='svg-icon-3 ms-2 me-0 dark-icon'
              /> */}
            </Nav.Link>
            <Nav.Link href="/pricing">
              Pricing
              {/* <KTSVG
                path='/media/icons/duotune/arrows/arr072.svg'
                className='svg-icon-3 ms-2 me-0 dark-icon'
              /> */}
            </Nav.Link>
            <Nav.Link href="/login">
              Login
            </Nav.Link>
            <Button href="/login/registration">GET STARTED</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>
)

export {NavigationBar}
