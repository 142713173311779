/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {Stack} from 'react-bootstrap'
import {useOnboardingDots} from '../../../../app/routing/PrivateRoutes'
import {useAuth} from '../../../../app/modules/auth'

export function AsideMenuMainAdmin() {
  return (
    <>
      <AsideMenuItem to='/admin/companies' title='Companies' icon='/media/icons/duotune/ecommerce/ecm004.svg' />
      <AsideMenuItem to='/admin/users' title='Users' icon='/media/icons/duotune/communication/com014.svg' />
    </>
  )
}
