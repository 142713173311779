import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {AdminDashboardWrapper} from '../pages/admin-dash/AdminDashboardWrapper'
import {CompanyPage} from '../pages/admin-dash/CompanyPage'
import {UserPage} from '../pages/admin-dash/UserPage'
import {UsersListPage} from '../pages/admin-dash/UsersListPage'
  
const AdminRoutes = () => {
    // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Pages */}
        <Route path='admin/companies' element={<AdminDashboardWrapper />} />
        <Route path='admin/companies/:companyID' element={<CompanyPage />} />
        <Route path='admin/users' element={<UsersListPage />} />
        <Route path='admin/users/:userID' element={<UserPage />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {AdminRoutes}
