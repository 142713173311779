import {FC, useEffect} from 'react'
import {useSearchParams} from 'react-router-dom';
import {Field, ErrorMessage} from 'formik'


const CompanyDetailsStep: FC = () => {
  const [searchParams, _] = useSearchParams()
  const gorgiasSubdomain = searchParams.get('gorgiasSubdomain');
  const gorgiasURL =  gorgiasSubdomain ? `https://${gorgiasSubdomain}.gorgias.com` : null
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark wizard-title'>Account Details</h2>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label'>Job Title</label>

        <Field name='jobTitle' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='jobTitle' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Company Name</label>

        <Field name='companyName' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyName' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>Company Website</span>
        </label>

        <Field
          name='website'
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='website' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Gorgias URL</label>

        <Field name='gorgiasURL' value={gorgiasURL}
               className='form-control form-control-lg form-control-solid placeholder-gray'
               placeholder='https://my-subdomain.gorgias.com'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='gorgiasURL' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>Phone Number</span>
        </label>

        <Field
          name='phoneNumber'
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='phoneNumber' />
        </div>
      </div>

      {/* <div className='fv-row mb-10'>
        <label className='form-label required'>Support Start</label>

        <Field
          as='select'
          name='supportStart'
          className='form-select form-select-lg form-select-solid'
        >
          <option></option>
          <option value='12:00 AM'>12:00 AM</option>
          <option value='12:30 AM'>12:30 AM</option>
          <option value='1:00 AM'>1:00 AM</option>
          <option value='1:30 AM'>1:30 AM</option>
          <option value='2:00 AM'>2:00 AM</option>
          <option value='2:30 AM'>2:30 AM</option>
          <option value='3:00 AM'>3:00 AM</option>
          <option value='3:30 AM'>3:30 AM</option>
          <option value='4:00 AM'>4:00 AM</option>
          <option value='4:30 AM'>4:30 AM</option>
          <option value='5:00 AM'>5:00 AM</option>
          <option value='5:30 AM'>5:30 AM</option>
          <option value='6:00 AM'>6:00 AM</option>
          <option value='6:30 AM'>6:30 AM</option>
          <option value='7:00 AM'>7:00 AM</option>
          <option value='7:30 AM'>7:30 AM</option>
          <option value='8:00 AM'>8:00 AM</option>
          <option value='8:30 AM'>8:30 AM</option>
          <option value='9:00 AM'>9:00 AM</option>
          <option value='9:30 AM'>9:30 AM</option>
          <option value='10:00 AM'>10:00 AM</option>
          <option value='10:30 AM'>10:30 AM</option>
          <option value='11:00 AM'>11:00 AM</option>
          <option value='11:30 AM'>11:30 AM</option>
          <option value='12:00 PM'>12:00 PM</option>
          <option value='12:30 PM'>12:30 PM</option>
          <option value='1:00 PM'>1:00 PM</option>
          <option value='1:30 PM'>1:30 PM</option>
          <option value='2:00 PM'>2:00 PM</option>
          <option value='2:30 PM'>2:30 PM</option>
          <option value='3:00 PM'>3:00 PM</option>
          <option value='3:30 PM'>3:30 PM</option>
          <option value='4:00 PM'>4:00 PM</option>
          <option value='4:30 PM'>4:30 PM</option>
          <option value='5:00 PM'>5:00 PM</option>
          <option value='5:30 PM'>5:30 PM</option>
          <option value='6:00 PM'>6:00 PM</option>
          <option value='6:30 PM'>6:30 PM</option>
          <option value='7:00 PM'>7:00 PM</option>
          <option value='7:30 PM'>7:30 PM</option>
          <option value='8:00 PM'>8:00 PM</option>
          <option value='8:30 PM'>8:30 PM</option>
          <option value='9:00 PM'>9:00 PM</option>
          <option value='9:30 PM'>9:30 PM</option>
          <option value='10:00 PM'>10:00 PM</option>
          <option value='10:30 PM'>10:30 PM</option>
          <option value='11:00 PM'>11:00 PM</option>
          <option value='11:30 PM'>11:30 PM</option>
        </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name='supportStart' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Support End</label>

        <Field
          as='select'
          name='supportEnd'
          className='form-select form-select-lg form-select-solid'
        >
          <option></option>
          <option value='12:00 AM'>12:00 AM</option>
          <option value='12:30 AM'>12:30 AM</option>
          <option value='1:00 AM'>1:00 AM</option>
          <option value='1:30 AM'>1:30 AM</option>
          <option value='2:00 AM'>2:00 AM</option>
          <option value='2:30 AM'>2:30 AM</option>
          <option value='3:00 AM'>3:00 AM</option>
          <option value='3:30 AM'>3:30 AM</option>
          <option value='4:00 AM'>4:00 AM</option>
          <option value='4:30 AM'>4:30 AM</option>
          <option value='5:00 AM'>5:00 AM</option>
          <option value='5:30 AM'>5:30 AM</option>
          <option value='6:00 AM'>6:00 AM</option>
          <option value='6:30 AM'>6:30 AM</option>
          <option value='7:00 AM'>7:00 AM</option>
          <option value='7:30 AM'>7:30 AM</option>
          <option value='8:00 AM'>8:00 AM</option>
          <option value='8:30 AM'>8:30 AM</option>
          <option value='9:00 AM'>9:00 AM</option>
          <option value='9:30 AM'>9:30 AM</option>
          <option value='10:00 AM'>10:00 AM</option>
          <option value='10:30 AM'>10:30 AM</option>
          <option value='11:00 AM'>11:00 AM</option>
          <option value='11:30 AM'>11:30 AM</option>
          <option value='12:00 PM'>12:00 PM</option>
          <option value='12:30 PM'>12:30 PM</option>
          <option value='1:00 PM'>1:00 PM</option>
          <option value='1:30 PM'>1:30 PM</option>
          <option value='2:00 PM'>2:00 PM</option>
          <option value='2:30 PM'>2:30 PM</option>
          <option value='3:00 PM'>3:00 PM</option>
          <option value='3:30 PM'>3:30 PM</option>
          <option value='4:00 PM'>4:00 PM</option>
          <option value='4:30 PM'>4:30 PM</option>
          <option value='5:00 PM'>5:00 PM</option>
          <option value='5:30 PM'>5:30 PM</option>
          <option value='6:00 PM'>6:00 PM</option>
          <option value='6:30 PM'>6:30 PM</option>
          <option value='7:00 PM'>7:00 PM</option>
          <option value='7:30 PM'>7:30 PM</option>
          <option value='8:00 PM'>8:00 PM</option>
          <option value='8:30 PM'>8:30 PM</option>
          <option value='9:00 PM'>9:00 PM</option>
          <option value='9:30 PM'>9:30 PM</option>
          <option value='10:00 PM'>10:00 PM</option>
          <option value='10:30 PM'>10:30 PM</option>
          <option value='11:00 PM'>11:00 PM</option>
          <option value='11:30 PM'>11:30 PM</option>
        </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name='supportEnd' />
        </div>
      </div> */}

      <div className='fv-row mb-10'>
        <label className='form-label required'>Business Time Zone</label>

        <Field
          as='select'
          name='timezone'
          className='form-select form-select-lg form-select-solid'
        >
          <option></option>
          <option value='PT -08:00'>PT -08:00</option>
          <option value='MT -07:00'>MT -07:00</option>
          <option value='CT -06:00'>CT -06:00</option>
          <option value='EST -05:00'>EST -05:00</option>
        </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name='timezone' />
        </div>
      </div>

      {/* <div className='fv-row mb-10'>
        <label className='form-label required'>State</label>

        <Field
          as='select'
          name='state'
          className='form-select form-select-lg form-select-solid'
        >
          <option></option>
          <option value='Alabama'>Alabama</option>
          <option value='Alaska'>Alaska</option>
          <option value='Arizona'>Arizona</option>
          <option value='Arkansas'>Arkansas</option>
          <option value='California'>California</option>
          <option value='Colorado'>Colorado</option>
          <option value='Connecticut'>Connecticut</option>
          <option value='Delaware'>Delaware</option>
          <option value='Flordia'>Flordia</option>
          <option value='Georgia'>Georgia</option>
          <option value='Hawaii'>Hawaii</option>
          <option value='Idaho'>Idaho</option>
          <option value='Illinois'>Illinois</option>
          <option value='Indiana'>Indiana</option>
          <option value='Iowa'>Iowa</option>
          <option value='Kansas'>Kansas</option>
          <option value='Kentucky'>Kentucky</option>
          <option value='Louisiana'>Louisiana</option>
          <option value='Maine'>Maine</option>
          <option value='Maryland'>Maryland</option>
          <option value='Massachusetts'>Massachusetts</option>
          <option value='Michigan'>Michigan</option>
          <option value='Minnesota'>Minnesota</option>
          <option value='Mississippi'>Mississippi</option>
          <option value='Missouri'>Missouri</option>
          <option value='Montana'>Montana</option>
          <option value='Nebraska'>Nebraska</option>
          <option value='Nevada'>Nevada</option>
          <option value='New Hampshire'>New Hampshire</option>
          <option value='New Jersey'>New Jersey</option>
          <option value='New Mexico'>New Mexico</option>
          <option value='New York'>New York</option>
          <option value='North Carolina'>North Carolina</option>
          <option value='North Dakota'>North Dakota</option>
          <option value='Ohio'>Ohio</option>
          <option value='Oklahoma'>Oklahoma</option>
          <option value='Oregon'>Oregon</option>
          <option value='Pennsylvania'>Pennsylvania</option>
          <option value='Rhode Island'>Rhode Island</option>
          <option value='South Carolina'>South Carolina</option>
          <option value='South Dakota'>South Dakota</option>
          <option value='Tennessee'>Tennessee</option>
          <option value='Texas'>Texas</option>
          <option value='Utah'>Utah</option>
          <option value='Vermont'>Vermont</option>
          <option value='Virginia'>Virginia</option>
          <option value='Washington'>Washington</option>
          <option value='West Virginia'>West Virginia</option>
          <option value='Wisconsin'>Wisconsin</option>
          <option value='Wyoming'>Wyoming</option>
        </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name='state' />
        </div>
      </div> */}

      {/* <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>Zip Code</span>
        </label>

        <Field
          name='zipCode'
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='zipCode' />
        </div>
      </div> */}

    </div>
  )
}

export {CompanyDetailsStep}
