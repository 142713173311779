import {FC} from 'react'
import {NavigationBar} from './components/NavigationBar'
import {Row, Col, Button, Container, Stack} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { Footer } from './components/Footer'
import { FAQMini } from '../../../_custom/partials/FAQMini'

const PricingPage: FC = () => (
  <>
    <div className='custom-page pricing-page'>
      <div className='above-fold'>
        <NavigationBar />
        <Container fluid className="p-0">
          <Row>
            <Col>
              <h1>Plans that fit your scale</h1>
              <Stack className='card-stack' direction='horizontal' gap={5}>
                <div className='pricing-card'>
                  <div className='card-header'>
                    <h2>THE ESSENTIALS</h2>
                  </div>
                  <div className='card-body'>
                    <h3>
                      Self-serve Customer Service like Amazon, making sure to field 
                      the basic questions and bring key issues to your attention
                    </h3>
                    <p className='starting-at'>Starting At</p>
                    <Stack className='pricing-stack' direction='horizontal' gap={4}>
                      <p className='original-price'>$70</p>
                      <p className='new-price'>$55</p>
                      <p className='payment-schedule'>/month</p>
                    </Stack>
                    <div className='item-list mt-5'>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p className='fw-bold'>Self Serve Customer Service</p>
                      </Stack>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p>Help Center Content Creation</p>
                      </Stack>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p>Help Center Platform Setup</p>
                      </Stack>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p>Self Serve Chat Widget Setup</p>
                      </Stack>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p>Core Integrations: Shopify, Email, Instagram, Facebook, agent satisfaction rate</p>
                      </Stack>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p>$200 Gorgias Setup Fee</p>
                      </Stack>
                    </div>
                    <Button href='/login/registration'>GET STARTED</Button>
                  </div>
                </div>
                <div className='pricing-card center-card'>
                  <div className='corner-ribbon'>
                    <p>MOST POPULAR</p>
                  </div>
                 <div className='card-header'>
                    <h2>THE COMPLETE PACKAGE</h2>
                  </div>
                  <div className='card-body'>
                    <h3>Agent led customer service that allows for a flexible and responsive user experience</h3>
                    <p className='starting-at'>Starting At</p>
                    <Stack className='pricing-stack' direction='horizontal' gap={4}>
                      <p className='original-price'>$625</p>
                      <p className='new-price'>$500</p>
                      <p className='payment-schedule'>/month</p>
                    </Stack>
                    <div className='item-list mt-5'>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p className='fw-bold'>Agent Led Customer Service</p>
                      </Stack>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p>Everything In The Essentials +</p>
                      </Stack>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p>Standard Response Times: Under 6 Hours</p>
                      </Stack>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p>Agents Staffed Weekdays M-F / 9:00-6:00pm</p>
                      </Stack>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p>$200 Gorgias Setup Fee</p>
                      </Stack>
                    </div>
                    <Button href='/login/registration'>GET STARTED</Button>
                  </div>
                </div>
                <div className='pricing-card'>
                 <div className='card-header'>
                    <h2>ALL ELITE EVERYTHING</h2>
                  </div>
                  <div className='card-body'>
                    <h3>
                      Fully serviced customer service for high growth teams, allowing for 
                      fully customizable Gorgias and customer service operations
                    </h3>
                    <p className='starting-at'>Starting At</p>
                    <Stack className='pricing-stack' direction='horizontal' gap={4}>
                      <p className='original-price'>$1,875</p>
                      <p className='new-price'>$1,500</p>
                      <p className='payment-schedule'>/month</p>
                    </Stack>
                    <div className='item-list mt-5'>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p className='fw-bold'>Managed Customer Service</p>
                      </Stack>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p>Everything In The Complete Package +</p>
                      </Stack>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p>Standard Response Times: Under 2 Hours</p>
                      </Stack>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p>Agents Staffed Daily / 24 Hour Support</p>
                      </Stack>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p>Direct Communication With Shipping & Ecom Teams</p>
                      </Stack>
                      <Stack className='list-item' direction='horizontal' gap={4}>
                        <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                        <p>$200 Gorgias Setup Fee + Setup Fees For Optional Integrations</p>
                      </Stack>
                    </div>
                    <Button href='/login/registration'>GET STARTED</Button>
                  </div>
                </div>
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='full-width-card mw-75 mx-auto mt-15'>
                <h3 className='mb-7'>What's Included in the $200 Gorgias Setup Fee?</h3>
                <Row>
                  <Col>
                    <Stack className='list-item' direction='horizontal' gap={4}>
                      <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                      <p>Customer Service Process Audit</p>
                    </Stack>
                    <Stack className='list-item' direction='horizontal' gap={4}>
                      <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                      <p>Gorgias Dashboard Setup - Views, Macros, Rules</p>
                    </Stack>
                  </Col>
                  <Col>
                    <Stack className='list-item' direction='horizontal' gap={4}>
                      <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                      <p>AI supported Customized Responses</p>
                    </Stack>
                    <Stack className='list-item' direction='horizontal' gap={4}>
                      <img alt='Check Icon' className='check-icon' src={toAbsoluteUrl('/media/icons/custom/green-circle-check.png')} />
                      <p>Core Integrations Setup</p>
                    </Stack>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='cs-capabilities-section'>
        <Container fluid className="p-0">
          <h2 className='mb-15'>Ticket Pricing That Scales</h2>
          <Row>
            <Col>
              <table className='table table-bordered table-desktop align-middle fw-semibold'>
                <tbody>
                  <tr>
                    <th className='text-white ps-3 first-th fw-bold'>Monthly Ticket Count</th>
                    <th className='text-white pe-0 fw-bold'>0 - 40</th>
                    <th className='text-white pe-0 fw-bold'>41 - 75</th>
                    <th className='text-white pe-0 fw-bold'>76 - 300</th>
                    <th className='text-white pe-0 fw-bold'>301 - 500</th>
                    <th className='text-white pe-0 fw-bold'>501 - 1000</th>
                    <th className='text-white pe-0 last-th fw-bold'>1001+</th>
                  </tr>
                  <tr>
                    <td className='text-dark ps-3 fw-bold'>The Essentials Plan</td>
                    <td className='text-dark pe-0'>Included</td>
                    <td className='text-dark pe-0'>$1.25/ticket</td>
                    <td className='text-dark pe-0'>$1.20/ticket</td>
                    <td className='text-dark pe-0'>$1.15/ticket</td>
                    <td className='text-dark pe-0'>$1.10/ticket</td>
                    <td className='text-dark pe-0'>$1.05/ticket</td>
                  </tr>
                  <tr>
                    <td className='text-dark ps-3 fw-bold'>The Complete Package Plan</td>
                    <td className='text-dark pe-0'>Included</td>
                    <td className='text-dark pe-0'>Included</td>
                    <td className='text-dark pe-0'>Included</td>
                    <td className='text-dark pe-0'>$1.25/ticket</td>
                    <td className='text-dark pe-0'>$1.15/ticket</td>
                    <td className='text-dark pe-0'>$1.10/ticket</td>
                  </tr>
                  <tr>
                    <td className='text-dark ps-3 fw-bold'>All Elite Everything Plan</td>
                    <td className='text-dark pe-0'>Included</td>
                    <td className='text-dark pe-0'>Included</td>
                    <td className='text-dark pe-0'>Included</td>
                    <td className='text-dark pe-0'>$1.65/ticket</td>
                    <td className='text-dark pe-0'>$1.55/ticket</td>
                    <td className='text-dark pe-0'>$1.50/ticket</td>
                  </tr>
                </tbody>
              </table>
              <table className='table table-bordered table-mobile align-middle fw-semibold'>
                <tbody>
                  <tr>
                    <th className='text-white ps-3 pe-3 first-th fw-bold'>Monthly Ticket Count</th>
                    <th className='text-white ps-3 pe-3 fw-bold'>The Essentials Plan</th>
                    <th className='text-white ps-3 pe-3 fw-bold'>The Complete Package Plan</th>
                    <th className='text-white ps-3 pe-3 fw-bold last-th'>All Elite Everything Plan</th>
                  </tr>
                  <tr>
                    <td className='text-dark ps-3 fw-bold'>0 - 40</td>
                    <td className='text-dark pe-0'>Included</td>
                    <td className='text-dark pe-0'>Included</td>
                    <td className='text-dark pe-0'>Included</td>
                  </tr>
                  <tr>
                    <td className='text-dark ps-3 fw-bold'>41 - 75</td>
                    <td className='text-dark pe-0'>$1.25/ticket</td>
                    <td className='text-dark pe-0'>Included</td>
                    <td className='text-dark pe-0'>Included</td>
                  </tr>
                  <tr>
                    <td className='text-dark ps-3 fw-bold'>76 - 300</td>
                    <td className='text-dark pe-0'>$1.20/ticket</td>
                    <td className='text-dark pe-0'>Included</td>
                    <td className='text-dark pe-0'>Included</td>
                  </tr>
                  <tr>
                    <td className='text-dark ps-3 fw-bold'>301 - 500</td>
                    <td className='text-dark pe-0'>$1.15/ticket</td>
                    <td className='text-dark pe-0'>$1.25/ticket</td>
                    <td className='text-dark pe-0'>$1.65/ticket</td>
                  </tr>
                  <tr>
                    <td className='text-dark ps-3 fw-bold'>501 - 1000</td>
                    <td className='text-dark pe-0'>$1.10/ticket</td>
                    <td className='text-dark pe-0'>$1.15/ticket</td>
                    <td className='text-dark pe-0'>$1.55/ticket</td>
                  </tr>
                  <tr>
                    <td className='text-dark ps-3 last-th fw-bold'>1001+</td>
                    <td className='text-dark pe-0'>$1.05/ticket</td>
                    <td className='text-dark pe-0'>$1.10/ticket</td>
                    <td className='text-dark pe-0'>$1.50/ticket</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <h2 className='mt-15'>Customer Service Capabilities</h2>
          <Row className='top-row'>
            <Col lg={12} xl={4}>
              <Stack direction='horizontal' gap={4}>
                <img alt='Setup Icon' className='icon' src={toAbsoluteUrl('/media/icons/custom/setup-boxed.png')} />
                <Stack className='inner-stack'>
                  <h3>Gorgias Setup</h3>
                  <p>Streamlined dashboard, macros, & triggers to ensure best practices</p>
                </Stack>
              </Stack>
            </Col>
            <Col lg={12} xl={4}>
              <Stack direction='horizontal' gap={4}>
                <img alt='Setup Icon' className='icon' src={toAbsoluteUrl('/media/icons/custom/ship-boxed.png')} />
                <Stack className='inner-stack'>
                  <h3>Shipment inquiries</h3>
                  <p>Tracking, claims, international shipments, returns</p>
                </Stack>
              </Stack>
            </Col>
            <Col lg={12} xl={4}>
              <Stack direction='horizontal' gap={4}>
                <img alt='Setup Icon' className='icon' src={toAbsoluteUrl('/media/icons/custom/customers-boxed.png')} />
                <Stack className='inner-stack'>
                  <h3>Customer Experience</h3>
                  <p>Announcements, website issues, FAQ Updates, customer loyalty</p>
                </Stack>
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={4}>
              <Stack direction='horizontal' gap={4}>
                <img alt='Setup Icon' className='icon' src={toAbsoluteUrl('/media/icons/custom/channels-boxed.png')} />
                <Stack className='inner-stack'>
                  <h3>Channel Integrations</h3>
                  <p>Email, Facebook, Instagram, Twitter, Live chat, SMS</p>
                </Stack>
              </Stack>
            </Col>
            <Col lg={12} xl={4}>
              <Stack direction='horizontal' gap={4}>
                <img alt='Setup Icon' className='icon' src={toAbsoluteUrl('/media/icons/custom/info-boxed.png')} />
                <Stack className='inner-stack'>
                  <h3>Billing Inquiries</h3>
                  <p>Chargeback and refund management</p>
                </Stack>
              </Stack>
            </Col>
            <Col lg={12} xl={4}>
              <Stack direction='horizontal' gap={4}>
                <img alt='Setup Icon' className='icon' src={toAbsoluteUrl('/media/icons/custom/invoice-boxed.png')} />
                <Stack className='inner-stack'>
                  <h3>Order management</h3>
                  <p>Consolidation, address change, exchanges, return labels</p>
                </Stack>
              </Stack>
            </Col>
          </Row>
        </Container>
      </div>
      <FAQMini />
      {/* <div className='additional-services-section'>
        <Container fluid className="p-0">
          <h2>Additional Services</h2>
            <div className='service-list'>
              <Row className='service-item'>
                <Col className='col-1' xs={12} md={6} lg={4}>
                  <Stack className='icon-text-stack' direction='horizontal' gap={4}>
                    <img alt='Calendar Icon' src={toAbsoluteUrl('/media/icons/custom/calendar-icon.png')} />
                    <h3>24/7 Support</h3>
                  </Stack>
                  <p>from $199/month</p>
                </Col>
                <Col className='col-2' xs={12} md={6} lg={6}>
                  <h3>Provide 24/7 customer support</h3>
                  <ul>
                    <li>
                      Customer support agents available for extended support
                    </li>
                    <li>
                      Holidays and weekends covered
                    </li>
                  </ul>
                </Col>
                <Col className='col-3' xs={12} md={12} lg={2}>
                  <Button href='/invitation'>Add to trial</Button>
                </Col>
              </Row>
              <Row className='service-item'>
                <Col className='col-1' xs={12} md={6} lg={4}>
                  <Stack className='icon-text-stack' direction='horizontal' gap={4}>
                    <img alt='Money Cart Icon' src={toAbsoluteUrl('/media/icons/custom/money-cart-icon.png')} />
                    <h3>Ecom Services</h3>
                  </Stack>
                  <p>from $15/hour</p>
                </Col>
                <Col className='col-2' xs={12} md={6} lg={6}>
                  <h3>Additional requests to support CS operations</h3>
                  <ul>
                    <li>
                      Agents provide additional support aside from ticket responses
                    </li>
                    <li>
                      Chargebacks, refund processing, data entry, and more
                    </li>
                  </ul>
                </Col>
                <Col className='col-3' xs={12} md={12} lg={2}>
                  <Button href='/invitation'>Add to trial</Button>
                </Col>
              </Row>
              <Row className='service-item'>
                <Col className='col-1' xs={12} md={6} lg={4}>
                  <Stack className='icon-text-stack' direction='horizontal' gap={4}>
                    <img alt='Check Doc Icon' src={toAbsoluteUrl('/media/icons/custom/check-doc-icon.png')} />
                    <h3>Additional Tickets</h3>
                  </Stack>
                  <p>$25 for every 100</p>
                </Col>
                <Col className='col-2' xs={12} md={6} lg={6}>
                  <h3>Flat fee for ticket overages</h3>
                  <ul>
                    <li>
                      Easy billing to support growing user trends
                    </li>
                    <li>
                      On-demand scaling peak season
                    </li>
                  </ul>
                </Col>
                <Col className='col-3' xs={12} md={12} lg={2}>
                <Button href='/invitation'>Add to trial</Button>
                </Col>
              </Row>
              <Row className='service-item'>
                <Col className='col-1' xs={12} md={6} lg={4}>
                  <Stack className='icon-text-stack' direction='horizontal' gap={4}>
                    <img alt='Chat Bubbles Icon' src={toAbsoluteUrl('/media/icons/custom/chat-bubbles-icon.png')} />
                    <h3>Live Chat Support</h3>
                  </Stack>
                  <p>from $250/month</p>
                </Col>
                <Col className='col-2' xs={12} md={6} lg={6}>
                  <h3>Chat support powered by Dedication Agents</h3>
                  <ul>
                    <li>
                      Direct customer response for immediate support
                    </li>
                    <li>
                      On-demand scaling for peak season
                    </li>
                  </ul>
                </Col>
                <Col className='col-3' xs={12} md={12} lg={2}>
                  <Button href='/invitation'>Add to trial</Button>
                </Col>
              </Row>
            </div>
        </Container>
      </div> */}
      {/* <div className='compare-features-section'>
        <Container>
          <h2>Compare all features</h2>
          <Row>
            <Col lg={4}>
              // TODO: Pull these cards out into a separate component
              <div className='detail-card'>
                <h3>THE ESSENTIALS</h3>
                <h4>Customer Responses</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Up to 50</p>
                </Stack>
                <h4>Gorgias Setup</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Dashboard Views Setup</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Macros & Rules Setup</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Tagging Structures</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>FAQ/Terms & Services Review</p>
                </Stack>
                <h4>Communication Channels Integrations</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Email</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Facebook</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Instagram</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle X Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-x-icon.png')} />
                  <p className='light-text'>Live chat</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle X Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-x-icon.png')} />
                  <p className='light-text'>SMS</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle X Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-x-icon.png')} />
                  <p className='light-text'>Call (Coming 2023)</p>
                </Stack>
                <h4>Shipment Inquiries</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Courier Tracking Update</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Courier Claims</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>International Shipment Restrictions</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Bounce Back Returns</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Idle Shipments</p>
                </Stack>
                <h4>General Customer Service</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Fulfillment Announcements</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Website issues/Reporting bugs</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>FAQ Policy Updates</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Customer Experience Surveys</p>
                </Stack>
                <h4>Chargebacks & Refunds</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle X Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-x-icon.png')} />
                  <p className='light-text'>Appeasements/Discount Codes</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle X Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-x-icon.png')} />
                  <p className='light-text'>Refund Requests</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle X Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-x-icon.png')} />
                  <p className='light-text'>Chargeback monitoring & reporting</p>
                </Stack>
              </div>
            </Col>
            <Col lg={4}>
              <div className='detail-card'>
                <h3>THE COMPLETE PACKAGE</h3>
                <h4>Customer Responses</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Up to 300</p>
                </Stack>
                <h4>Gorgias Setup</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Dashboard Views Setup</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Macros & Rules Setup</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Tagging Structures</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>FAQ/Terms & Services Review</p>
                </Stack>
                <h4>Communication Channels Integrations</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Email</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Facebook</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Instagram</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle X Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-x-icon.png')} />
                  <p className='light-text'>Live chat</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle X Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-x-icon.png')} />
                  <p className='light-text'>SMS</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle X Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-x-icon.png')} />
                  <p className='light-text'>Call (Coming 2023)</p>
                </Stack>
                <h4>Shipment Inquiries</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Courier Tracking Update</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Courier Claims</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>International Shipment Restrictions</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Bounce Back Returns</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Idle Shipments</p>
                </Stack>
                <h4>General Customer Service</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Fulfillment Announcements</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Website issues/Reporting bugs</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>FAQ Policy Updates</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Customer Experience Surveys</p>
                </Stack>
                <h4>Chargebacks & Refunds</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle X Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-x-icon.png')} />
                  <p className='light-text'>Appeasements/Discount Codes</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle X Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-x-icon.png')} />
                  <p className='light-text'>Refund Requests</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle X Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-x-icon.png')} />
                  <p className='light-text'>Chargeback monitoring & reporting</p>
                </Stack>
              </div>
            </Col>
            <Col lg={4}>
              <div className='detail-card'>
                <h3>ALL ELITE EVERYTHING</h3>
                <h4>Customer Responses</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Up to 2000</p>
                </Stack>
                <h4>Gorgias Setup</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Dashboard Views Setup</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Macros & Rules Setup</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Tagging Structures</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>FAQ/Terms & Services Review</p>
                </Stack>
                <h4>Communication Channels Integrations</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Email</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Facebook</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Instagram</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Circle Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Live chat</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Circle Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>SMS</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Circle Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Call (Coming 2023)</p>
                </Stack>
                <h4>Shipment Inquiries</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Courier Tracking Update</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Courier Claims</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>International Shipment Restrictions</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Bounce Back Returns</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Idle Shipments</p>
                </Stack>
                <h4>General Customer Service</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Fulfillment Announcements</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Website issues/Reporting bugs</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>FAQ Policy Updates</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Customer Experience Surveys</p>
                </Stack>
                <h4>Chargebacks & Refunds</h4>
                <Stack className='dbl-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Appeasements/Discount Codes</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Refund Requests</p>
                </Stack>
                <Stack className='bottom-dashed-stack' direction='horizontal' gap={4}>
                  <img alt='Circle Check Icon' className='circle-check-icon' src={toAbsoluteUrl('/media/icons/custom/gray-circle-check-icon.png')} />
                  <p>Chargeback monitoring & reporting</p>
                </Stack>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      <Footer />
    </div>
  </>
)

export {PricingPage}
