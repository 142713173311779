export interface IProfileDetails {
  fName: string
  lName: string
  position: string
}

export interface ThirdPartyIntegrationsInterface {
  // helps serialization from snake case to camel case
  gorgias_url?: string
  shopify_url?: string
  shipstation_url?: string
  ai_pre_launch_limiter?: number
}

export class ThirdPartyIntegrationsModel {
  gorgiasURL?: string
  shopifyURL?: string
  shipstationURL?: string
  aiPreLaunchLimiter?: number

  constructor(integrations: ThirdPartyIntegrationsInterface) {
    this.gorgiasURL = integrations.gorgias_url
    this.shopifyURL = integrations.shopify_url
    this.shipstationURL = integrations.shipstation_url
    this.aiPreLaunchLimiter = integrations.ai_pre_launch_limiter
  }
}

export interface IPlan {
  name: string
  price: number
  schedule: string
  stripe_id?: string
}

export interface CompanyDetailsInterface {
  // helps serialization from snake case to camel case
  id?: number
  name: string
  phone_number?: string
  website: string
  timezone: string
  twitter_url?: string
  instagram_url?: string
  facebook_url?: string
  faq_url?: string
  blog_url?: string
  newsletter_signup_url?: string
  plan?: IPlan
  stripe_customer_id?: string
  stripe_subscription_id?: string
  third_party_integrations?: ThirdPartyIntegrationsInterface
}

export class CompanyDetailsModel {
  id?: number
  name: string
  phoneNumber?: string
  website: string
  timezone: string
  twitterURL?: string
  instagramURL?: string
  facebookURL?: string
  faqURL?: string
  blogURL?: string
  newsletterSignupURL?: string
  plan?: IPlan
  stripeCustomerID?: string
  stripeSubscriptionID?: string
  thirdPartyIntegrations?: ThirdPartyIntegrationsModel

  constructor(company: CompanyDetailsInterface) {
    this.id = company.id
    this.name = company.name
    this.phoneNumber = company.phone_number
    this.website = company.website
    this.timezone = company.timezone
    this.twitterURL = company.twitter_url
    this.instagramURL = company.instagram_url
    this.facebookURL = company.facebook_url
    this.faqURL = company.faq_url
    this.blogURL = company.blog_url
    this.newsletterSignupURL = company.newsletter_signup_url
    this.plan = company.plan
    this.stripeCustomerID = company.stripe_customer_id
    this.stripeSubscriptionID = company.stripe_subscription_id
    this.thirdPartyIntegrations = (
      company.third_party_integrations ?
      new ThirdPartyIntegrationsModel(company.third_party_integrations) :
      undefined
    )
  }
}

export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  google: boolean
  github: boolean
  stack: boolean
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notifications: {
    email: boolean
    phone: boolean
  }
  billingUpdates: {
    email: boolean
    phone: boolean
  }
  newTeamMembers: {
    email: boolean
    phone: boolean
  }
  completeProjects: {
    email: boolean
    phone: boolean
  }
  newsletters: {
    email: boolean
    phone: boolean
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}

export const updateEmail: IUpdateEmail = {
  newEmail: 'support@keenthemes.com',
  confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}
