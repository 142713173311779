import {
  createContext, Dispatch, FC, SetStateAction,
  useEffect, useContext, useState
} from 'react'
import {Row, Col, Container, Stack} from 'react-bootstrap'
import {useFormik, FormikState} from 'formik'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../_metronic/helpers';
import {
  getTeamMembers, sendTeamMemberInvitation, sendOrCancelTeamMemberInvitation
} from './_requests';
import { UserModel, useAuth } from '../../modules/auth';

export interface IInviteUser {
  firstName: string
  lastName: string
  email: string
}
const initialValues: IInviteUser = {
  firstName: '',
  lastName: '',
  email: '',
}

const sendInvitationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
            .required('Email is required')
            .email('Wrong email format'),
})

type TeamMemberListContextProps = {
  teamMembers: Array<UserModel> | []
  setTeamMembers: Dispatch<SetStateAction<Array<UserModel> | []>>
  memberErrorMessage: string | undefined
  setMemberErrorMessage: Dispatch<SetStateAction<string | undefined>>
}

const initTeamMemberListPropsState = {
  teamMembers: [],
  setTeamMembers: () => {},
  memberErrorMessage: '',
  setMemberErrorMessage: () => {},
}

const TeamMemberListContext = createContext<TeamMemberListContextProps>(initTeamMemberListPropsState)

function useTeamMemberList() {
  return useContext(TeamMemberListContext)
}

const TeamPage: FC = () => {
  useEffect(() => {
    const getCompanyTeamMembers = async () => {
      getTeamMembers().then((response) => {
        const {team_members: members} = response.data;
        // TODO: pull this out so it's reusable
        let teamMembers: Array<UserModel> = [];
        for (let i=0; i<members.length; i++) {
          teamMembers.push(new UserModel(members[i]));
        }
        setTeamMembers(teamMembers);
      })
    };
    getCompanyTeamMembers().catch((error) => {
      setErrorMessage('ERROR: ' + error);
    });
  }, [])
  const {currentUser} = useAuth();
  // TODO: i think it requires this because UserModel is wrong -> need to update
  const user = JSON.parse(JSON.stringify(currentUser)).user;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [memberErrorMessage, setMemberErrorMessage] = useState<string | undefined>(undefined)
  const [teamMembers, setTeamMembers] = useState<Array<UserModel>>([])
  const [inviteSent, setInviteSent] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [editable, setEditable] = useState<boolean>(true)
  const formik = useFormik<IInviteUser>({
    initialValues,
    validationSchema: sendInvitationSchema,
    onSubmit: async (values, {resetForm}) => {
      setErrorMessage(undefined)
      if (editable) {
        setSubmitting(true)
        sendTeamMemberInvitation(values).then((response) => {
          const {team_members: members} = response.data;
          // TODO: pull this out so it's reusable
          let teamMembers: Array<UserModel> = [];
          for (let i=0; i<members.length; i++) {
            teamMembers.push(new UserModel(members[i]));
          }
          setTeamMembers(teamMembers);
          setInviteSent(true);
          setEditable(false);
          resetForm(initialValues as Partial<FormikState<IInviteUser>>);
          setTimeout(()=> {
            setInviteSent(false);
            setEditable(true);
          }, 5000)
        }).catch((error) => {
          setErrorMessage("Error: " + error)
        });
        setSubmitting(false)
      } else {
        setEditable(true)
      }
    },
  })
  
  return (
    <div className='team-page'>
      <Container fluid className='p-0'>
        <h1>Team Settings</h1>
        { user.is_owner &&
          <Row className='invitation-section'>
            <Col>
              <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <Row className='invitation-row'>
                  <Col lg={3} md={6}>
                    <input
                      type='text'
                      className='form-control form-control-lg name-input '
                      placeholder='First Name'
                      {...formik.getFieldProps('firstName')}
                    />
                  </Col>
                  <Col lg={3} md={6}>
                    <input
                      type='text'
                      className='form-control form-control-lg name-input '
                      placeholder='Last Name'
                      {...formik.getFieldProps('lastName')}
                    />
                  </Col>
                  <Col lg={4} md={12}>
                    <div className='email-input-wrapper'>
                      <input
                        type='email'
                        className='email-input form-control form-control-lg'
                        placeholder='Email'
                        {...formik.getFieldProps('email')}
                      />
                    </div>
                  </Col>
                  <Col lg={2} md={12}>
                    <button
                      disabled={!editable}
                      type='submit'
                      className='btn btn-lg btn-primary mt-0'
                    >
                      <span className='indicator-label'>
                        { !submitting && !inviteSent &&
                          <span className='indicator-label'>Invite</span>
                        }
                        { inviteSent &&
                          <span className='indicator-label'>Sent!</span>
                        }
                        { submitting &&
                          <img alt='Loading' className='loading-icon' src={toAbsoluteUrl(`/media/gifs/loading.gif`)} />
                        }
                      </span>
                    </button>
                  </Col>
                </Row>
              </form>
              <div className='error-message mt-4'>
                {errorMessage}
              </div>
            </Col>
          </Row>
        }
        <Row className='member-list-section'>
          <Col>
            <Stack direction='horizontal' gap={5}>
              <h2>Team Members</h2>
              <div className='error-message mt-4'>
                {memberErrorMessage}
              </div>
            </Stack>
            {teamMembers.map((member, index) => {
              return (
                <TeamMemberListContext.Provider value={{
                  teamMembers, setTeamMembers, memberErrorMessage, setMemberErrorMessage
                }} key={index}>
                  <TeamMemberItem user={user} member={member} index={index}/>
                </TeamMemberListContext.Provider>
              )
            })}
            
          </Col>
        </Row>
      </Container>
    </div>
  )
}

interface ITeamMemberItemProps{
  index: number
  user: UserModel
  member: UserModel
}

const TeamMemberItem: FC<ITeamMemberItemProps> = (props) => {
  const member = props.member;
  const user = props.user;
  const [submittingCancel, setSubmittingCancel] = useState(false)
  const [editableCancel, setEditableCancel] = useState(true)
  const [submittingResend, setSubmittingResend] = useState(false)
  const [inviteResent, setInviteResent] = useState(false)
  const [editableResend, setEditableResend] = useState(true)
  const {setTeamMembers, setMemberErrorMessage} = useTeamMemberList();
  const memberActive = member.isOwner || member.invitation?.status === 'accepted';
  const sendOrCancel = async (cancel: boolean) => {
    setEditableCancel(false);
    setEditableResend(false);
    if (cancel) {
      setSubmittingCancel(true);
    } else {
      setSubmittingResend(true);
    }
    sendOrCancelTeamMemberInvitation(member.invitation.id, cancel).then((response) => {
      const {team_members: teamMembers} = response.data;
      setTeamMembers(teamMembers);
      setInviteResent(true);
      setTimeout(()=> {
        setInviteResent(false);
        setEditableResend(true);
      }, 5000)
    }).catch((error) => {
      setMemberErrorMessage("Error: " + error);
    }).finally(() => {
      setEditableCancel(true);
      if (cancel) {
        setSubmittingCancel(false);
      } else {
        setSubmittingResend(false);
      }
    });
  }
  return (
    <div className='member-item'>
      <Row className='member-item-row'>
        <Col lg={2} md={4}>
          <p className='member-name'>
            {member.firstName + ' ' + member.lastName}
          </p>
        </Col>
        <Col lg={1} md={4}>
          <p className={`member-owner member-owner-${member.isOwner ? 'owner' : 'member'}`}>{member.isOwner ? 'Owner' : 'Member'}</p>
        </Col>
        <Col lg={2} md={4}>
          {/* Shorten email to 15 char max */}
          <p className='member-email'>{member.email.length > 15 && user.isOwner ? member.email.slice(0,15) + '...' : member.email}</p>
        </Col>
        <Col lg={1} md={4}>
          <div className={`member-status member-status-${memberActive ? 'active' : member.invitation.status}`}>{memberActive ? 'Active' : member.invitation.status}</div>
        </Col>
        { user.isOwner &&
          <Col lg={4} md={4}>
            <Stack className='button-stack' direction='horizontal' gap={5}>
              { user.isOwner && !member.isOwner && member.invitation?.status === 'pending' &&
                <button disabled={!editableCancel} className='btn btn-color-gray-600 fw-bolder px-6 py-3' onClick={() => sendOrCancel(true)}>
                  <span className='indicator-label'>
                    { !submittingCancel &&
                      <span className='indicator-label'>Cancel</span>
                    }
                    { submittingCancel &&
                      <img alt='Loading' className='loading-icon' src={toAbsoluteUrl(`/media/gifs/loading.gif`)} />
                    }
                  </span>
                </button>
              }
              { user.isOwner && !member.isOwner && member.invitation?.status === 'pending' &&
                <button disabled={!editableResend} className='btn btn-lg btn-primary' onClick={() => sendOrCancel(false)}>
                  <span className='indicator-label'>
                    { !submittingResend && !inviteResent &&
                      <span className='indicator-label'>Resend</span>
                    }
                    { inviteResent &&
                      <span className='indicator-label'>Resent!</span>
                    }
                    { submittingResend &&
                      <img alt='Loading' className='loading-icon' src={toAbsoluteUrl(`/media/gifs/loading.gif`)} />
                    }
                  </span>
                </button>
              }
            </Stack>
          </Col>
        }
      </Row>
    </div>
  )
}

export {TeamPage}
