/* eslint-disable react/jsx-no-target-blank */
import {useOnboardingDots} from '../../../../app/routing/PrivateRoutes'
import {useAuth} from '../../../../app/modules/auth'
import {AsideMenuMainCompany} from './AsideMenuMainCompany'
import {AsideMenuMainAdmin} from './AsideMenuMainAdmin'

// import {useIntercom} from 'react-use-intercom';

export function AsideMenuMain() {
  // const { boot, show } = useIntercom();
  const {currentUser} = useAuth()
  let user = undefined;
  if (currentUser) {
    // So ugly... but not sure how else to go about it
    // https://stackoverflow.com/questions/17546953/cant-access-object-property-even-though-it-shows-up-in-a-console-log
    user = JSON.parse(JSON.stringify(currentUser)).user;
  }
  return (
    <>
      { user?.is_agent ? (
        <AsideMenuMainAdmin />
      ) : (
        <AsideMenuMainCompany />
      )}
    </>
  )
}
