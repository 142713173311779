import {
  FC, useEffect, useRef, useState, createContext,
  useContext, Dispatch, SetStateAction} from 'react'
import {CompanyDetailsStep} from './steps/CompanyDetailsStep'
import {Step5} from './steps/Step5'
import {KTSVG} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {createCompanySchemas, ICreateAccount, inits} from './CreateCompanyWizardHelper'
import {PlanSelectStep} from './steps/PlanSelectStep'
import {BillingStep} from './steps/BillingStep'
import {createStripeSubscription, finalizeStripeInvoice} from '../../billing/_requests'
import {getUserByToken} from '../../auth/core/_requests'
import {useAuth} from '../../auth/core/Auth'

import {Stripe, StripeElements} from '@stripe/stripe-js'

type PlanIDContextProps = {
  planID: string | undefined
  setPlanID: Dispatch<SetStateAction<string | undefined>>
}
type StripeContextProps = {
  clientSecret: string | undefined
  setClientSecret: Dispatch<SetStateAction<string | undefined>>
  lineItems: Array<LineItem> | undefined
  setLineItems: Dispatch<SetStateAction<Array<LineItem> | undefined>>
  chargeSubtotal: number | undefined
  setChargeSubtotal: Dispatch<SetStateAction<number | undefined>>
  chargeDiscount: number | undefined
  setChargeDiscount: Dispatch<SetStateAction<number | undefined>>
  chargeTax: number | undefined
  setChargeTax: Dispatch<SetStateAction<number | undefined>>
  chargeTotal: number | undefined
  setChargeTotal: Dispatch<SetStateAction<number | undefined>>
  stripe: Stripe | undefined
  setStripe: Dispatch<SetStateAction<Stripe | undefined>>
  elements: StripeElements | undefined
  setElements: Dispatch<SetStateAction<StripeElements | undefined>>
}
const initPlanIDPropsState = {
  planID: undefined,
  setPlanID: () => {},
}
const initStripePropsState = {
  clientSecret: undefined,
  setClientSecret: () => {},
  lineItems: undefined,
  setLineItems: () => {},
  chargeSubtotal: undefined,
  setChargeSubtotal: () => {},
  chargeDiscount: undefined,
  setChargeDiscount: () => {},
  chargeTax: undefined,
  setChargeTax: () => {},
  chargeTotal: undefined,
  setChargeTotal: () => {},
  stripe: undefined,
  setStripe: () => {},
  elements: undefined,
  setElements: () => {},
}
export interface LineItem {
  amount: number
  description: string
}
const PlanIDContext = createContext<PlanIDContextProps>(initPlanIDPropsState)
const StripeContext = createContext<StripeContextProps>(initStripePropsState)

function usePlanID() {
  return useContext(PlanIDContext)
}
function useStripeContext() {
  return useContext(StripeContext)
}

export type FinalizeInvoiceDetails = {
  jobTitle: string
  companyName: string
  website: string
  gorgiasURL: string
  phoneNumber: string
  timezone: string
}

const CompanySetup: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createCompanySchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)

  const [planID, setPlanID] = useState<string | undefined>();
  const [lineItems, setLineItems] = useState<Array<LineItem> | undefined>();
  const [chargeSubtotal, setChargeSubtotal] = useState<number | undefined>();
  const [chargeDiscount, setChargeDiscount] = useState<number | undefined>();
  const [chargeTax, setChargeTax] = useState<number | undefined>();
  const [chargeTotal, setChargeTotal] = useState<number | undefined>();
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const [stripe, setStripe] = useState<Stripe | undefined>();
  const [elements, setElements] = useState<StripeElements | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [loading, setLoading] = useState(false)
  const [formIncomplete, setFormIncomplete] = useState(true);
  const [isStripeFormStep, setIsStripeFormStep] = useState(false);

  const createSubscription = async () => {
    try {
      const response = await createStripeSubscription(planID!);
      setLineItems(response.data.price['lines'] as Array<LineItem>)
      setChargeDiscount(response.data.price['discount_amount'])
      setChargeSubtotal(response.data.price['subtotal']);
      setChargeTax(response.data.price['tax']);
      setChargeTotal(response.data.price['total']);
      return response.data.client_secret;
    } catch (error) {
      alert('Payment Failed: ' + error);
    }
  }
  const finalizeInvoice = async (details: FinalizeInvoiceDetails) => {
    try {
      const response = await finalizeStripeInvoice(details);
      return response
    } catch (error) {
      alert('Payment Failed: ' + error);
    }
  }
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    setIsStripeFormStep(false);
    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber!)
    stepper.current.goPrev()
    setCurrentSchema(createCompanySchemas[stepper.current.currentStepIndex - 1])
  }
  // Some updates when the form is complete
  const paymentElement = elements?.getElement('payment');
  paymentElement?.on('change', function(event) {
    if (event.complete) {
      setFormIncomplete(false);
      setLoading(false);
      setSubmitButton(false);
    }
  })
  const {auth, setCurrentUser} = useAuth();
  const finalize = async (values: any, stepper: React.MutableRefObject<StepperComponent | null>) => {
    const response = await finalizeInvoice({
      'jobTitle': values.jobTitle,
      'companyName': values.companyName, 'website': values.website,
      'gorgiasURL': values.gorgiasURL,
      'phoneNumber': values.phoneNumber,
      'timezone': values.timezone,
    });
    setIsStripeFormStep(false);
    if (response?.status === 200) {
      setLoading(false)
      stepper?.current?.goNext()
    } else {
      setLoading(false)
    }
  }
  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    setErrorMessage(undefined);
    if (!stepper.current) {
      return
    }
    setLoading(true)
    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)
    setCurrentSchema(createCompanySchemas[stepper.current.currentStepIndex])
    // Index 2 is Plan Selection Step
    if (stepper.current.currentStepIndex === 2) {
      const clientSecret = await createSubscription();
      setClientSecret(clientSecret);
      setLoading(false);
      setIsStripeFormStep(true);
      stepper.current.goNext();
    } else if (stepper.current.currentStepIndex === 3) {
      if (elements) {
        if (chargeTotal! > 0) {
          const {error} = await stripe!.confirmPayment({
            elements,
            redirect: 'if_required',
          })
          if (!error) {
            finalize(values, stepper);
          } else {
            setErrorMessage("ERROR: " + error.message);

          }
        } else {
          const {error} = await stripe!.confirmSetup({
            elements,
            redirect: 'if_required',
          });
          if (!error) {
            finalize(values, stepper)
          } else {
            setErrorMessage("ERROR: " + error.message)
          }
        }
      }  
    } else if (isSubmitButton) {
      // Update User - will redirect to dashboard
      const {data: user} = await getUserByToken(auth!.api_token);
      setCurrentUser(user);
    } else if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      setLoading(false)
      stepper.current.goNext()
    } else {
      setLoading(false)
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Account Info</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Plan</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Billing Details</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Completed</h3>
            </div>
          </div>

          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {() => (
              <Form className='mx-auto mw-1000px w-100 pt-15 pb-10' id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <CompanyDetailsStep />
                </div>
                <div data-kt-stepper-element='content'>
                  <PlanIDContext.Provider value={{planID, setPlanID}}>
                    <PlanSelectStep />
                  </PlanIDContext.Provider>
                </div>
                <div data-kt-stepper-element='content'>
                  <StripeContext.Provider value={{
                    clientSecret, setClientSecret,
                    lineItems, setLineItems,
                    chargeSubtotal, setChargeSubtotal,
                    chargeDiscount, setChargeDiscount,
                    chargeTax, setChargeTax,
                    chargeTotal, setChargeTotal,
                    stripe, setStripe,
                    elements, setElements,
                  }}>
                    <BillingStep />
                  </StripeContext.Provider>
                </div>

                <div data-kt-stepper-element='content'>
                  <Step5 />
                </div>

                <div className={`${!isSubmitButton ? 'd-flex' : ''} flex-stack pt-15`}>
                  {!isSubmitButton &&
                    <div className='mr-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3 back-button'
                        data-kt-stepper-action='previous'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Back
                      </button>
                    </div>
                  }
                  <div className='error-message'>
                    {errorMessage}
                  </div>
                  <div style={{display: 'block', float: 'right'}}>
                    {!loading && (!isStripeFormStep) &&
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {!isSubmitButton && <span className='indicator-label'>Continue</span>}
                          {isSubmitButton && 'Dashboard'}
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                        </span>
                      </button>
                    }
                    {!loading && ((isStripeFormStep && !formIncomplete)) &&
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {!isSubmitButton && <span className='indicator-label'>Pay Now</span>}
                          {isSubmitButton && 'Dashboard'}
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                        </span>
                      </button>
                    }
                     {!loading && (isStripeFormStep && formIncomplete) &&
                      <button type='submit' className='btn btn-lg btn-primary me-3' disabled>
                        <span className='indicator-label'>
                          <span className='indicator-label'>Pay Now</span>
                          <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                        </span>
                      </button>
                    }
                    {loading &&
                      <button type='submit' className='btn btn-lg btn-primary me-3' disabled>
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      </button>
                    }
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export {CompanySetup, StripeContext, usePlanID, useStripeContext}
