import {FC} from 'react'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PlanSelectForm} from '../../../billing/components/PlanSelectForm';

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY!);
const PlanSelectStep: FC = () => {
  return (
      <div className='w-100'>
        <Elements stripe={stripePromise}>
          <PlanSelectForm parentPage='companySetup' />
        </Elements>
      </div>
  )
}

export {PlanSelectStep}
