import {FC} from 'react'
import { Container, Col, Row, Stack } from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const Footer: FC = () => (
  <>
    <div className='custom-footer'>
    <Container>
      <Row>
        <Col lg={8} md={6} className='logo-col'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/default-black.png')} className='h-80px logo' />
        </Col>
        <Col lg={4} md={6} className='link-col'>
           <Stack direction='horizontal' gap={5}>
            <Stack>
              <p>Case Studies</p>
              <p>Dedication Blog</p>
              <p>COVID-19</p>
              <a href='/pricing'><p>Pricing</p></a>
            </Stack>
            <Stack>
              <p>Support</p>
              <p>Careers</p>
              <p>Contact</p>
            </Stack>
            <Stack>
              <p>Terms</p>
              <a href='/policies/privacy-policy'><p>Privacy</p></a>
              <p>Press</p>
            </Stack>
           </Stack>
        </Col>
      </Row>
    </Container>
    </div>
  </>
)

export {Footer}
