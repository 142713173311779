import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js'
import {useEffect} from 'react'
import {useStripeContext as useStripeContextCompanySetup} from '../../wizards/components/CompanySetup'
import {useStripeContext as useStripeContextAccountPage} from '../../accounts/AccountPage'


function CheckoutForm(data: any) {
  const { parentPage } = data;
  const stripe = useStripe();
  const elements = useElements();
  const {
    setStripe: setStripeCompanySetup,
    setElements: setElementsCompanySetup
  } = useStripeContextCompanySetup();
  const {
    setStripe: setStripeAccountPage,
    setElements: setElementsAccountPage
  } = useStripeContextAccountPage();
  
  useEffect(() => {
    if (stripe && elements) {
      if (parentPage === 'companySetup') {
        setStripeCompanySetup(stripe);
        setElementsCompanySetup(elements);
      } else if (parentPage === 'accountSettings') {
        setStripeAccountPage(stripe);
        setElementsAccountPage(elements);
      }
    }
  })

  return (
    <div>
        <PaymentElement />
    </div>
  )
}

export default CheckoutForm
