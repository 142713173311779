import {FC, useState} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe, Appearance} from '@stripe/stripe-js';
import CheckoutForm from '../../../billing/components/CheckoutForm'
import { useStripeContext, LineItem } from '../CompanySetup';
import { Container, Row, Col, Stack } from 'react-bootstrap';
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { applyDiscountCode } from '../../../billing/_requests';
import {createSetupIntent} from '../../../billing/_requests'

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY!);

const BillingStep: FC = () => {
  const {
    setChargeSubtotal, setChargeTotal, setChargeDiscount,
    setLineItems, setClientSecret, clientSecret, lineItems,
    chargeSubtotal, chargeDiscount, chargeTotal,
  } = useStripeContext();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const options = {
    clientSecret: clientSecret,
    appearance: {
      theme: 'stripe',
      labels: 'floating',
    } as Appearance
  }
  const formatPrice = (price: number) => {
    return (
        (price/100).toLocaleString().includes('.') ?
        (
          (price/100).toLocaleString().slice(-2).includes('.') ?
          (price/100).toLocaleString() + '0' : (price/100).toLocaleString()
        ) : (price/100).toLocaleString() + '.00');
  }
  const addCard = async () => {
    setErrorMessage(undefined);
    const {data} = await createSetupIntent();
    const {client_secret: secret} = data;
    setClientSecret(secret)
  }
  const applyCode = async () => {
    setLoading(true);
    setErrorMessage(undefined);
    const code = (document.getElementById("DiscountCodeInput") as HTMLInputElement).value;
    if (code === '') {
      setErrorMessage('ERROR: Please enter a discount code');
      setLoading(false);
    } else {
      applyDiscountCode(code).then(async (response) => {
        const price = response.data.price;
        const {client_secret: secret} = response.data;
        setLineItems(price['lines']  as Array<LineItem>);
        setChargeSubtotal(price['subtotal']);
        setChargeDiscount(price['discount_amount']);
        setChargeTotal(price['total']);
        setClientSecret(secret);
        if (!(response.data.price['total'] > 0)) {
          // create setup intent instead
          // NOTE: this will remount the payment method because using clientSecret as key
          await addCard();
        }
      }).catch((error) => {
        setErrorMessage('ERROR: ' + error);
      }).finally(() => {
        (document.getElementById("DiscountCodeInput") as HTMLInputElement).value = '';
        setLoading(false);
      })
    }
  }
  return clientSecret === undefined ? (
    <div></div>
  ) : (
      <div className='w-100'>
        <Container fluid className='p-0 charge-price-breakdown-container'>
          <Row>
            <Col className='left-col' md={12} lg={6}>
              {lineItems?.map((lineItem, index) => {
                return (
                  <Stack key={index} direction='horizontal' className='pricing-stack-row'>
                    <p className='detail-text fw-normal'>{lineItem.description}</p>
                    <p className='amount-text'>${formatPrice(lineItem.amount)}</p>
                  </Stack>
                )
              })}
              <Stack direction='horizontal' className='pricing-stack-row'>
                <p className='detail-text'>Subtotal</p>
                {
                  chargeSubtotal && 
                  <p className='amount-text'>${formatPrice(chargeSubtotal)}</p>
                }
                {
                  !chargeSubtotal && 
                  <p className='amount-text'>$0.00</p>
                }
              </Stack>
              { chargeDiscount &&
                <Stack direction='horizontal' className='pricing-stack-row'>
                  <p className='detail-text'>Discount</p>
                  <p className='amount-text'>- ${formatPrice(chargeDiscount)}</p>
                </Stack>
              }
              {/* <Stack direction='horizontal' className='pricing-stack-row'>
                <p className='detail-text'>Tax</p>
                {
                  (chargeTax != 0) && 
                  <p className='amount-text'>${formatPrice(chargeTax!)}</p>
                }
                {
                  (chargeTax == 0) && 
                  <p className='amount-text'>$0.00</p>
                }
              </Stack> */}
              <Stack direction='horizontal' className='pricing-stack-row last-row'>
                <p className='detail-text total-detail-text'>Total</p>
                {
                  chargeTotal && 
                  <p className='amount-text total-detail-amount'>${formatPrice(chargeTotal)}</p>
                }
                {
                  !chargeTotal && 
                  <p className='amount-text'>$0.00</p>
                }
              </Stack>
            </Col>
            <Col className='right-col' md={12} lg={6}>
              <h3>Have a Discount Code?</h3>
              <div className='input-container'>
                <input
                  id='DiscountCodeInput'
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Discount Code'
                />
                <div className='btn btn-primary macros-button-mobile' onClick={applyCode}>
                  {!loading && 'Apply'}
                  {loading &&
                    <img alt='Loading' className='loading-icon' src={toAbsoluteUrl(`/media/gifs/loading.gif`)} />
                  }
                </div>
              </div>
              <div className='error-message mt-10'>
                  {errorMessage}
                </div>
            </Col>
          </Row>
        </Container>
        <Elements stripe={stripePromise} options={options} key={clientSecret}>
          <CheckoutForm parentPage='companySetup'/>
        </Elements>
      </div>
  )
}

export {BillingStep}
