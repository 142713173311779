import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const GET_QUESTIONS_AND_ANSWERS_URL = `${API_URL}/gorgias/questions-answers/`
const GET_CONDITIONAL_QUESTION_URL = `${API_URL}/gorgias/conditional-questions/`
const SUBMIT_MACRO_ANSWERS_URL = `${API_URL}/gorgias/submit-answers/`
const GET_MACROS_TEXTS_URL = `${API_URL}/gorgias/macros/`

export async function getQuestionAndAnswers() {
  return axios.get(GET_QUESTIONS_AND_ANSWERS_URL)
}

export async function getConditionalQuestion(id: number) {
  return axios.get(GET_CONDITIONAL_QUESTION_URL + `${id}/`)
}

export async function submitMacroAnswers(selectedOptions: Array<Array<string>>, optionResponses: Array<Array<string>>) {
  return axios.post(SUBMIT_MACRO_ANSWERS_URL, {
    'selected_options': selectedOptions,
    'option_responses': optionResponses,
  })
}

export async function getMacros(company_id?: string) {
  if (company_id) {
    return axios.get(GET_MACROS_TEXTS_URL + '?company=' + company_id)
  } else {
    return axios.get(GET_MACROS_TEXTS_URL)
  }
}
