import {FC} from 'react'
import {NavigationBar} from './components/NavigationBar'
import {Row, Col, Button, Container, Stack} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { Footer } from './components/Footer'

const HomePage: FC = () => (
  <>
    <div className='custom-page home-page'>
      <div className='home-above-fold'>
        <NavigationBar />
        <Container fluid className="p-0">
          <Row className='flex-column-reverse flex-md-row'>
            <Col className='left-col' sm={12} md={4} lg={5}>
              <div className='left-col-inner'>
                <h1>Trained e-commerce customer service agents from just $55 per month</h1>
                <p className='paragraph-text'>No call centers. No robots. No hiring and training. Just Gorgias trained e-com customer
                  service agents ready for deployment.
                </p>
                <Button href="/login/registration">GET STARTED</Button>
                <div className='gorgias-text'>
                  <img alt='Gorigias Logo' className='gorgias-logo' src={toAbsoluteUrl('/media/icons/custom/gorgias.png')} />
                  <p>Gorgias Premiere Partner</p>
                </div>
                <img alt='Shopify partner' className='shopify-partner-logo' src={toAbsoluteUrl('/media/icons/custom/shopify-partner.png')} />
              </div>
            </Col>
            <Col className='right-col' sm={12} md={8} lg={7}>
              <img alt='Blue Curve' className='blue-curve-bottom' src={toAbsoluteUrl('/media/illustrations/custom/blue-curve-bottom.png')} />
              <img alt='Blue Curve' className='blue-curve-top' src={toAbsoluteUrl('/media/illustrations/custom/blue-curve-top.png')} />
              <video playsInline={true} autoPlay loop muted id='video'>
                <source src={toAbsoluteUrl('/media/videos/homepage-background-video.mp4')} type='video/mp4' />
              </video>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='firstline-section'>
        <Container fluid className="p-0">
          <Row>
            <Col lg={12}>
              <h2>Dedication Agents are the first line of communication for your customers</h2>
              <p className='subtext'>
                We create personalized experiences for your customers. We ensure that all inquiries are 
                handled with pinpoint accuracy, quickly, and efficiently, so your customers don't wait 
                too long for a response; as this can impact all aspects of the customer experience.
              </p>
            </Col>
          </Row>
          <Row className='icon-text-row'>
            <h3>Get more done with less effort</h3>
            <Col lg={3} md={6} sm={12}>
              <img alt='Chat Bubbles Icon' className='chatbubbles-icon' src={toAbsoluteUrl('/media/icons/custom/chat-bubbles-icon.png')} />
              <h4>Answers all tickets</h4>
              <p>Get your support tickets managed and organized</p>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <img alt='Upwards Icon' className='upwards-icon' src={toAbsoluteUrl('/media/icons/custom/upwards-icon.png')} />
              <h4>Escalates priority issues</h4>
              <p>For those tickets that require immediate attention so they don't 
                get lost in the fray
              </p>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <img alt='Conversation Icon' className='convo-icon' src={toAbsoluteUrl('/media/icons/custom/convo-icon.png')} />
              <h4>Support requests</h4>
              <p>We also care about supporting you as a client!</p>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <img alt='Location Icon' className='location-icon' src={toAbsoluteUrl('/media/icons/custom/location-icon.png')} />
              <h4>Daily<br />check ins</h4>
              <p>100% transparency. Never be in the dark when it comes to Dedication Agents</p>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div className='what-we-do-section'>
        <Container fluid className="p-0">
          <Row>
            <Col className='left-col' md={12} lg={6}>
              <div className='left-col-inner'>
                <p>WHAT WE DO</p>
                <h2>We give you customer service muscle to supercharge your user experience.</h2>
                <p className='paragraph-text'>
                  Hire e-commerce experts trained in Gorgias Help Desk to answer customers' 
                  questions while you focus on what matters most.
                </p>
                <Button href="/">SEE WHAT ELSE IS INCLUDED</Button>
              </div>
            </Col>
            <Col className='right-col' md={12} lg={6}>
              <div className='right-col-inner'>
                <div>
                  <h3>Gorgias Certified</h3>
                  <p className='paragraph-text'>
                    We are Gorgias certified customer service agents that can streamline 
                    your dashboard setup and respond to tickets.
                  </p>
                </div>
                <div>
                  <h3>On-Demand Hiring</h3>
                  <p className='paragraph-text'>
                    Are agents are ready to start 24 hours after onboarding without 
                    additional training and HR management.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      <div className='get-started-section'>
        <Container fluid className="p-0">
          <Row>
            <Col lg={6} xl={7} className='left-col'>
              <h2>Getting Started in 24-48 Hours</h2>
              <Stack direction="horizontal" gap={5}>
                <img alt='Arrow and Line Icon' className='arrow-line-img' src={toAbsoluteUrl('/media/icons/custom/arrow-line-white.png')} />
                <Stack gap={1} className='text-stack'>
                  <h4>Submit company profile + e-com settings</h4>
                  <p>Set your company's support hours, shipping preferences, and refund policies</p>
                </Stack>
              </Stack>
              <Stack direction="horizontal" gap={5}>
                <img alt='Arrow and Line Icon' className='arrow-line-img' src={toAbsoluteUrl('/media/icons/custom/arrow-line-white.png')} />
                <Stack gap={1} className='text-stack'>
                  <h4>Customize response types and user message points</h4>
                  <p>We review your e-commerce settings, FAQ, and terms and conditions to make sure we 
                    are synced with your policies
                  </p>
                </Stack>
              </Stack>
              <Stack direction="horizontal" gap={5}>
                <img alt='Arrow and Line Icon' className='arrow-line-img' src={toAbsoluteUrl('/media/icons/custom/arrow-line-white.png')} />
                <Stack gap={1} className='text-stack'>
                  <h4>Let us show you around</h4>
                  <p>We'll give you an overview of your daily updates and send a few tickets to make 
                    sure we're ready to engage with customers
                  </p>
                </Stack>
              </Stack>
              <Stack direction="horizontal" gap={5}>
                <img alt='Arrow and Line Icon' className='arrow-img' src={toAbsoluteUrl('/media/icons/custom/arrow-circle-white.png')} />
                <Stack gap={1} className='text-stack'>
                  <h4>Gorgias dashboard optimization</h4>
                  <p>Integrating your customized macros and FAQ directly into your Gorgias and 
                    setting up optimized channel views to quickly identify key issues
                  </p>
                </Stack>
              </Stack>
            </Col>
            <Col lg={6} xl={5} className='right-col'>
              <Stack direction="horizontal" gap={5} className='agent-stack-1'>
                <img alt='Avatar' className='avatar-circle-img' src={toAbsoluteUrl('/media/avatars/custom/johnB.png')} />
                <Stack className='text-icon-stack'>
                  <h4>John B.</h4>
                  <p>Gorgias expert in</p>
                  <Stack direction="horizontal" gap={5} className='icon-stack'>
                    <img alt='Refund & Exchange Skills' className='expert-skills-img' src={toAbsoluteUrl('/media/icons/custom/refunds-exchange.png')} />
                    <img alt='Macros Rules Skills' className='expert-skills-img' src={toAbsoluteUrl('/media/icons/custom/macros-rules.png')} />
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="horizontal" gap={5} className='agent-stack-2'>
                <img alt='Avatar' className='avatar-circle-img' src={toAbsoluteUrl('/media/avatars/custom/georgeL.png')} />
                <Stack className='text-icon-stack'>
                  <h4>Goerge L.</h4>
                  <p>Gorgias expert in</p>
                  <Stack direction="horizontal" gap={5} className='icon-stack'>
                    <img alt='Escalations Skills' className='expert-skills-img' src={toAbsoluteUrl('/media/icons/custom/escalations.png')} />
                    <img alt='Workflow Integration Skills' className='expert-skills-img' src={toAbsoluteUrl('/media/icons/custom/workflow-integrations.png')} />
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="horizontal" gap={5} className='agent-stack-3'>
                <img alt='Avatar' className='avatar-circle-img' src={toAbsoluteUrl('/media/avatars/custom/shellaC.png')} />
                <Stack className='text-icon-stack'>
                  <h4>Shella C.</h4>
                  <p>Gorgias expert in</p>
                  <Stack direction="horizontal" gap={5} className='icon-stack'>
                    <img  alt='Escalations Skills'className='expert-skills-img' src={toAbsoluteUrl('/media/icons/custom/escalations.png')} />
                    <img alt='Aircall Skills' className='expert-skills-img' src={toAbsoluteUrl('/media/icons/custom/aircall.png')} />
                  </Stack>
                </Stack>
              </Stack>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='trusted-clients-section'>
        <Container fluid className="p-0">
          <h2>Trusted by awesome clients</h2>
          <Row className='review-row'>
            <Col lg={4} md={12}>
              <div className='review-wrapper'>
                <Stack className='img-text-stack' direction='horizontal' gap={5}>
                  <img alt='Avatar' className='avatar-circle-img' src={toAbsoluteUrl('/media/avatars/custom/lanadelray-review.png')} />
                  <Stack>
                    <h3>Lana Del Rey Village</h3>
                    <img alt='5-Stars' className='five-stars-img' src={toAbsoluteUrl('/media/icons/custom/5stars.png')} />
                  </Stack>
                </Stack>
                <p>
                  "Dedication Agents gave us he bandwidth to be hyper-focused on ramping up 
                  the business. They're an affordable solution to hiring and training our 
                  own customer support team."
                </p>
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className='review-wrapper'>
                <Stack className='img-text-stack' direction='horizontal' gap={5}>
                  <img alt='Avatar' className='avatar-circle-img' src={toAbsoluteUrl('/media/avatars/custom/wing-review.png')} />
                  <Stack>
                    <h3>Wing Tel</h3>
                    <img alt='5-Stars' className='five-stars-img' src={toAbsoluteUrl('/media/icons/custom/5stars.png')} />
                  </Stack>
                </Stack>
                <p>
                  "Dedication Agents have saved us so much time from not having to figure out 
                  customer service ourselves! We're able to focus on tasks that need to get done 
                  instead of being stuck on the nitty gritty of customer support."
                </p>
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className='review-wrapper'>
                <Stack className='img-text-stack' direction='horizontal' gap={5}>
                  <img alt='Avatar' className='avatar-circle-img' src={toAbsoluteUrl('/media/avatars/custom/khalifakush-review.png')} />
                  <Stack>
                    <h3>Khalifa Kush</h3>
                    <img alt='5-Stars' className='five-stars-img' src={toAbsoluteUrl('/media/icons/custom/5stars.png')} />
                  </Stack>
                </Stack>
                <p>
                  "Didn't know where to start in creating a customer support team. Starting from 
                  scratch was my biggest fear until I discovered Dedication Agents. They guided 
                  me through what needed to be done while they did all the heavy work."
                </p>
              </div>
            </Col>
          </Row>
          <Button href='/login/registration'>GET STARTED</Button>
        </Container>
      </div>
      <div className='cost-effective-section'>
        <Container fluid className="p-0">
          <Row>
            <Stack>
              <h2>Cost-effective and scalable</h2>
              <p className='subtitle'>Call centers and BPO's charge 8-10x the actual rate they pay their agents, 
                leaving them overworked and underpaid. We optimize experienced agents to 
                peak efficiency.
              </p>
            </Stack>
          </Row>
          <Row>
            <Col lg={5}>
              <Stack className='starting-at-stack'>
                <h3>Starting At</h3>
                <Stack direction='horizontal'>
                  <h3 className='price'>$55</h3>
                  <h3 className='per-month'>/per month</h3>
                </Stack>
              </Stack>
            </Col>
            <Col lg={7}>
              <ul>
                <li>Streamline client messaging and Gorgias Dashboard</li>
                <li>Agents are highly educated and trained to be efficient</li>
                <li>Affordable rates for additional tickets and requests</li>
              </ul>
            </Col>
          </Row>
          <Row>
          <Button href="/login/registration">GET STARTED</Button>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  </>
)

export {HomePage}
