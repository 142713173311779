/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useState } from 'react'
import {KTSVG} from '../../../_metronic/helpers'

import {useAuth} from '../../../app/modules/auth'
import {uploadCompanyLogo} from './_requests'

const AccountHeader: React.FC = () => {
  const {currentUser, setCurrentUser} = useAuth()
  const [errorMsg, setErrorMsg] = useState("")
  // So ugly... but not sure how else to go about it
  // https://stackoverflow.com/questions/17546953/cant-access-object-property-even-though-it-shows-up-in-a-console-log
  const user = JSON.parse(JSON.stringify(currentUser)).user;
  const company = user.company;
  const plan = company.plan;
  const isOwner = user.is_owner;
  const logo = user.company.logo;
  let logoURL: string = logo || `https://ui-avatars.com/api/?name=${company.name}&size=150`
  async function onFileChange(event: ChangeEvent) {
    const target= event.target as HTMLInputElement;
    const file = target?.files ? target.files[0] : null;
    if (!file) {
      setErrorMsg("Error: Issue uploading file. If error persists, please contact support.")
    } else {
      uploadCompanyLogo(file!).then((response) => {
        const {data: updatedUser} = response;
        setCurrentUser(updatedUser);
      }).catch((error) => {
        setErrorMsg("Error: " + error)
      });
    }
  }

  const formatPrice = (price: number) => {
    return (
        (price).toLocaleString().includes(".") ? (price).toLocaleString() : (price).toLocaleString() + ".00"
      );
  }

  return (
    <div className='card mb-5 mb-xl-10 dash-account-header-section'>
      <h1>Account Settings</h1>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <label>
                { isOwner && <input onChange={onFileChange} type="file" name="image" id="avatar-upload" style={{display: 'none'}}/>}
                { !logo && <img className='company-logo' src={logoURL} alt='Metronic'/> }
                { logo && <div className='company-logo' style={{
                  height: "150px", width: "150px",
                  backgroundImage: `url(${logoURL})`, backgroundPosition: "center"
                }}>
                </div> }
              </label>
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-gray-800 fs-2 fw-bolder me-1'>
                    {company.name}
                  </p>
                  <KTSVG
                    path='/media/icons/duotune/general/gen026.svg'
                    className='svg-icon-1 svg-icon-primary'
                  />
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <p
                    className='d-flex align-items-center text-gray-400 mb-2'
                  >
                    {company.website}
                  </p>
                </div>
                  <p
                    className='d-flex align-items-center text-gray-800 mb-2 mt-3 fw-bold fs-5'
                  >
                    {plan.name}
                  </p>
                  <p
                    className='d-flex align-items-center text-gray-400 mb-2 fw-bold fs-6'
                  >
                    ${formatPrice(plan.price)} / {plan.schedule === 'monthly' ? 'month' : 'year'}
                  </p>
                <p className="error-message">
                  {errorMsg}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AccountHeader}
