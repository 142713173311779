import axios from 'axios'
import {
    CompanyDetailsModel, IProfileDetails, IUpdateEmail,
    IUpdatePassword, ThirdPartyIntegrationsModel,
} from '../../modules/accounts/components/settings/SettingsModel'

const API_URL = process.env.REACT_APP_API_URL
const COMPANY_DETAILS_URL = `${API_URL}/companies/`
const THIRD_PARTY_INTEGRATIONS_URL = `${API_URL}/companies/third-party-integrations/`
const COMPANY_LOGO_URL = `${API_URL}/companies/upload-logo/`
const USER_DETAILS_URL = `${API_URL}/users/`
const CHANGE_USER_EMAIL_URL = `${API_URL}/users/update_email/`
const CHANGE_USER_PASSWORD_URL = `${API_URL}/users/update_password/`
const COMPANY_ONBOARDING_PROGRESS_URL = `${API_URL}/companies/onboarding-progress/`

// TODO: should this be string or int?
export async function updateCompany(company_id: string, data: CompanyDetailsModel) {
    return axios.put(
        COMPANY_DETAILS_URL + company_id + '/', {
            'phone_number': data.phoneNumber,
            'website': data.website,
            'timezone': data.timezone,
            'twitter_url': data.twitterURL,
            'instagram_url': data.instagramURL,
            'facebook_url': data.facebookURL,
            'faq_url': data.faqURL,
            'blog_url': data.blogURL,
            'newsletter_signup_url': data.newsletterSignupURL,
        }
    )
}

export async function updateThirdPartyIntegrations(data: ThirdPartyIntegrationsModel) {
    return axios.put(
        THIRD_PARTY_INTEGRATIONS_URL, {
            'gorgias_url': data.gorgiasURL,
            'shopify_url': data.shopifyURL,
            'shipstation_url': data.shipstationURL,
        }
    )
}

export async function uploadCompanyLogo(file: File) {
    let formData = new FormData()
    formData.append('file', file)
    return axios.post(
        COMPANY_LOGO_URL,
        formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
        }
    )
}

// TODO: should this be string or int?
export async function updateUser(user_id: string, data: IProfileDetails) {
    return axios.post(
        USER_DETAILS_URL + user_id + '/', {
            'first_name': data.fName,
            'last_name': data.lName,
            'job_title': data.position,
        }
    )
}

export async function changeUserEmail(data: IUpdateEmail) {
    return axios.post(
        CHANGE_USER_EMAIL_URL, {
            'email': data.newEmail,
            'password': data.confirmPassword,
        }
    )
}

export async function changeUserPassword(data: IUpdatePassword) {
    return axios.post(
        CHANGE_USER_PASSWORD_URL, {
            'current_password': data.currentPassword,
            'new_password': data.passwordConfirmation,
        }
    )
}

export async function getOnboardingProgress() {
    return axios.get(COMPANY_ONBOARDING_PROGRESS_URL)
}
