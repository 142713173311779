import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestResetCode, resetPassword} from '../core/_requests'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'

const initialForgotPasswordValues = {
  email: '',
}
const initialResetCodeValues = {
  resetCode: '',
  password: '',
  confirmPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})
const resetCodeSchema = Yup.object().shape({
  resetCode: Yup.string()
    .required('Reset Code is required'),
  password: Yup.string()
  .required('Password is required'),
  confirmPassword: Yup.string()
  .required('Password Confirmation is required'),
})

enum FormStep {
  Step1 = 1,
  Step2,
  Step3,
}

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [formStep, setFormStep] = useState<FormStep>(1)
  const [email, setEmail] = useState<string | undefined>(undefined)
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])
  const formik_forgot_password = useFormik({
    initialValues: initialForgotPasswordValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setErrorMessage(undefined)
      setEmail(values.email)
      requestResetCode(values.email)
        .then((response) => {
          setHasErrors(false)
          setLoading(false)
          setFormStep(2)
        })
        .catch((error) => {
          setErrorMessage("ERROR: " + error)
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          setStatus(error)
        })
    },
  })
  const formik_reset_code = useFormik({
    initialValues: initialResetCodeValues,
    validationSchema: resetCodeSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setErrorMessage(undefined)
      resetPassword(values.resetCode, values.password, values.confirmPassword, email!)
      .then((response) => {
        setHasErrors(false)
        setLoading(false)
        setFormStep(3)
      })
      .catch((error) => {
        setErrorMessage("ERROR: " + error)
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        setStatus(error)
      })
    },
  })
  function FirstStep() {
    return (
      <div className={`forgot-password-step ${formStep !== FormStep.Step1 ? 'hidden' : ''}`}>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik_forgot_password.handleSubmit}
        >
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Forgot Password?</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password</div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                {/* Sorry, looks like there are some errors detected, please try again. */}
                {errorMessage}
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>Sent password reset. Please check your email</div>
            </div>
          )}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
            <input
              type='email'
              placeholder=''
              autoComplete='off'
              {...formik_forgot_password.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik_forgot_password.touched.email && formik_forgot_password.errors.email},
                {
                  'is-valid': formik_forgot_password.touched.email && !formik_forgot_password.errors.email,
                }
              )}
            />
            {formik_forgot_password.touched.email && formik_forgot_password.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik_forgot_password.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary fw-bolder me-4'
            >
              <span className='indicator-label'>Submit</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bolder'
                disabled={formik_forgot_password.isSubmitting || !formik_forgot_password.isValid}
              >
                Cancel
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </form>
      </div>
    )
  }
  function SecondStep() {
    return (
      <div className={`forgot-password-step ${formStep !== FormStep.Step2 ? 'hidden' : ''}`}>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_reset_code_form'
          onSubmit={formik_reset_code.handleSubmit}
        >
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Enter Reset Code</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4'>Reset Code expires within 10 minutes</div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                {/* Sorry, looks like there are some errors detected, please try again. */}
                {errorMessage}
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>Sent password reset. Please check your email</div>
            </div>
          )}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Reset Code</label>
            <input
              type='text'
              placeholder=''
              autoComplete='off'
              {...formik_reset_code.getFieldProps('resetCode')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik_reset_code.touched.resetCode && formik_reset_code.errors.resetCode},
                {
                  'is-valid': formik_reset_code.touched.resetCode && !formik_reset_code.errors.resetCode,
                }
              )}
            />
            {formik_reset_code.touched.resetCode && formik_reset_code.errors.resetCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik_reset_code.errors.resetCode}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group password */}
          <div className='mb-10 fv-row' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>New Password</label>
              <div className='position-relative mb-3'>
                <input
                  type='password'
                  placeholder=''
                  autoComplete='off'
                  {...formik_reset_code.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik_reset_code.touched.password && formik_reset_code.errors.password,
                    },
                    {
                      'is-valid': formik_reset_code.touched.password && !formik_reset_code.errors.password,
                    }
                  )}
                />
                {formik_reset_code.touched.password && formik_reset_code.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik_reset_code.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* begin::Meter */}
              <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
              >
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
              </div>
              {/* end::Meter */}
            </div>
            <div className='text-muted'>
              Use 8 or more characters, with at least one of each of the following: 
              Uppercase, Lowercase, Number, Symbol.
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group Confirm password */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
            <input
              type='password'
              placeholder=''
              autoComplete='off'
              {...formik_reset_code.getFieldProps('confirmPassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik_reset_code.touched.confirmPassword && formik_reset_code.errors.confirmPassword,
                },
                {
                  'is-valid': formik_reset_code.touched.confirmPassword && !formik_reset_code.errors.confirmPassword,
                }
              )}
            />
            {formik_reset_code.touched.confirmPassword && formik_reset_code.errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik_reset_code.errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_reset_code_submit'
              className='btn btn-lg btn-primary fw-bolder me-4'
            >
              <span className='indicator-label'>Submit</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bolder'
                disabled={formik_reset_code.isSubmitting || !formik_reset_code.isValid}
              >
                Cancel
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </form>
      </div>
    )
  }
  function LastStep() {
    return (
      <div className={`forgot-password-step ${formStep !== FormStep.Step3 ? 'hidden' : ''}`}>
        <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Password Succesfully Reset</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4'>Click the button below to visit the login page</div>
            {/* end::Link */}
          </div>
          <Link to='/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-primary fw-bolder done-btn'
            >
              Done
            </button>
          </Link>
      </div>
    )
  }

  return (
    <div className='forgot-password-page'>
      {FirstStep()}
      {SecondStep()}
      {LastStep()}
    </div>
  )

}
