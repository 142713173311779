import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  // request interceptor
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  // response interceptor
  axios.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (err: any) => {
      // Making API errors more readable
      // NOTE: this may be an issue with other APIs
      let errors = err.response?.data.errors;
      if (!errors || errors.length === 0) {
        return Promise.reject(err);
      }
      let errorMessage = "";
      for (let i = 0; i < errors.length; i++) {
        const params = errors[i]["parameters"]
        if (params) {
          for (let y = 0; y < params.length; y++) {
            errorMessage += JSON.stringify(errors[i]["detail"][params[y]]) + ' ';
          }
        } else {
          errorMessage += JSON.stringify(errors[i]["detail"]) + ' ';
        }
      }
      // NOTE: removing quotes. not sure where they came from tbh...
      // maybe because of the string creation above?
      return Promise.reject(errorMessage.replaceAll('"',''));
    }
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
