import React, {createContext, useContext, useState, Dispatch, SetStateAction} from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Billing} from './components/Billing'
import {Settings} from './components/settings/Settings'
import {AccountHeader} from './AccountHeader'
import {Stripe, StripeElements} from '@stripe/stripe-js'

type StripeContextProps = {
  clientSecret: string | undefined
  setClientSecret: Dispatch<SetStateAction<string | undefined>>
  stripe: Stripe | undefined
  setStripe: Dispatch<SetStateAction<Stripe | undefined>>
  elements: StripeElements | undefined
  setElements: Dispatch<SetStateAction<StripeElements | undefined>>
}
const initStripePropsState = {
  clientSecret: undefined,
  setClientSecret: () => {},
  stripe: undefined,
  setStripe: () => {},
  elements: undefined,
  setElements: () => {},
}
const StripeContext = createContext<StripeContextProps>(initStripePropsState)
export function useStripeContext() {
  return useContext(StripeContext)
}

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Account',
    path: '/account/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AccountPage: React.FC = () => {
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const [stripe, setStripe] = useState<Stripe | undefined>();
  const [elements, setElements] = useState<StripeElements | undefined>();
  return (
    <div className='account-page-wrapper'>
      <Routes>
        <Route
          element={
            <>
              <AccountHeader />
              <Outlet />
            </>
          }
        >
          {/* <Route
            path='overview'
            element={
              <>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
                <Overview />
              </>
            }
          /> */}
          <Route
            path='billing'
            element={
              <>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Billing</PageTitle>
                <StripeContext.Provider value={{
                    clientSecret, setClientSecret,
                    stripe, setStripe,
                    elements, setElements,
                  }}>
                    <Billing />
                </StripeContext.Provider>
              </>
            }
          />
          <Route
            path='profile'
            element={
              <>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Settings</PageTitle>
                <Settings />
              </>
            }
          />
          <Route index element={<Navigate to='/account/overview' />} />
        </Route>
      </Routes>
    </div>
  )
}

export default AccountPage
