import {useEffect, useState} from 'react'
import {Stack} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import { usePlanID as usePlanIDCompanySetup } from '../../wizards/components/CompanySetup';
import { usePlanID as usePlanIDBilling } from '../../accounts/components/Billing';
import { useAuth } from '../../auth';
import {Plan} from '../../accounts/components/Billing';

// TODO: For nowplans are hardcoded, but may want to move them to the backend at some point
// TODO: Also, create interface for this
const plans = [
  {
    monthly_id: process.env.REACT_APP_ESSENTIALS_MONTHLY,
    annual_id: process.env.REACT_APP_ESSENTIALS_ANNUALLY,
    title: 'THE ESSENTIALS',
    subTitle: 'Best for startups',
    // description: 'Optimal for 10+ team size and new startup',
    priceMonth: 70.00,
    priceAnnual: 55.00,
    priceMonthString: '$70',
    priceAnnualString: '$55',
    default: true,
    // custom: false,
    features: [
      {
        title: 'Includes 40 Tickets',
        supported: true,
      },
      {
        title: 'Support Channels: Email, Social Media',
        supported: true,
      },
      {
        title: 'Gorgias Setup: Dashboard Views, Back End Setup',
        supported: true,
      },
      {
        title: 'Compatible With: Shopify, Gorgias, Shipstation',
        supported: true,
      },
    ],
  },

  {
    monthly_id: process.env.REACT_APP_COMPLETE_MONTHLY,
    annual_id: process.env.REACT_APP_COMPLETE_ANNUALLY,
    title: 'THE COMPLETE PACKAGE',
    subTitle: 'Best for 100+ team size',
    // description: 'Optimal for 20+ team size and new startup',
    priceMonth: 625.00,
    priceAnnual: 500.00,
    priceMonthString: '$625',
    priceAnnualString: '$500',
    // label: 'Most popular',
    default: false,
    // custom: false,
    features: [
      {
        title: 'Includes 300 Tickets',
        supported: true,
      },
      {
        title: 'Support Channels: Email, Social Channels, Live Chat',
        supported: true,
      },
      {
        title: 'Gorgias Setup: Essentials + Help Center, Chat Support',
        supported: true,
      },
      {
        title: 'Compatible With: Shopify & Gorgias',
        supported: true,
      },
      {
        title: 'Tier 2 Escalations Support',
        supported: true,
      },
    ],
  },

  {
    monthly_id: process.env.REACT_APP_ELITE_MONTHLY,
    annual_id: process.env.REACT_APP_ELITE_ANNUALLY,
    title: 'ALL ELITE EVERYTHING',
    subTitle: 'Best value for 1000+ team',
    // description: 'Optimal for 50+ team size and new startup',
    priceMonth: 1875.00,
    priceAnnual: 1500.00,
    priceMonthString: '$1875',
    priceAnnualString: '$1500',
    default: false,
    // custom: false,
    features: [
      {
        title: 'Includes 1,000 Tickets',
        supported: true,
      },
      {
        title: 'Support Channels: Email, Social Channels, Live Chat',
        supported: true,
      },
      {
        title: 'Gorgias Setup: Complete Package + Custom Integrations',
        supported: true,
      },
      {
        title: 'Compatible With: Shopify, Magento, Big Commerce, WooCommerce',
        supported: true,
      },
      {
        title: 'Custom Support Hours',
        supported: true,
      },
    ],
  },
]

function PlanSelectForm(data: any) {
  const { parentPage } = data;
  const {currentUser} = useAuth();
  const user = JSON.parse(JSON.stringify(currentUser)).user;
  const currentPlan = user.company?.plan;
  const [selected, setSelected] = useState(
    () => {
      if (currentPlan !== undefined) {
        return currentPlan.stripe_id;
      }
      return plans[0].monthly_id;
    }
  );
  const [currentState, setCurrentState] = useState<'monthly' | 'annual'>(
    () => {
      if (currentPlan !== undefined) {
        return currentPlan.schedule === 'monthly' ? 'monthly' : 'annual';
      }
      return 'monthly';
    }
  );
  const {
    setPlanID: setPlanIDCompanySetup,
  } = usePlanIDCompanySetup();
  const {
    setPlan: setPlanBilling,
    setErrorMessage,
  } = usePlanIDBilling();

  const defaultPlan: Plan = {
    'id': currentPlan?.stripe_id,
    'price': currentPlan?.price,
  }

  function setPlan(plan: Plan) {
    setSelected(plan.id);
    if (parentPage === 'companySetup') {
      return setPlanIDCompanySetup(plan.id);
    } else if (parentPage === 'billing') {
      return setPlanBilling(plan);
    }
  }
  // Set default Plan (should run only once because of [])
  useEffect(() => {
    if (parentPage === 'companySetup') {
      setPlanIDCompanySetup(plans[0].monthly_id);
    } else if (parentPage === 'billing') {
      setPlanBilling(defaultPlan);
    }
  }, [])

  return <div className='plan-select-form' id='plan-select-form'>
    <div className='d-flex flex-column'>
      <div className='nav-group nav-group-outline mx-auto' data-kt-buttons='true'>
        <a
          href='#plan-select-form'
          className={
            'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
            (currentState === 'monthly' && 'active')
          }
          onClick={() => {
            setCurrentState('monthly');
            setErrorMessage(undefined);
            if (currentPlan?.schedule === 'monthly') {
              setPlan(defaultPlan);
            } else {
              setPlan({
                'id': plans[0]['monthly_id']!,
                'price': plans[0]['priceMonth'],
              });
            }
          }}
          data-kt-plan='month'
        >
          Monthly
        </a>
        <a
          href='#plan-select-form'
          className={
            'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
            (currentState === 'annual' && 'active')
          }
          onClick={() => {
            setCurrentState('annual');
            setErrorMessage(undefined);
            if (currentPlan?.schedule === 'annual') {
              setPlan(defaultPlan);
            } else {
              setPlan({
                'id': plans[0]['annual_id']!,
                'price': plans[0]['priceAnnual'] * 12,
              });
            }
          }}
          data-kt-plan='annual'
        >
          Annual
        </a>
      </div>
      <div className='row mt-0'>
        <div className='col-lg-6 mb-10 mb-lg-0'>
          <div className='nav flex-column'>
            {plans.map((plan, index) => {
              return (
                <div
                  onClick={async () => {
                    if (currentState === 'monthly') {
                      setPlan({
                        'id': plan.monthly_id!,
                        'price': plan.priceMonth,
                      })
                    } else {
                      setPlan({
                        'id': plan.annual_id!,
                        'price': plan.priceAnnual * 12,
                      });
                    } 
                  }}
                  className={
                    `nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6
                    btn-active btn-active-primary
                    ${(index !== plans.length - 1) ? 'mb-6 ' : ''}
                    ${(selected === plan.monthly_id || selected === plan.annual_id) ? 'active ' : ''}
                  `
                  }
                  data-bs-toggle='tab'
                  data-bs-target={`#kt_upgrade_plan_${index}`}
                  key={index}
                >
                  <div className='d-flex align-items-center me-2 select-price-card'>
                    <div className='form-check form-check-custom form-check-solid form-check-success me-6'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='plan'
                        value={currentState === 'monthly' ? plan.monthly_id : plan.annual_id}
                        checked={selected === plan.monthly_id || selected === plan.annual_id }
                        onChange={(e) => setSelected(e.target.value)}
                      />
                    </div>

                    <div className='flex-grow-1'>
                      <h2 className='d-flex align-items-center fs-2 fw-bolder flex-wrap'>
                        {plan.title}

                        {/* {plan.label && (
                          <span className='badge badge-light-success ms-2 fs-7'>
                            {plan.label}
                          </span>
                        )} */}
                      </h2>
                      {/* <div className='fw-bold opacity-50'>{plan.subTitle}</div> */}
                    </div>
                  </div>

                  <div className='ms-5'>
                    {plan.priceMonthString && (
                      <>
                        <span className='fs-3x fw-bolder'>
                          {currentState === 'monthly' ? plan.priceMonthString : plan.priceAnnualString}
                        </span>

                        <span className='fs-7 opacity-50'>
                          /<span data-kt-element='period'>Month</span>
                        </span>
                      </>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className='col-lg-6'>
          <div className={`tab-content rounded h-100 p-10 ${parentPage === 'companySetup' ? 'bg-light shadow-sm' : 'pt-0'}`}>
            {plans.map((plan, index) => {
              return (
                <div key={`custom${index}`}>
                 
                    <>
                      <div
                        className={`tab-pane fade` + (plan.default && 'show active')}
                        id={`kt_upgrade_plan_${index}`}
                        key={index}
                        style={{display: (selected === plan.monthly_id || selected === plan.annual_id)? '' : 'none'}}
                      >
                        <div className='pb-5'>
                          <h2 className='fw-bolder text-dark'>{plan.title}</h2>
                          <h4>Starting At</h4>
                          {/* <div className='text-gray-400 fw-bold'>{plan.description}</div> */}
                        </div>
                        {plan.priceMonth && (
                          <Stack direction='horizontal' gap={1}>
                            <p className='plan-price'>
                              {currentState === 'monthly' ? plan.priceMonthString : plan.priceAnnualString}
                            </p>
                            <p className='plan-price-cycle'>
                              /Month
                            </p>
                          </Stack>
                        )}
                        <div className='pt-1'>
                          {plan.features!.map((feature, i) => {
                            return (
                              <div
                                className={
                                  `d-flex align-items-center` +
                                  (i !== plan.features!.length - 1 && ' mb-7')
                                }
                                key={`${i}-${feature.title}`}
                              >
                                {feature.supported && (
                                  <>
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen043.svg'
                                      className='svg-icon-1 svg-icon-success'
                                    />
                                    <span className='fw-bold fs-5 text-gray-700 flex-grow-1 feature-title'>
                                      {feature.title}
                                    </span>
                                  </>
                                )}
                                {!feature.supported && (
                                  <>
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen040.svg'
                                      className='svg-icon-1'
                                    />
                                    <span className='fw-bold fs-5 text-gray-400 flex-grow-1 feature-title'>
                                      {feature.title}
                                    </span>
                                  </>
                                )}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </>
                  
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  </div>
}

export {PlanSelectForm, plans}
