import axios from 'axios'
import { UploadURL, UploadDoc } from '../../pages/dashboard/AIPage'

const API_URL = process.env.REACT_APP_API_URL
const AI_COMPANY_DOCS_URL = `${API_URL}/vector_uploads/`
const AI_DELETE_COMPANY_DOC_URL = `${API_URL}/delete_vector_upload/`
const UPLOAD_URL_DOC_URL = `${API_URL}/companies/urls/`
const UPLOAD_DOC_URL = `${API_URL}/companies/docs/`
const LAUNCH_AI_URL = `${API_URL}/companies/launch-ai/`

export async function getCompanyDocs() {
  return axios.get(AI_COMPANY_DOCS_URL)
}

export async function deleteCompanyDoc(filename?: string, url?: string) {
  if (!(filename || url)) {
    throw new Error('Either filename or URL is required.')
  }
  interface Data {
    filename?: string
    url?: string
  }
  let data: Data = {}
  if (filename) {
    data.filename = filename
  } else {
    data.url = url
  }
  return axios.post(
    AI_DELETE_COMPANY_DOC_URL, data
  )
}

export async function uploadURLDoc(data: UploadURL) {
  return axios.post(
    UPLOAD_URL_DOC_URL, {
      "url": data.url,
      "qa_format": data.qaFormat,
      "overwrite": data.overwrite,
    }
  )
}

export async function uploadFileDoc(doc: File, data: UploadDoc) {
  const form_data = new FormData()
  form_data.append("file", doc, doc.name)
  const json = {
    "overwrite": data.overwrite,
  }
  const blob = new Blob([JSON.stringify(json)], {
      type: 'application/json'
  })
  form_data.append("json", blob, "json")
  return axios.post(
    UPLOAD_DOC_URL, form_data
  )
}

export async function launchAI() {
  return axios.post(LAUNCH_AI_URL)
}
