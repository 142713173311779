import { FC, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { deleteCompanyDoc } from '../../../../ai/_requests'
import { useDocsList } from '../../../../../pages/dashboard/AIPage'

export interface UploadedDoc {
  filename?: string
  url?: string
  status: string
  updated_at: string
}
function formatDate(str_date: string) {
  let date = new Date(str_date)
  let formatted = date.toLocaleDateString('en-us', { year:'numeric', month:'long', day:'numeric'})
  return formatted
}

const CompanyDocsList: FC = () => {
  const [deleteModals, setDeleteModals] = useState<Array<number>>([])
  const { docs, setDocs } = useDocsList()
  async function deleteDoc(index: number, filename?: string, url?: string) {
    deleteCompanyDoc(filename, url).then((response) => {
      const {vector_uploads: uploads} = response.data
      setDocs(uploads)
      closeModal(index)
    }).catch((error) => {
      console.log(`ERROR: ${error}`)
    })
  }
  function  openModal(modalIndex: number) {
    if (!deleteModals.includes(modalIndex)) {
      let updated = [...deleteModals]
      updated.push(modalIndex)
      setDeleteModals(updated)
    }
  }
  function closeModal(modalIndex: number) {
    const index = deleteModals.indexOf(modalIndex)
    const updated = [
      ...deleteModals.slice(0, index),
      ...deleteModals.slice(index + 1)
    ]
    setDeleteModals(updated)
  }
  return (
    <div className='company-docs-list'>
      <Row className="doc-title-row">
        <Col sm={1} className='doc-title'>TYPE</Col>
        <Col sm={4} className='doc-title'>NAME</Col>
        <Col sm={3} className='doc-title'>UPLOAD DATE</Col>
        <Col sm={1} className='doc-title'>STATUS</Col>
        <Col sm={3} className='doc-title'></Col>
      </Row>
      { docs.map((doc: UploadedDoc, i: number) => {
        return (
          <div key={i}>
            <div className={`company-doc-item ${doc.url ? 'salmon-doc-item' : 'blue-doc-item'}`}>
              <Row className='doc-item-row'>
                <Col sm={1} className='doc-item-col'>
                  <p className={`${doc.filename ? 'blue-text' : 'salmon-text'}`}>
                    {doc.filename ? 'Doc' : 'URL'}
                  </p>
                </Col>
                <Col sm={4} className='doc-item-col'>
                  <p className='doc-item-text'>
                    {doc.filename ? doc.filename : doc.url}
                  </p>
                </Col>
                <Col sm={3} className='doc-item-col'>
                  <p className='doc-item-text'>
                    {formatDate(doc.updated_at)}
                  </p>
                </Col>
                <Col sm={1} className='doc-item-col'>
                  <p className='doc-item-text'>
                    {doc.status.slice(0,1).toUpperCase()}{doc.status.slice(1)}
                  </p>
                </Col>
                <Col sm={3} className='doc-item-col del-col'>
                  <button
                    // type='submit'
                    className={`btn btn-lg btn-primary mt-0 ${doc.filename ? 'btn-blue-light' : 'btn-salmon-light'}`}
                    onClick={() => openModal(i)}
                  >
                    <span className='indicator-label'>
                      <span>Delete</span>
                      {/* { !submitting &&
                        <span>Upload</span>
                      }
                      { submitting &&
                        <img alt='Loading' className='loading-icon' src={toAbsoluteUrl(`/media/gifs/loading.gif`)} />
                      } */}
                    </span>
                  </button>
                </Col>
              </Row>
            </div>
            { deleteModals.includes(i) &&
              <div className='delete-modal'>
                <img src='/media/icons/duotune/arrows/arr061.svg' className='close-icon' onClick={() => closeModal(i)} alt='close'/>
                <h2>This Cannot Be Undone.</h2>
                <p>Are you sure you want to delete <span className='bold-text'>{doc.filename ? doc.filename : doc.url}</span>?</p>
                <button className="btn btn-lg btn-primary btn-salmon" onClick={() => deleteDoc(i, doc.filename, doc.url)}>
                  Permanently Delete
                </button>
              </div>
            }
          </div>
        )
      })

      }
    </div>
  )
}

export { CompanyDocsList }
