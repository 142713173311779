import {FC, useEffect, useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {Link, useParams} from 'react-router-dom'
import {getCompany} from './_requests'
import {getMacros} from '../../modules/macros/_requests'
import {CompanyDetailsModel} from '../../modules/accounts/components/settings/SettingsModel'
import {UserModel} from '../../modules/auth/core/_models'
import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'


const CompanyPage: FC = () => {
  const {companyID} = useParams();
  const [company, setCompany] = useState<CompanyDetailsModel | undefined>(undefined);
  const [team, setTeamMembers] = useState<Array<UserModel>>([]);
  const [macros, setMacros] = useState<Array<string>>([]);
  const [companyErrorMessage, setCompanyErrorMessage] = useState<string | undefined>();
  const [macrosErrorMessage, setMacrosErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    const getCompanyDetails = async () => {
      getCompany(companyID!).then((response) => {
        const {company, team_members: members} = response.data;
        let teamMembers: Array<UserModel> = [];
        for (let i=0; i<members.length; i++) {
          teamMembers.push(new UserModel(members[i]));
        }
        setTeamMembers(teamMembers);
        setCompany(new CompanyDetailsModel(company));
      }).catch((error) => {
        setCompanyErrorMessage('ERROR: ' + error);
      });
      getMacros(companyID).then((response) => {
        const {macros} = response.data;
        setMacros(macros);
      }).catch((error) => {
        setMacrosErrorMessage('ERROR: ' + error);
      });
    };
    getCompanyDetails();
  }, [])
  const phone = (
    company?.phoneNumber?.slice(0,3) + '-' +
    company?.phoneNumber?.slice(3,6) + '-' +
    company?.phoneNumber?.slice(6,10)
  )
  return (
    <div className='admin-company-page'>
      <h1>Company Details</h1>
      <div className='card-body'>
        {companyErrorMessage ? (
          <div className='card-body'>
            <div className='error-message mt-10'>
              {companyErrorMessage}
            </div>
          </div>
        ) : (
          <>
            <Row className='mb-8'>
              <Col>
                <h2>{company?.name}</h2>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>ID:</p>
                  </Col>
                  <Col>
                    <p>{company?.id}</p>
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Website:</p>
                  </Col>
                  <Col>
                    <p>{company?.website}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Phone Number:</p>
                  </Col>
                  <Col>
                    { company?.phoneNumber ? (
                      <a href={`tel:${phone}`}>
                        <p>{phone}</p>
                      </a>
                    ) : (
                      <p>N/A</p>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Timezone:</p>
                  </Col>
                  <Col>
                    <p>{company?.timezone}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Plan:</p>
                  </Col>
                  <Col>
                    <p>{company?.plan?.name}</p>
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Payment Schedule:</p>
                  </Col>
                  <Col>
                    <p>{company?.plan?.schedule[0].toUpperCase()}{company?.plan?.schedule.slice(1)}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Stripe Customer:</p>
                  </Col>
                  <Col>
                    <p>{company?.stripeCustomerID}</p>
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Stripe Subscription:</p>
                  </Col>
                  <Col>
                    <p>{company?.stripeSubscriptionID}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr className='solid'></hr>
            <Row>
              <Col>
                <h3>Social</h3>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Instagram:</p>
                  </Col>
                  <Col>
                    {company?.instagramURL ? (
                      <p>{company?.instagramURL}</p>
                    ) : (
                      <p>N/A</p>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Facebook:</p>
                  </Col>
                  <Col>
                    {company?.facebookURL ? (
                      <p>{company?.facebookURL}</p>
                    ) : (
                      <p>N/A</p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Twitter:</p>
                  </Col>
                  <Col>
                    {company?.twitterURL ? (
                      <p>{company?.twitterURL}</p>
                    ) : (
                      <p>N/A</p>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
              </Col>
            </Row>
            <hr className='solid'></hr>
            <Row>
              <Col>
                <h3>Links</h3>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>FAQ:</p>
                  </Col>
                  <Col>
                    {company?.faqURL ? (
                      <a href={`https://${company.faqURL}`} target='_blank' rel="noreferrer">
                        <p>{company.faqURL}</p>
                      </a>
                    ) :
                      <p>N/A</p>
                    }
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Blog:</p>
                  </Col>
                  <Col>
                    {company?.blogURL ? (
                      <a href={`https://${company.blogURL}`} target='_blank' rel="noreferrer">
                        <p>{company.blogURL}</p>
                      </a>
                    ) :
                      <p>N/A</p>
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Newsletter Signup:</p>
                  </Col>
                  <Col>
                    {company?.newsletterSignupURL ? (
                      <a href={`https://${company.newsletterSignupURL}`} target='_blank' rel="noreferrer">
                        <p>{company.newsletterSignupURL}</p>
                      </a>
                    ) :
                      <p>N/A</p>
                    }
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
              </Col>
            </Row>
            <hr className='solid'></hr>
            <Row>
              <Col>
                <h3>Integrations</h3>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Gorgias:</p>
                  </Col>
                  <Col>
                    {company?.thirdPartyIntegrations?.gorgiasURL ? (
                      <p>{company?.thirdPartyIntegrations?.gorgiasURL}</p>
                    ) : (
                      <p>N/A</p>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Shopify:</p>
                  </Col>
                  <Col>
                    {company?.thirdPartyIntegrations?.shopifyURL ? (
                      <p>{company?.thirdPartyIntegrations?.shopifyURL}</p>
                    ) : (
                      <p>N/A</p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Shipstation:</p>
                  </Col>
                  <Col>
                    {company?.thirdPartyIntegrations?.shipstationURL ? (
                      <p>{company?.thirdPartyIntegrations?.shipstationURL}</p>
                    ) : (
                      <p>N/A</p>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
              </Col>
            </Row>
          </>
        )}
      </div>
      <h2 className='mt-10 mb-8'>Team Members</h2>
      {team.map((member, index) => {
        return (
          <Link to={`/admin/users/${member.id!.toString()}`} key={index}>
            <div className='card-body team-member-item'>
              <Row>
                <Col className='item-column' sm={1}>
                  <p>{member.id}</p>
                </Col>
                <Col  className='item-column'>
                  <p>{member.firstName} {member.lastName}</p>
                </Col>
                <Col  className='item-column'>
                  <p>{member.email}</p>
                </Col>
                <Col  className='item-column'>
                  <p className={`member-owner member-owner-${member.isOwner ? 'owner' : 'member'}`}>{member.isOwner ? 'Owner' : 'Member'}</p>
                </Col>
                <Col className='item-column arrow-column' sm={1}>
                  <img alt='Right Arrow' className='arrow-icon' src={toAbsoluteUrl('/media/icons/custom/gray-right-arrow-rounded.png')} />
                </Col>
              </Row>
            </div>
          </Link>
        )
      })}
      {(macros.length > 0 || macrosErrorMessage) &&
        <h2 className='mt-10 mb-8'>Macros Snippets</h2>
      }
      {macrosErrorMessage ? (
        <div className='card-body'>
          <div className='error-message mt-10'>
            {macrosErrorMessage}
          </div>
        </div>   
      ) : (
        <>
          {macros.map((macro, index) => {
            return (
              <CompanyMacrosSnippet macro={macro} key={index} />
            )
          })}
        </>
      )}
    </div>
  )
}

interface MacroSnippetProps{
  macro: string
}

const CompanyMacrosSnippet: FC<MacroSnippetProps> = (props) => {
  const macro = props.macro;
  const [copied, setCopied] = useState<boolean>(false);
  const brRegex = /<br\s*[\/]?>/gi;
  const copyText = async (macro: string) => {
    const updated_macro = macro.replace(brRegex, '\r\n');
    navigator.clipboard.writeText(updated_macro).then(() => {
      setCopied(true);
      setTimeout(()=> {
        setCopied(false);
      }, 3000);
    })
  }

  return (
    <div className='macros-snippet'>
      {copied ? (
        <p className='copied-text fw-bold'>Copied!</p>
      ) : (
        <img onClick={() => {copyText(macro)}} alt='Copy Text' className='copy-icon' src={toAbsoluteUrl('/media/icons/duotune/general/gen054.svg')} />
      )}
      <p dangerouslySetInnerHTML={{__html: macro}}></p>
    </div>
  )
}

export {CompanyPage, CompanyMacrosSnippet}
