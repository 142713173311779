import axios from 'axios'
import qs from 'qs'
import {RequestInvitation} from '../main/InvitationPage'

const API_URL = process.env.REACT_APP_API_URL
const SEND_INVITATION = `${API_URL}/notifications/invitations/`

export function requestInvitation(values: RequestInvitation) {
  return axios.post(
    SEND_INVITATION, {
      "name": values.name,
      "email": values.email,
      "website": values.website,
      "integrations": qs.stringify(values.integrations),
    },
  )
}
