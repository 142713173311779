/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {createContext, useContext, useState, Dispatch, SetStateAction} from 'react'

import {CardPayment} from './billing/CardPayment';
import {BillingHistory} from './billing/BillingHistory';
import {PlanSelectForm, plans}from '../../billing/components/PlanSelectForm';
import {toAbsoluteUrl} from '../../../../_metronic/helpers';
import {updateSubscriptionPlan} from '../../billing/_requests';

import { useAuth } from '../../auth';

export interface Plan {
  id: string
  price: number
}

type PlanIDContextProps = {
  plan: Plan | undefined
  setPlan: Dispatch<SetStateAction<Plan | undefined>>
  errorMessage: string | undefined
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>
}
const initPlanIDPropsState = {
  plan: undefined,
  setPlan: () => {},
  errorMessage: undefined,
  setErrorMessage: () => {},
}
const PlanIDContext = createContext<PlanIDContextProps>(initPlanIDPropsState)
export function usePlanID() {
  return useContext(PlanIDContext)
}

export function Billing() {
  const [plan, setPlan] = useState<Plan | undefined>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const {currentUser} = useAuth();
  const user = JSON.parse(JSON.stringify(currentUser)).user;
  const currentPlan = user.company.plan;
  const planIDs: Array<string> = [];
  plans.forEach((plan) => [
    planIDs.push(plan.annual_id!, plan.monthly_id!)
  ]);
  // NOTE: some users may be on a legacy plan
  const currentPlanNoLongerAvailable = !planIDs.includes(currentPlan.stripe_id);
  const changePlan = async () => {
    setErrorMessage(undefined);
    setSubmitting(true);
    updateSubscriptionPlan(plan!.id).then(() => {
      // NOTE: hacky... but refreshing page in order to show plan updated
      window.location.reload();
    }).catch((error) => {
      setErrorMessage('ERROR: ' + error);
    })
    setSubmitting(false);
  }

  const formatButtonText = () => {
    if (plan !== undefined) {
      if (currentPlan.stripe_id === plan.id) {
        return 'Current Plan'
      }
      const amount = plan.price - currentPlan.price;
      if (amount < 0) {
        return 'Credit -$' + ((amount + (-2 * amount))).toLocaleString();
      } else {
        return 'Pay $' + amount.toLocaleString();
      }
    }
  }

  return (
    <>
      <div className='dash-billing-page'>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-body p-9'>
            <div className='card-title m-0'>
              {/* <Stack className='title-tab-stack' direction='horizontal'> */}
                {/* <h3 className='fw-bolder m-0'>Payment Methods</h3> */}
                {/* <div className='nav-group bg-white' data-kt-buttons='true'>
                  <a
                    className={
                      'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
                      (currentState === 'card' && 'active')
                    }
                    onClick={() => {
                      setCurrentState('card');
                    }}
                  >
                    Credit / Debit Card
                  </a>
                  <a
                    className={
                      'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
                      (currentState === 'paypal' && 'active')
                    }
                    onClick={() => {
                      setCurrentState('paypal');
                    }}
                  >
                    PayPal
                  </a>
                </div> */}
              {/* </Stack> */}
            </div>
            {/* { currentState === 'card' && ( */}
            <h3 className='fw-bolder m-0 mb-10'>Payment Methods</h3>
            <CardPayment />
            {/* )} */}
            {/* { currentState === 'paypal' && (
              <div className='card-tab'>
                <h4 className='fw-bold m-0'>PayPal</h4>
              </div>
            )} */}
          </div>
        </div>
        { user?.is_owner && !currentPlanNoLongerAvailable &&
          <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-body plan-selector-card'>
            <h3 className='fw-bolder m-0 mb-10'>Change Plan</h3>
            <PlanIDContext.Provider value={{
              plan, setPlan, errorMessage, setErrorMessage
            }}>
              <PlanSelectForm parentPage='billing' />
            </PlanIDContext.Provider>
            <button
                onClick={() => {
                  changePlan();
                }}
                type='submit'
                className='btn btn-lg btn-primary mt-8'
                disabled={currentPlan.stripe_id === plan?.id || submitting}
              >
                <span className='indicator-label'>
                  { !submitting && plan !== undefined && currentPlan.stripe_id !== plan!.id &&
                    <span className='indicator-label'>{formatButtonText()}</span>
                  }
                  { submitting &&
                    <img alt='Loading' className='loading-icon' src={toAbsoluteUrl(`/media/gifs/loading.gif`)} />
                  }
                  {
                    plan !== undefined && currentPlan.stripe_id === plan!.id &&
                    <span className='indicator-label'>Current Plan</span>
                  }
                </span>
              </button>
              <div className='error-message mt-10'>
                {errorMessage}
              </div>
            </div>
          </div>
        }
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-body p-9'>
            <h3 className='fw-bolder m-0 mb-10'>Billing History</h3>
            <BillingHistory />
          </div>
        </div>
      </div>
    </>
  )
}
