import axios from 'axios'
import {IInviteUser} from './TeamPage';

const API_URL = process.env.REACT_APP_API_URL
const TEAM_MEMBERS_URL = `${API_URL}/companies/team-members/`
const SEND_TEAM_MEMBER_INVITATION_URL = `${API_URL}/notifications/send-member-invitation/`
const SEND_CANCEL_TEAM_MEMBER_INVITATION_URL = `${API_URL}/member-invitations/`
const DISMISS_ONBOARDING_ITEM_URL = `${API_URL}/dismiss-onboarding-items/`
const FINALIZE_GORGIAS_AUTH_URL = `${API_URL}/gorgias/auth/`

export async function getTeamMembers() {
  return axios.get(TEAM_MEMBERS_URL)
}

export async function sendTeamMemberInvitation(params: IInviteUser) {
  return axios.post(
    SEND_TEAM_MEMBER_INVITATION_URL,
    {
      'first_name': params.firstName,
      'last_name': params.lastName,
      'email': params.email,
    }
  )
}

export async function sendOrCancelTeamMemberInvitation(id: number, cancel: boolean) {
  return axios.put(
    SEND_CANCEL_TEAM_MEMBER_INVITATION_URL + `${id}/`, {'cancel': cancel}
  )
}

export async function dismissOnboardingItem(item: string) {
  return axios.post(
    DISMISS_ONBOARDING_ITEM_URL, {item}
  )
}

export async function finalizeGorgiasAuth(code: string) {
  return axios.post(
    FINALIZE_GORGIAS_AUTH_URL, {
      "code": code,
    }
  )
}
