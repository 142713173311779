import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {MacrosSnippetsPage} from '../../modules/macros/MacrosSnippetsPage'

const MacrosSnippetsPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MACROSSNIPPETSPAGE'})}</PageTitle>
      <MacrosSnippetsPage />
    </>
  )
}

export {MacrosSnippetsPageWrapper}
