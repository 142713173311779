import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {AIPage} from './AIPage'

const AIPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.AIPAGE'})}</PageTitle>
      <AIPage />
    </>
  )
}

export {AIPageWrapper}
