import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users/verify-token/`
export const LOGIN_URL = `${API_URL}/users/login/`
export const GOOGLE_LOGIN_URL = `${API_URL}/users/google-login/`
export const GOOGLE_REGISTRATION_URL = `${API_URL}/users/google-register/`
export const REGISTER_URL = `${API_URL}/users/register/`
export const REQUEST_RESET_CODE_URL = `${API_URL}/users/forgot-password/`
export const RESET_PASSWORD_URL = `${API_URL}/users/reset-password/`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}
// Server should return AuthModel
export function google_login(google_token: string) {
  return axios.post<AuthModel>(GOOGLE_LOGIN_URL, {
    google_token
  })
}
// Server should return AuthModel
export function google_registration(google_token: string) {
  return axios.post<AuthModel>(GOOGLE_REGISTRATION_URL, {
    google_token
  })
}

// Server should return AuthModel
export function register(
  email: string,
  password: string,
  password_confirmation: string,
  firstname?: string | undefined,
  lastname?: string | undefined,
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function requestResetCode(email: string) {
  return axios.post(REQUEST_RESET_CODE_URL, {
    email,
  })
}

export function resetPassword(
  resetCode: string, password: string, confirmPassword: string, email: string) {
  return axios.post(RESET_PASSWORD_URL, {
    "reset_code": resetCode,
    "new_password": password,
    "confirm_password": confirmPassword,
    "email": email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
