import {FC} from 'react'
import {Row, Col, Accordion, Button, Container} from 'react-bootstrap'

const FAQMini: FC = () => (
  <>
     <div className='faq-section'>
        <Container fluid className="p-0">
          <Row>
            <Col lg={12}>
              <h2 className='section-title'>Frequently asked questions</h2>
              <Accordion flush>
              <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <p  className='question-text'>
                      Where are Dedication Agents based?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      Dedication Agents are home-based!
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <p  className='question-text'>
                      I want to have a 24/7 team, how much will that cost?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      You can have it as an add-on. You may refer to our
                      <a href='/pricing' className='inline-link'> pricing page </a>
                      for more information on your plan and other add-ons.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <p  className='question-text'>
                      I already have an existing CS team and only want to use Dedication Agents 
                      for level 1 tickets.
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      We can definitely work on your T1 tickets and escalate all other tickets that need your attention.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <p className='question-text'>
                      What if I want Dedication Agents to assimilate to my team?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      That's perfectly fine! If you need extra hands to help out with CS, we can make that happen.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <p className='question-text'>
                      Can Dedication Agents take calls?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className='answer-text'>
                      Not yet! This feature will be available in the future, however, as of now, we only support chat.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <a href='/faq'>View All</a>
            </Col>
          </Row>
        </Container>
      </div>
  </>
)

export {FAQMini}
