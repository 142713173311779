import {FC} from 'react'
import {NavigationBar} from './components/NavigationBar'
import {Row, Col, Accordion, Button, Container, Stack} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { Footer } from './components/Footer'

const WhatsIncludedPage: FC = () => (
  <>
    <div className='custom-page whats-included-page'>
      <div className='above-fold'>
        <NavigationBar />
        <Container fluid className="p-0">
          <Row>
            <Col>
              <h1>What's Included</h1>
              <p>On-demand customer service support that is proficient in e-commerce
                and certified in Gorgias. Message directly with our chat agents 
                that will be answering custom concerns over multiple channels: email, 
                social media, and live chat.
              </p>
              <Button href='/login/registration'>GET STARTED</Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='out-the-box-section'>
        <Container fluid className="p-0">
          <Row>
            <Col lg={5} md={12} className='left-col'>
              <h2>Good customer service that’s ready to go out the box</h2>
            </Col>
            <Col lg={6} md={12} className='right-col'>
              <p>Working on customer service is more important than working for customer service. 
                Good CS can bring a loyal customer base and boost revenue - but not if it's hard, 
                time-consuming, and expensive to maintain.
              </p>
              <p>
                If you have the ability to scale your customer service without having to hire, train, 
                and manage a CS team, you will gain a competitive edge over other companies who don’t 
                have such capabilities.
              </p>
              <p>
                Dedication Agents are ready to go as soon as you're onboarded. They can help 
                with connecting the agent to a customer, and will work on building your 
                business with enthusiasm.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='synchronize-all-section'>
        <Container fluid className="p-0">
          <Row>
            <Col lg={6} md={12} className='left-col'>
            </Col>
            <Col lg={6} md={12} className='right-col'>
              <h2>Synchronizing all user messaging</h2>
              <p className='subtitle'>
                Our team reviews your FAQ, terms and conditions, and e-commerce preferences 
                to make sure that messaging is consistent across the board. This process 
                ensures that all policies are communicated consistently on all 
                communication channels.
              </p>
              <Button href='/login/registration'>GET STARTED</Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='streamline-section'>
        <Container fluid className="p-0">
          <Row>
            <Col lg={6} md={12} className='left-col'>
              <h2>Streamlining Gorgias ticket management</h2>
              <p className='subtitle'>
                Communication is key to good customer support. Dedication Agents 
                provide immediate support to answer customer concerns across 
                multiple communication channels.
              </p>
              <Button href='/login/registration'>GET STARTED</Button>
            </Col>
            <Col lg={6} md={12} className='right-col'>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='daily-updates-section'>
        <Container fluid className="p-0">
          <Row>
            <Col lg={12}>
              <h2>Providing daily updates</h2>
              <p className='subtext'>
                Managing customer service, without having to become an agent yourself, is key. 
                We will provide you with daily updates to ensure no key issues are slowing 
                down user responses.
              </p>
            </Col>
          </Row>
          <Row className='icon-text-row'>
            <Col lg={3} md={6} sm={12}>
              <img alt='Chat Bubbles Icon' className='tickets-icon' src={toAbsoluteUrl('/media/icons/custom/tickets-icon.png')} />
              <h4>Ticket volume trends</h4>
              <p>Make sure your tickets aren't piling up by monitoring the essentials: 
                close rate, ticket volume, key issues
              </p>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <img alt='Upwards Icon' className='key-icon' src={toAbsoluteUrl('/media/icons/custom/key-icon.png')} />
              <h4>Key issues</h4>
              <p>Knowing what types of tickets and macros are causing the most volume is key</p>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <img alt='Conversation Icon' className='question-icon' src={toAbsoluteUrl('/media/icons/custom/question-icon.png')} />
              <h4>Offroad questions</h4>
              <p>Not all questions require macros. We'll provide a list of these and potential 
                responses for approval</p>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <img alt='Conversation Icon' className='question-icon' src={toAbsoluteUrl('/media/icons/custom/pointer-up-icon.png')} />
              <h4>Level of involvement</h4>
              <p>Customize when tickets should be routed to you or have Dedication Agents 
                completely handle it for you
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  </>
)

export {WhatsIncludedPage}
