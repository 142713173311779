import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {WhatsIncludedPage} from '../../modules/main/WhatsIncludedPage'

const WhatsIncludedPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.WHATSINCLUDEDPAGE'})}</PageTitle>
      <WhatsIncludedPage />
    </>
  )
}

export {WhatsIncludedPageWrapper}
