import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {PricingPage} from '../../modules/main/PricingPage'

const PricingPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PRICINGPAGE'})}</PageTitle>
      <PricingPage />
    </>
  )
}

export {PricingPageWrapper}
