import React, {useState} from 'react'
import {CompanyDetailsModel} from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { Stack } from 'react-bootstrap'

import {useAuth} from '../../../../../../app/modules/auth'
import {updateCompany} from '../../../_requests'

const companyDetailsSchema = Yup.object().shape({
  phoneNumber: Yup.string().required('Company phone is required'),
  website: Yup.string().required('Company website is required'),
  timezone: Yup.string().required('Timezone is required'),
  twitterURL: Yup.string(),
  instagramURL: Yup.string(),
  facebookURL: Yup.string(),
  faqURL: Yup.string(),
  blogURL: Yup.string(),
  newsletterSignupURL: Yup.string(),
})

const CompanyDetails: React.FC = () => {
  const {currentUser} = useAuth();
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  // TODO: keep searching for a better way to do this
  const company = JSON.parse(JSON.stringify(currentUser))?.user.company;
  const user = JSON.parse(JSON.stringify(currentUser)).user;
  const initialValues: CompanyDetailsModel = {
    name: company.name,
    // TODO: format this phone number - also check how it's being added during onboarding
    phoneNumber: company.phone_number,
    website: company.website,
    timezone: company.timezone,
    twitterURL: company.twitter_url  || '',
    instagramURL: company.instagram_url || '',
    facebookURL: company.facebook_url || '',
    faqURL: company.faq_url || '',
    blogURL: company.blog_url || '',
    newsletterSignupURL: company.newsletter_signup_url || '',
  }
  const [loading, setLoading] = useState(false)
  const [editable, setEditable] = useState(false)
  const formik = useFormik<CompanyDetailsModel>({
    initialValues,
    validationSchema: companyDetailsSchema,
    onSubmit: async (values) => {
      setErrorMsg(undefined)
      if (editable) {
        setLoading(true)
        updateCompany(company.id, values).then((response) => {
            setEditable(false);
            // TODO: easier for now. would need to pull provider out higher in the tree
            document.location.reload();
        }).catch((error) => {
            setErrorMsg("Error: " + error)
        });
        setLoading(false)
      } else {
        setEditable(true)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Company Details</h3>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Company Phone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg'
                  placeholder='Phone number'
                  {...formik.getFieldProps('phoneNumber')}
                  disabled={!editable}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phoneNumber}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Company URL</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='Company URL'
                  {...formik.getFieldProps('website')}
                  disabled={!editable}
                />
                {formik.touched.website && formik.errors.website && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.website}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Time Zone</label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('timezone')}
                  disabled={!editable}
                >
                  <option></option>
                  <option value='PT -08:00'>PT -08:00</option>
                  <option value='MT -07:00'>MT -07:00</option>
                  <option value='CT -06:00'>CT -06:00</option>
                  <option value='EST -05:00'>EST -05:00</option>
                </select>
                {formik.touched.timezone && formik.errors.timezone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.timezone}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                Twitter URL
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='Twitter URL'
                  {...formik.getFieldProps('twitterURL')}
                  disabled={!editable}
                />
                {formik.touched.twitterURL && formik.errors.twitterURL && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.twitterURL}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                Instagram URL
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='Instagram URL'
                  {...formik.getFieldProps('instagramURL')}
                  disabled={!editable}
                />
                {formik.touched.instagramURL && formik.errors.instagramURL && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.instagramURL}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                Facebook URL
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='Facebook URL'
                  {...formik.getFieldProps('facebookURL')}
                  disabled={!editable}
                />
                {formik.touched.facebookURL && formik.errors.facebookURL && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.facebookURL}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                FAQ URL
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='FAQ URL'
                  {...formik.getFieldProps('faqURL')}
                  disabled={!editable}
                />
                {formik.touched.faqURL && formik.errors.faqURL && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.faqURL}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                Blog URL
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='Blog URL'
                  {...formik.getFieldProps('blogURL')}
                  disabled={!editable}
                />
                {formik.touched.blogURL && formik.errors.blogURL && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.blogURL}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                Newsletter Signup URL
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='Newsletter Signup URL'
                  {...formik.getFieldProps('newsletterSignupURL')}
                  disabled={!editable}
                />
                {formik.touched.newsletterSignupURL && formik.errors.newsletterSignupURL && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.newsletterSignupURL}</div>
                  </div>
                )}
              </div>
            </div>
            <p className="error-message">
              {errorMsg}
            </p>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <Stack direction='horizontal' gap={5}>
                { editable && <button
                  type='button'
                  onClick={() => {
                    setEditable(false)
                  }}
                  className='btn btn-color-gray-400 btn-active-light-primary px-6'
                >
                  Cancel
                </button> }
                { user.is_owner &&
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && !editable && 'Edit'}
                  {!loading && editable && 'Save Changes'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button> }
              </Stack>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export {CompanyDetails}
