import {ChangeEvent, FC, useEffect, useState} from 'react'
import {useEditable} from '../MacrosSettingsPage'

interface IMacroQuestionProps{
    index: number
    question: Question
    answer: Answer | null
    conditionalQuestions: Array<ConditionalQuestion>
    conditionalAnswers: Array<Answer>
    optionResponses: Array<OptionResponse>
}

export interface ConditionalQuestion {
  id: number
  text: string
  options: Array<Option>
  select_multiple: boolean
}

interface Option {
  id: number
  text: string
  other: string
  // TODO: these shouldn't be snake case... but changing them makes code fail. need to figure out why
  child_conditional_question_id: number
  parent_conditional_question_id: number
  question_id: number
}

export interface OptionMinimal {
  id: number
  other?: string
}

export interface OptionResponse {
  option_id: number
  text: string
}

export interface Question {
  id: number
  text: string
  options: Array<Option>
  select_multiple: boolean
}

export interface Answer {
  id: number
  question_id?: number
  conditional_question_id?: number
  options: Array<OptionMinimal>
}

const MacrosQuestionComponent: FC<IMacroQuestionProps> = (props) => {
  const [visibleConditionals, setVisibleConditionals] = useState<Array<string>>([]);
  const [currentlySelectedOptions, setcurrentlySelectedOptions] = useState<Array<OptionMinimal>>([]);
  const {editable} = useEditable();
  // NOTE: other options basically stop all possible conditional nesting, so just need to keep track of 1
  // TODO: I think this can just be a boolean. Will take a look at it later
  const [visibleOtherOptions, setVisibleOtherOptions] = useState<Array<string>>([]);
  let nestedCount = 0;
  let nestedConditionals: Array<any> = [];
  const conditionalAnswers = props.conditionalAnswers;
  const optionResponses = props.optionResponses;
  useEffect(() => {
    const selectedOptions = props.answer?.options;
    let visibleConditionalsTemp = [...visibleConditionals];
    for (let i=0; i<conditionalAnswers.length; i++){
      const id = conditionalAnswers[i].conditional_question_id!.toString();
      visibleConditionalsTemp.push(id);
    }
    setVisibleConditionals(visibleConditionalsTemp);
    if (selectedOptions) {
      // TODO: should I remove other options here?
      setcurrentlySelectedOptions(selectedOptions)
      let visibleOtherOptionTemp = [];
      for (let i=0; i<optionResponses.length; i++) {
        visibleOtherOptionTemp.push(optionResponses[i].option_id.toString())
      }
      setVisibleOtherOptions(visibleOtherOptionTemp);
    }
  }, []);
  
  function _onSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    let questionID: string;
    let parentConditionalQuestionID: string;
    let optionID: string;
    let conditionalID: string;
    let layer: string;
    let otherFieldCount: string;
    let other: string | undefined;
    const valueArray = event.target.value.split(';');
    questionID = valueArray[0];
    parentConditionalQuestionID = valueArray[1]
    optionID = valueArray[2];
    conditionalID = valueArray[3];
    layer = valueArray[4];
    otherFieldCount = valueArray[5];
    if (valueArray.length > 6) {
      other = valueArray[6];
    }
    if (other !== undefined) {
      setVisibleOtherOptions([optionID!]);
    } else {
      setVisibleOtherOptions([])
      // TODO: see if there's a way to actually get null here rather than a string
      if (conditionalID !== 'null') {
        let visibleConditionalsTemp = [...visibleConditionals];
        visibleConditionalsTemp.push(conditionalID);
        setVisibleConditionals(visibleConditionalsTemp);
      } else {
        let conditionalIDsToRemove: Array<string> = [];
        for (let i=0; i<nestedConditionals.length; i++) {
          if (nestedConditionals[i][1] >= layer) {
            conditionalIDsToRemove.push(nestedConditionals[i][0].toString())
          }
        }
        const visibleConditionalsTemp = [...visibleConditionals];
        for (let i=0; i< conditionalIDsToRemove.length; i++) {
          // Remove from visibility
          const index = visibleConditionalsTemp.indexOf(conditionalIDsToRemove[i]);
          if (index > -1) {
            visibleConditionalsTemp.splice(index, 1);
          }
          // Set value to default
          const selector = document.getElementById(`selector-${conditionalIDsToRemove[i]}`)  as HTMLSelectElement;
          selector.selectedIndex = 0;
        }
        setVisibleConditionals(visibleConditionalsTemp);
      }
    }
  }
  function _onMultiSelectChange(event: ChangeEvent<HTMLInputElement>) {
    const valueArray = event.target.value.split(';');
    const selectedOption = valueArray[2];
    let updatedSelectedOptions = false;
    let currentlySelectedOptionsTemp = [...currentlySelectedOptions]
    for (let i=0; i<currentlySelectedOptions.length; i++) {
      if (selectedOption === currentlySelectedOptions[i].id.toString()) {
        // remove from currently selected
        currentlySelectedOptionsTemp.splice(i, 1)
        setcurrentlySelectedOptions(currentlySelectedOptionsTemp);
        updatedSelectedOptions = true;
      }
    }
    // if nothing was removed, add instead
    if (!updatedSelectedOptions) {
      currentlySelectedOptionsTemp.push({'id': parseInt(selectedOption, 10)})
      setcurrentlySelectedOptions(currentlySelectedOptionsTemp);
    }
  }
  function _recursionHelper(options: Array<Option>): any {
    for (let i=0; i<options.length; i++) {
      if (options[i].child_conditional_question_id !== null) {
        const conditionalQuestion = props.conditionalQuestions.find((x) => x.id === options[i].child_conditional_question_id)
        const selectedOptions = props.answer?.options;
        if (conditionalQuestion) {
          return (
            <div className={`${visibleConditionals.find((x) => x === conditionalQuestion.id.toString()) ? '' : 'hidden'}`}>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                {conditionalQuestion.text}
                {conditionalQuestion.select_multiple && 
                  <p className='select-all-text'>(Select All That Apply)</p>
                }
              </label>
              {_createOptionSelector(conditionalQuestion.options, conditionalQuestion.select_multiple, conditionalQuestion)}
            </div>
          )
        }
      }
    }
  }
  function _createOptionSelector(
    options: Array<Option>,
    multipleSelections: boolean = false,
    conditionalQuestion?: ConditionalQuestion
    ) {
    const conditionalQuestionID = conditionalQuestion?.id;
    const conditionalAnswer: Answer | undefined = conditionalAnswers.find((x) => x.conditional_question_id === conditionalQuestionID)
    const selectedConditionalOptionID: number | undefined = conditionalAnswer?.options[0].id;

    nestedCount += 1;
    let otherFieldCount = 0;
    for (let i=0; i<options.length; i++) {
      const other = options[i].other;
      if (other !== null) {
        if (other === 'timeframe') {
          otherFieldCount = 2;
        } else {
          otherFieldCount = 1;
        }
      }
    }
    return (
      <>
        {multipleSelections &&
          <>
            {options.map((option, i) => {
              const other = option.other !== null ? option.other : null;
              let isSelected: boolean = false;
              if (currentlySelectedOptions.length > 0) {
                for (let i=0; i<currentlySelectedOptions.length; i++) {
                  if (currentlySelectedOptions[i].id === option.id) {
                    isSelected = true;
                  }
                }
              }
              
              return (
                <div className='multiselect-option-container'  key={i}>
                  <input disabled={!editable} type="checkbox" id={option.question_id + "_" + i.toString()} name={i.toString()}
                    checked={isSelected}
                    onChange={_onMultiSelectChange}
                    value={
                    `${
                      option.question_id + ';' +
                      option.parent_conditional_question_id + ';' +
                      option.id + ';' +
                      (option.child_conditional_question_id !== null ?
                        option.child_conditional_question_id + ';' + nestedCount + ';' +
                        otherFieldCount : (
                          other !== null ?
                          null + ';' + nestedCount + ';' + otherFieldCount + ';' + other :
                          null + ';' + nestedCount + ';' + otherFieldCount
                        )
                      )
                    }`
                  }></input>
                  <label className='multiselect-container'>{option.text}</label>
              </div>
            )
          })}
          </>
        }
        {!multipleSelections &&
          <select
            className={`form-select form-select-solid form-select-lg layer-${nestedCount}`}
            onChange={_onSelectChange}
            id={conditionalQuestionID !== undefined ? `selector-${conditionalQuestionID}` : ''}
            disabled={!editable}
          >
            <option value={`null;null;null;null;${nestedCount};${otherFieldCount}`}></option>
            { options.map((option, i) => {
              if (option.child_conditional_question_id !== null) {
                nestedConditionals.push([option.child_conditional_question_id, nestedCount])
              }
              const other = option.other !== null ? option.other : null;
              let isSelected: boolean = false;
              if (currentlySelectedOptions !== undefined) {
                for (let i=0; i<currentlySelectedOptions.length; i++) {
                  if (currentlySelectedOptions[i].id === option.id) {
                    isSelected = true;
                  }
                }
              }
              if (option.id === selectedConditionalOptionID) {
                isSelected = true;
              }
              return (
                // TODO: maybe there is a better way to do this... don't have time right now to figure it out
                <option
                  key={i}
                  selected={isSelected}
                  value={
                  `${
                    option.question_id + ';' +
                    option.parent_conditional_question_id + ';' +
                    option.id + ';' +
                    (option.child_conditional_question_id !== null ?
                      option.child_conditional_question_id + ';' + nestedCount + ';' +
                      otherFieldCount : (
                        other !== null ?
                        null + ';' + nestedCount + ';' + otherFieldCount + ';' + other :
                        null + ';' + nestedCount + ';' + otherFieldCount
                      )
                    )
                  }`
                }>
                    {option.text}
                </option>
              )
            })}
          </select>
        }
        {/* Now render 'other' option inputs */}
        { options.map((option, i) => {
          if (option.other !== null) {
            // TODO: maybe make an enum for these diff options
            if (option.other === 'text') {
              let defaultValue;
              for (let i=0; i<optionResponses.length; i++) {
                if (optionResponses[i].option_id === option.id) {
                  defaultValue = optionResponses[i].text;
                }
              }
              return (
                <div key={i}
                  className={`${(visibleOtherOptions.find((x) => x === option.id.toString())) ? '' : 'hidden'}`}
                >
                  <input
                    type='text'
                    className='other-input'
                    defaultValue={defaultValue}
                    disabled={!editable}
                  >
                  </input>
                </div>
              )
            } else if (option.other === 'timeframe') {
              const defaultValues = optionResponses.find((x) => x.option_id === option.id)?.text.split(' ');
              let firstDefaultValue = null;
              let secondDefaultValue = null;
              if (defaultValues) {
                firstDefaultValue = defaultValues[0];
                secondDefaultValue = defaultValues[1];
              }
              return (
                <div key={i}
                  className={`timeframe-container ${(visibleOtherOptions.find((x) => x === option.id.toString())) ? '' : 'hidden'}`}
                >
                  <select id='timeframe-amount' name='timeframe-amount' disabled={!editable}
                    defaultValue={firstDefaultValue || ''}>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5'>5</option>
                    <option value='6'>6</option>
                    <option value='7'>7</option>
                    <option value='8'>8</option>
                    <option value='9'>9</option>
                    <option value='10'>10</option>
                    <option value='11'>11</option>
                    <option value='12'>12</option>
                    <option value='13'>13</option>
                    <option value='14'>14</option>
                    <option value='15'>15</option>
                    <option value='16'>16</option>
                    <option value='17'>17</option>
                    <option value='18'>18</option>
                    <option value='19'>19</option>
                    <option value='20'>20</option>
                    <option value='21'>21</option>
                    <option value='22'>22</option>
                    <option value='23'>23</option>
                    <option value='24'>24</option>
                    <option value='25'>25</option>
                    <option value='26'>26</option>
                    <option value='27'>27</option>
                    <option value='28'>28</option>
                    <option value='29'>29</option>
                    <option value='30'>30</option>
                  </select>
                  <select id='timeframe-period' name='timeframe-period' disabled={!editable}
                    defaultValue={secondDefaultValue || ''}>
                    <option value='hours'>hour(s)</option>
                    <option value='days'>day(s)</option>
                    <option value='weeks'>week(s)</option>
                    <option value='months'>month(s)</option>
                    <option value='years'>year(s)</option>
                  </select>
                </div>
              )
            }
          }
        })}
        {_recursionHelper(options)}
      </>
    )
    // )
  }

  return (
    <div className='question-container' key={props.index}>            
      <div className='question-container-inner'>
        <label className='col-lg-4 col-form-label fw-bold fs-6 question'>
          {props.question.text}
          {props.question.select_multiple &&
            <p className='select-all-text'>(Select All That Apply)</p>
          }
        </label>
        <div className='col-lg-8 fv-row'>
          {_createOptionSelector(props.question.options, props.question.select_multiple)}
        </div>
      </div>
    </div>
  )
}

export {MacrosQuestionComponent}
