import { FC, useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom';
import { finalizeGorgiasAuth } from './_requests';
import { useAuth } from '../../modules/auth/core/Auth';

import {toAbsoluteUrl} from '../../../_metronic/helpers';

import {TicketCountWidget} from '../../../_metronic/partials/widgets';
import {updateThirdPartyIntegrations} from '../../modules/accounts/_requests';


const HomePage: FC = () => {
  // TODO: maybe move these to a helper file somewhere
  function dec2hex (dec: number) {
    return dec.toString(16).padStart(2, "0")
  }
  function randomString(len: number) {
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
  }
  const [searchParams, _] = useSearchParams();
  const {currentUser, setCurrentUser} = useAuth();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  // TODO: i think it requires this because UserModel is wrong -> need to update
  const user = JSON.parse(JSON.stringify(currentUser)).user;
  useEffect(() => {
    const gorgiasIntegration = async () => {
      const gorgiasCode = searchParams.get("code");
      const gorgiasOAuthState = searchParams.get("state");
      if (gorgiasOAuthState && gorgiasCode) {
        // check if state is the same
        if (gorgiasOAuthState !== localStorage.getItem('gorgias_oauth_state')) {
          throw "States don't match";
        } else {
          // finish on serverside
          setSubmitting(true);
          finalizeGorgiasAuth(gorgiasCode).then((response) => {
            // TODO: fix this as well at some point
            const {data: updatedUser} = response;
            setCurrentUser(updatedUser)
            setSubmitting(false)
          });
        }
      }
    };
    gorgiasIntegration().catch((error) => {
      setErrorMessage(error);
    });
  }, [])
  const clientID = process.env.REACT_APP_GORGIAS_CLIENT_ID;
  const scope = 'account:read integrations:read integrations:write tickets:read tickets:write users:read users:write statistics:read tags:read tags:write';
  const nonce = randomString(10);
  const redirectURI = process.env.REACT_APP_GORGIAS_REDIRECT_URI;
  return (
    <div className='custom-page temp-dash-page d-flex'>
      { !user.company.gorgias_integrated &&
        <Container fluid className="p-0 align-self-center mt-n20">
          <Row className='flex-column-reverse flex-md-row'>
            <Col className='left-col mx-auto' md={12} lg={5}>
              <h2 className='mb-10 text-center'>Connect Your Gorgias Account</h2>
              { !user.company.third_party_integrations.gorgias_url &&
                <input className='form-control form-control-solid mb-10' id='GorgiasURLInput' type='text' placeholder='https://your-subdomain.gorgias.com'></input>
              }
              <button
                type='submit'
                className='btn btn-lg btn-primary mx-auto mt-0'
                onClick={async ()=>{
                  setSubmitting(true);
                  let gorgiasURL = user.company.third_party_integrations.gorgias_url;
                  if (!gorgiasURL) {
                    gorgiasURL = (document.getElementById('GorgiasURLInput') as HTMLInputElement).value;
                    if (!gorgiasURL) {
                      setErrorMessage('Gorgias URL is required.')
                      return setSubmitting(false);
                    } else {
                      // save Gorgias URL
                      updateThirdPartyIntegrations({'gorgiasURL': gorgiasURL}).catch((error) => {
                        setErrorMessage(error);
                      })
                    }
                  }
                  const CSRFState = randomString(20);
                  // save state and check on redirect back to dashboard (prevents CSRF attacks)
                  localStorage.setItem('gorgias_oauth_state', CSRFState)
                  // Gorgias preview for when Gorgias tests updated app version
                  const gorgiasPreview = process.env.REACT_APP_GORGIAS_VERSION_PREVIEW
                  window.location.href = (
                    `${gorgiasURL}/oauth/authorize?response_type=code&client_id=${clientID}&state=${CSRFState}&scope=${scope}&nonce=${nonce}&redirect_uri=${redirectURI}&preview=${gorgiasPreview}`
                  );
                }}
              >
                <span className='indicator-label'>
                  { !submitting &&
                    <span className='indicator-label'>Connect Gorgias</span>
                  }
                  { submitting &&
                    <img alt='Loading' className='loading-icon' src={toAbsoluteUrl(`/media/gifs/loading.gif`)} />
                  }
                </span>
              </button>
              {errorMessage &&
                <div className='error-message mt-10'>
                  {errorMessage}
                </div>
              }
            </Col>
          </Row>
        </Container>
      }
      { user.company.gorgias_integrated &&
        <Container fluid className="p-0">
          <Row className='flex-column-reverse flex-md-row'>
            <Col className='left-col' md={12}>
              <TicketCountWidget />
            </Col>
          </Row>
        </Container>
      }
    </div>
  )
}

export {HomePage}
