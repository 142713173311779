/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {AdminRoutes} from './AdminRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {HomePageWrapper} from '../pages/main-site/HomePageWrapper'
import {WhatWeDoPageWrapper} from '../pages/main-site/WhatWeDoPageWrapper'
import {WhoWeArePageWrapper} from '../pages/main-site/WhoWeArePageWrapper'
import { PrivacyPolicyPageWrapper } from '../pages/main-site/policies/PrivacyPolicyPageWrapper'
import {App} from '../App'

import { CompanySetupPage } from '../modules/auth/CompanySetupPage'
import { InvitationPageWrapper } from '../pages/main-site/InvitationPageWrapper'
import { WhatsIncludedPageWrapper } from '../pages/main-site/WhatsIncludedPageWrapper'
import { PricingPageWrapper } from '../pages/main-site/PricingPageWrapper'
import { ThankYouPageWrapper } from '../pages/main-site/ThankYouPageWrapper'
import { FAQPageWrapper } from '../pages/main-site/FAQPageWrapper'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  let user = undefined;
  if (currentUser) {
    // So ugly... but not sure how else to go about it
    // https://stackoverflow.com/questions/17546953/cant-access-object-property-even-though-it-shows-up-in-a-console-log
    user = JSON.parse(JSON.stringify(currentUser)).user;
  }
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          {user ? (
            user?.is_agent ? (
              <>
                <Route path='*' element={<AdminRoutes />} />
                <Route path='/login/*' element={<Navigate to='/admin/companies' />} />
              </>
            ) : (
              user?.company?.name ? (
                <>
                  <Route path='company-setup/*' element={<Navigate to='/dashboard' />} />
                  <Route path='*' element={<PrivateRoutes />} />
                  {/* redirect login to dashboard */}
                  <Route path='/login/*' element={<Navigate to='/dashboard' />} />
                </>
              ) : (
                <>
                  <Route path='company-setup/*' element={<CompanySetupPage />} />
                </>
              )
            )
          ) : (<></>)}
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='/' element={<HomePageWrapper />} />
          <Route path='invitation' element={<InvitationPageWrapper />} />
          <Route path='what-we-do' element={<WhatWeDoPageWrapper />} />
          <Route path='whats-included' element={<WhatsIncludedPageWrapper />} />
          <Route path='who-we-are' element={<WhoWeArePageWrapper />} />
          <Route path='pricing' element={<PricingPageWrapper />} />
          <Route path='thank-you' element={<ThankYouPageWrapper />} />
          <Route path='faq' element={<FAQPageWrapper />} />
          <Route path='login/*' element={<AuthPage />} />
          {/* Policies */}
          <Route path='policies/privacy-policy' element={<PrivacyPolicyPageWrapper />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
