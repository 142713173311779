import {
  createContext, lazy, FC, Suspense, Dispatch,
  SetStateAction, useContext, useEffect, useState
} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {MacrosSettingsPageWrapper} from '../pages/macros/MacrosSettingsPageWrapper'
import {MacrosSnippetsPageWrapper} from '../pages/macros/MacrosSnippetsPageWrapper'
import { TeamPageWrapper } from '../pages/dashboard/TeamPageWrapper'
import { AIPageWrapper } from '../pages/dashboard/AIPageWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { useAuth } from '../../app/modules/auth'
import {getOnboardingProgress} from '../../app/modules/accounts/_requests'

export interface IOnboardingDots {
  accountSettings: boolean
  macrosSettings: boolean
}

type OnboardingDotsContextProps = {
  onboardingDots: IOnboardingDots | undefined
  setOnboardingDots: Dispatch<SetStateAction<IOnboardingDots | undefined>>
}

const initOnboardingDotsPropsState = {
  onboardingDots: undefined,
  setOnboardingDots: () => {},
}

const OnboardingDotsContext = createContext<OnboardingDotsContextProps>(initOnboardingDotsPropsState)

function useOnboardingDots() {
  return useContext(OnboardingDotsContext)
}

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const [onboardingDots, setOnboardingDots] = useState<IOnboardingDots | undefined>(undefined);
  const {currentUser} = useAuth()
  // TODO: this is such a mess... really need to clean this up
  let user = undefined;
  if (currentUser) {
    // So ugly... but not sure how else to go about it
    // https://stackoverflow.com/questions/17546953/cant-access-object-property-even-though-it-shows-up-in-a-console-log
    user = JSON.parse(JSON.stringify(currentUser)).user
  }

  useEffect(() => {
    const getOnboardingProgressDots = async () => {
      getOnboardingProgress().then((response) => {
        const {onboarding_dots: dots} = response.data;
        // NOTE: reformatting to keep camel case
        const formattedDots = {
          'accountSettings': dots.account_settings,
          'macrosSettings': dots.macros_settings,
        }
        setOnboardingDots(formattedDots);
      })
    };
    getOnboardingProgressDots().catch((error) => {
      console.log('ERROR: ' + error);
    });
  }, [])

  return (
    <OnboardingDotsContext.Provider value={{onboardingDots, setOnboardingDots}}>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/onboarding */}
          <Route path='login/*' element={<Navigate to='/company-setup' />} />
          <Route path='company-setup/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='team/settings' element={<TeamPageWrapper />} />
          { user?.company.plan.ai &&
            <Route path='ai/settings' element={<AIPageWrapper />} />
          }
          {/* Macros Pages */}
          <Route path='business-ops/settings' element={<MacrosSettingsPageWrapper />} />
          <Route path='business-ops/snippets' element={<MacrosSnippetsPageWrapper />} />
          {/* Lazy Modules */}
          {/* <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          /> */}
          <Route
            path='crafted/pages/wizards/*'
            element={
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/widgets/*'
            element={
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            }
          />
          <Route
            path='account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/chat/*'
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </OnboardingDotsContext.Provider>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes, useOnboardingDots}
